import React from 'react'
import { Card, Row, Col, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteContrato } from '../actions/contratoActions'
import { LinkContainer } from 'react-router-bootstrap'

import { formatearFecha, currency } from '../utils/utils'

function Contrato({contrato}) {

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const dispatch = useDispatch()

  const deleteHandler = (id) => {
 
    if (window.confirm('¿Estás seguro de eliminar este Contrato?')) {
        dispatch(deleteContrato(id))
    }
  }

  return (
    <tr>
            <td className="text-center">{contrato.numeroContrato}</td>
            <td className="text-center">{contrato.proveedor}</td>
            <td className="text-center">{contrato.sociedad}</td>
            <td className="text-center">{currency(contrato.valorContrato)}</td>
            <td className="text-center">{formatearFecha(contrato.inicioVigencia)}</td>
            <td className="text-center">
              <Row>
                <Col>               
                  <LinkContainer to={`/contratos/${contrato._id}`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-eye'></i>
                    </Button>
                  </LinkContainer>
                  <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(contrato._id)}>
                    <i className='fas fa-trash'></i>
                  </Button>
                </Col>
              </Row>
            </td>
    </tr>
  )
}

export default Contrato