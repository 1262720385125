import React from 'react'
import { Button, Card, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deletePoliza } from '../actions/polizaActions'
import { LinkContainer } from 'react-router-bootstrap'

import { formatearFecha, currency } from '../utils/utils'

function Poliza({poliza}) {

  const dispatch = useDispatch()

  const deleteHandler = (id) => {
 
    if (window.confirm('¿Estás seguro de eliminar esta póliza?')) {
        dispatch(deletePoliza(id))
    }
  }
 
  return (
        <tr>
            <td className="text-center">{poliza.numeroPoliza}</td>
            <td className="text-center">{poliza.aseguradora}</td>
            <td className="text-center">{currency(poliza.primaTotal)}</td>
            <td className="text-center">{poliza.moneda}</td>
            <td className="text-center">{formatearFecha(poliza.inicioVigencia)}</td>
            <td className="text-center">
              <Row>
                <Col>              
                  <LinkContainer to={`/polizas/${poliza._id}`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-eye'></i>
                    </Button>
                  </LinkContainer>
                  <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(poliza._id)}>
                    <i className='fas fa-trash'></i>
                  </Button>
                </Col>
              </Row>
            </td>
        </tr>
  )
}

export default Poliza