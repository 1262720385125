import React, { useState,useEffect} from 'react'
import {Form, Card, Row, Col, Button} from 'react-bootstrap'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { listClientDetail } from '../actions/clientActions'
import { createComentario, listComentarios } from '../actions/comentarioActions'

import { useDispatch, useSelector } from 'react-redux'
import { COMENTARIO_CREATE_RESET } from '../constants/comentarioConstants';
import Loader from './Loader';
import Message from './Message';

function Comment(data) {

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const clientList = useSelector(state => state.clientDetailList)
    const { client } = clientList

    const comentList = useSelector(state => state.comentarioList)
    const { comentarios } = comentList

    const comentarioCreate = useSelector(state => state.comentarioCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, comentario: createdComentario } = comentarioCreate

    let clave = 0
    if(userInfo){
        clave = userInfo.cliente_id
    }

    const descriptionElementRef = React.useRef(null);

    const [open, setOpen] = useState(false);
    const [openNC, setOpenNC] = useState(false);
    const [scroll, setScroll] = useState('paper');

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()

    const [titulo, setTitulo] = useState('');
    const [prioridad, setPrioridad] = useState('');
    const [concepto, setConcepto] = useState('');
    const [folio, setFolio] = useState('');
    const [fecha, setFecha] = useState('');
    const [contenido, setContenido] = useState('');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClickOpenNC = (scrollType) => () => {
      setOpen(false);
      setOpenNC(true);
      setScroll(scrollType);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleCloseNC = () => {
      setOpenNC(false);
    };

    const submitHandler = (e) => {
      e.preventDefault()
      dispatch(createComentario({
        titulo, 
        prioridad,
        fecha,
        contenido,
        folio,
        concepto,
      }))
      setTitulo('')
      setContenido('')
    }

    useEffect(()=>{
        dispatch(listClientDetail(clave))
        dispatch(listComentarios(data.concepto, data.folio))

        setFolio(data.folio)
        setConcepto(data.concepto)
        setFecha(date)

        if (successCreate) {
          dispatch({ type: COMENTARIO_CREATE_RESET })
      }

        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
            descriptionElement.focus();
            }
        }

    }, [clave, open, data])

  return (
    <div>
        <Button className='btn btn-m' onClick={handleClickOpen('paper')} style={{background:client.secondary}}><i className="fa fa-comments"></i></Button>
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
        <DialogTitle id="scroll-dialog-title">Comentarios</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {comentarios.length ? (
                comentarios.map(comment => (
                  <Row>
                    <Col sm='8'>
                      <strong>{comment.titulo}</strong> - {comment.fecha}
                    </Col>
                    <Col className='text-end'>
                      <strong>Prioridad: </strong> {comment.prioridad}
                    </Col>
                      <p>{comment.contenido}</p>
                      <hr/>
                  </Row>
                ))
            ):(
              <Message variant='info'>No hay comentarios relacionados para este concepto</Message>
            )}
           
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{background:client.secondary}} onClick={handleClose}>Salir</Button>
          <Button style={{background:client.bg}} onClick={handleClickOpenNC('paper')}>Nuevo Comentario</Button>
        </DialogActions>
      </Dialog>
      <Dialog
            open={openNC}
            onClose={handleCloseNC}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
        <Form onSubmit={submitHandler}>
        <DialogTitle id="scroll-dialog-title">Nuevo Comentario</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
              {loadingCreate && <Loader />}
              {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
              
                <Form.Group controlId='titulo'>
                    <Form.Label>Titulo</Form.Label>
                    <Form.Control

                        type='titulo'
                        placeholder='Ingresa título del comentario'
                        value={titulo}
                        onChange={(e) => setTitulo(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
                <Form.Group controlId='prioridad'>
                    <Form.Label>Prioridad</Form.Label>
                    <Form.Select aria-label="Default select example"
                        onChange={(e) => setPrioridad(e.target.value)}
                    >
                        <option>Seleccione la prioridad del comentario</option>
                        <option value="Baja">Baja</option>
                        <option value="Media">Media</option>
                        <option value="Alta">Alta</option>
                    </Form.Select>
                </Form.Group>
                <br/>
                <Form.Group controlId='contenido'>
                    <Form.Label>Comentario</Form.Label>
                    <Form.Control

                        as='textarea'
                        placeholder='Ingresa comentario'
                        value={contenido}
                        onChange={(e) => setContenido(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
              
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{background:client.secondary}} onClick={handleCloseNC}>Cancelar</Button>
          <Button type='submit' style={{background:client.bg}} onClick={handleCloseNC} >Publicar</Button>
        </DialogActions>
        </Form>
      </Dialog>
    </div>
  )
}

export default Comment