import { 

    DASHBOARD_COUNTS_REQUEST,
    DASHBOARD_COUNTS_SUCCESS,
    DASHBOARD_COUNTS_FAIL,

    DASHBOARD_VENCIMIENTOS_REQUEST,
    DASHBOARD_VENCIMIENTOS_SUCCESS,
    DASHBOARD_VENCIMIENTOS_FAIL,
    
    DASHBOARD_INCIDENCIAS_REQUEST,
    DASHBOARD_INCIDENCIAS_SUCCESS,
    DASHBOARD_INCIDENCIAS_FAIL,

    VENCIMIENTO_ATENDIDO_REQUEST,
    VENCIMIENTO_ATENDIDO_SUCCESS,
    VENCIMIENTO_ATENDIDO_FAIL,

} from '../constants/dashboardConstants'

import axios from 'axios'

export const countsDashboard = (cliente) => async (dispatch) => {
    try{
        dispatch({type: DASHBOARD_COUNTS_REQUEST}) 

        const {data} = await axios.get(`/api/dashboard/counts/${cliente}`)

        dispatch({
            type: DASHBOARD_COUNTS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: DASHBOARD_COUNTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const vencimientosDashboard = (cliente) => async (dispatch) => {
    try{
        dispatch({type: DASHBOARD_VENCIMIENTOS_REQUEST}) 

        const {data} = await axios.get(`/api/dashboard/vencimientos/${cliente}`)

        dispatch({
            type: DASHBOARD_VENCIMIENTOS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: DASHBOARD_VENCIMIENTOS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const incidenciasDashboard = (cliente) => async (dispatch) => {
    try{
        dispatch({type: DASHBOARD_INCIDENCIAS_REQUEST}) 

        const {data} = await axios.get(`/api/dashboard/incidencias/${cliente}`)

        dispatch({
            type: DASHBOARD_INCIDENCIAS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: DASHBOARD_INCIDENCIAS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const vencimientoAtendidoDashboard = (id) => async (dispatch, getState) => {
    try{
        dispatch({type: VENCIMIENTO_ATENDIDO_REQUEST}) 

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.put(`/api/dashboard/atendido/${id}`,id, config)

        dispatch({
            type: VENCIMIENTO_ATENDIDO_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: VENCIMIENTO_ATENDIDO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    } 
}