import moment from 'moment';

function formatearFecha(fecha) {
    const momentFecha = moment(fecha, 'YYYY-MM-DD');
    const fechaFormateada = momentFecha.format('DD/MM/YYYY');
    return fechaFormateada;
};

export { formatearFecha };

function currency(number){
    return new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD', minimumFractionDigits: 2}).format(number);
};

export { currency };