import React, {useState} from 'react'
import { Table} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deletePago } from '../actions/pagoActions'
import ModalPago from './ModalPago'

import { formatearFecha, currency } from '../utils/utils'

function Pago({pagos}) {
  
    const [show, setShow] = useState(false);
    const [pago, setPago] = useState(null)

    const dispatch = useDispatch()

    const handleClose = () => {
      setShow(false);
    } 

    const handleShow = (pago) => {
      setShow(true);
      setPago(pago)
    }
  
    const deleteHandler = (id) => {
   
      if (window.confirm('¿Estás seguro de eliminar este pago?')) {
          dispatch(deletePago(id))
      }
    }

    return (
      <div>
        <Table bordered className='table-sm text-center fixed-header-table'>
          <thead>
            <tr>
              <th>Factura</th>
              <th>Fecha Limite</th>
              <th>Monto</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pagos.length >0 ? pagos.map(pago =>(
              <tr key={pago._id}>
                <td>{pago.numeroFactura}</td>
                <td>{formatearFecha(pago.fechaLimitePago)}</td>
                <td>{currency(pago.monto)}</td>
                <td>
                  <i className='fas fa-eye' onClick={() =>{ handleShow(pago)}}/>
                  <span style={{color:'white'}}>{' - '}</span>
                  <i className='fas fa-trash' style={{color:'#D9534F'}} onClick={() =>{ deleteHandler(pago._id)}}/>
                </td>
              </tr>
            )):
            <tr></tr>}
          </tbody>
        </Table>
        {pago != null &&<ModalPago pago={pago} show={show} handleClose={handleClose}/>}
      </div>
      
    )
}

export default Pago