import { 
    INCIDENCIA_LIST_FAIL,
    INCIDENCIA_LIST_REQUEST,
    INCIDENCIA_LIST_SUCCESS,
    INCIDENCIA_LIST_RESET,

    INCIDENCIA_DETAIL_REQUEST,
    INCIDENCIA_DETAIL_SUCCESS,
    INCIDENCIA_DETAIL_FAIL,

    INCIDENCIA_UPDATE_REQUEST,
    INCIDENCIA_UPDATE_SUCCESS,
    INCIDENCIA_UPDATE_FAIL,
    INCIDENCIA_UPDATE_RESET,

    INCIDENCIA_CREATE_REQUEST,
    INCIDENCIA_CREATE_SUCCESS,
    INCIDENCIA_CREATE_FAIL,
    INCIDENCIA_CREATE_RESET,

    INCIDENCIA_DELETE_REQUEST,
    INCIDENCIA_DELETE_SUCCESS,
    INCIDENCIA_DELETE_FAIL,

} from '../constants/incidenciaConstants'

export const incidenciaListReducer = (state = {incidencias:[]}, action) =>{
    switch(action.type){
        case INCIDENCIA_LIST_REQUEST:
            return {loading:true, ...state}

        case INCIDENCIA_LIST_SUCCESS:
            return {loading:false, 
                incidencias: action.payload,
            }

        case INCIDENCIA_LIST_FAIL:
            return {loading:false, error: action.payload}

        case INCIDENCIA_LIST_RESET:
            return {incidencias:[]}

        default:
            return state
    }
}

export const incidenciaDetailReducer= (state = {incidencia:[]}, action) =>{
    switch(action.type){
        case INCIDENCIA_DETAIL_REQUEST:
            return {loading:true, incidencia:[]}

        case INCIDENCIA_DETAIL_SUCCESS:
            return {loading:false, incidencia: action.payload}

        case INCIDENCIA_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const incidenciaCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case INCIDENCIA_CREATE_REQUEST:
            return { loading: true }

        case INCIDENCIA_CREATE_SUCCESS:
            return { loading: false, success: true, incidencia: action.payload }

        case INCIDENCIA_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case INCIDENCIA_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const incidenciaUpdateReducer = (state = { incidencia: {} }, action) => {
    switch (action.type) {
        case INCIDENCIA_UPDATE_REQUEST:
            return { loading: true }

        case INCIDENCIA_UPDATE_SUCCESS:
            return { loading: false, success: true, incidencia: action.payload }

        case INCIDENCIA_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case INCIDENCIA_UPDATE_RESET:
            return { incidencia: {} }

        default:
            return state
    }
}

export const incidenciaDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case INCIDENCIA_DELETE_REQUEST:
            return { loading: true }

        case INCIDENCIA_DELETE_SUCCESS:
            return { loading: false, success: true }

        case INCIDENCIA_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}