import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { 
    fianzaListReducer, 
    fianzaContratoListReducer,
    fianzaDetailReducer, 
    fianzaVigenciaReducer, 
    fianzaTopVencidasReducer,
    fianzaUpdateReducer,
    fianzaCreateReducer,
    fianzaDeleteReducer,
} from './reducers/fianzaReducers'

import { 
    userDetailReducer, 
    userLoginReducer, 
    userUpdateProfileReducer 
} from './reducers/userReducers'

import { clientDetailReducer } from './reducers/clientReducers'

import { 
    contratoListReducer,
    contratoProveedorListReducer,
    contratoDetailReducer, 
    contratoUpdateReducer,
    contratoCreateReducer,
    contratoDeleteReducer, 
} from './reducers/contratoReducers'

import { 
   proyectoListReducer,
} from './reducers/proyectoReducers'

import { 
    polizaListReducer,
    polizaContratoListReducer,
    polizaDetailReducer, 
    polizaUpdateReducer,
    polizaCreateReducer,
    polizaDeleteReducer,
} from './reducers/polizaReducers'

import { 
    endosoListReducer,
    endosoDetailReducer, 
    endosoUpdateReducer,
    endosoCreateReducer,
    endosoDeleteReducer,
} from './reducers/endosoReducers'

import { 
    pagoListReducer,
    pagoEndosoListReducer,
    pagoDetailReducer, 
    pagoUpdateReducer,
    pagoCreateReducer,
    pagoDeleteReducer,
} from './reducers/pagoReducers'

import { 
    comentarioListReducer,
    comentarioCreateReducer,
 } from './reducers/comentarioReducer'

 import { 
     documentoDetailReducer,
    documentoListReducer,
 } from './reducers/documentoReducers'

import { 
    proveedorListReducer,
    porveedorDetailReducer, 
    proveedorCreateReducer,
    proveedorUpdateReducer,
    proveedorDeleteReducer,
} from './reducers/proveedorReducers'

import { 
    vencimientoAtendidoReducer, 
    dashboardCountsReducer, 
    dashboardIncidenciasReducer, 
    dashboardVencimientosReducer } from './reducers/dashboardReducers'

import { incidenciaListReducer } from './reducers/incidenciaReducers'

import { 
    procesoListReducer,
    procesosCreateReducer,
    procesoUpdateReducer,
    procesoDeleteReducer,
 } from './reducers/procesoReducers'


const reducer = combineReducers({
    fianzaList: fianzaListReducer,
    fianzaContratoList: fianzaContratoListReducer,
    fianzaDetailList: fianzaDetailReducer,
    fianzaVigenciaList: fianzaVigenciaReducer,
    fianzaTopVencidasList: fianzaTopVencidasReducer,
    fianzaDelete: fianzaDeleteReducer,
    fianzaUpdate: fianzaUpdateReducer,
    fianzaCreate: fianzaCreateReducer,

    clientDetailList: clientDetailReducer,
    
    dashboardCounts: dashboardCountsReducer,
    dashboardVencimientos: dashboardVencimientosReducer,
    dashboardIncidencias: dashboardIncidenciasReducer,
    vencimientoAtendido: vencimientoAtendidoReducer,

    incidenciaList: incidenciaListReducer,

    procesoList: procesoListReducer,
    procesoCreate: procesosCreateReducer,
    procesoUpdate: procesoUpdateReducer,
    procesoDelete: procesoDeleteReducer,

    contratoList: contratoListReducer,
    contratoProveedorList: contratoProveedorListReducer,
    contratoDetailList: contratoDetailReducer,
    contratoDelete: contratoDeleteReducer,
    contratoUpdate: contratoUpdateReducer,
    contratoCreate: contratoCreateReducer,

    proyectoList: proyectoListReducer,

    proveedorList: proveedorListReducer,
    proveedorDetailList: porveedorDetailReducer,
    proveedorCreate: proveedorCreateReducer,
    proveedorUpdate: proveedorUpdateReducer,
    proveedorDelete: proveedorDeleteReducer,

    polizaList: polizaListReducer,
    polizaContratoList: polizaContratoListReducer,
    polizaDetailList: polizaDetailReducer,
    polizaDelete: polizaDeleteReducer,
    polizaUpdate: polizaUpdateReducer,
    polizaCreate: polizaCreateReducer,

    endosoList: endosoListReducer,
    endosoDetailList: endosoDetailReducer,
    endosoDelete: endosoDeleteReducer,
    endosoUpdate: endosoUpdateReducer,
    endosoCreate: endosoCreateReducer,

    pagoList: pagoListReducer,
    pagoEndosoList: pagoEndosoListReducer,
    pagoDetailList: pagoDetailReducer,
    pagoDelete: pagoDeleteReducer,
    pagoUpdate: pagoUpdateReducer,
    pagoCreate: pagoCreateReducer,

    comentarioList: comentarioListReducer,
    comentarioCreate: comentarioCreateReducer,

    documentoList: documentoListReducer,
    documentoDetailList: documentoDetailReducer,

    userLogin: userLoginReducer,
    userDetail: userDetailReducer,
    userUpdateProfile: userUpdateProfileReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: {userInfo: userInfoFromStorage}
}

const middleware = [thunk]

const store = createStore(reducer, initialState, 
    composeWithDevTools(applyMiddleware(...middleware)))

export default store


