import {
    CLIENT_DETAIL_REQUEST,CLIENT_DETAIL_SUCCESS,CLIENT_DETAIL_FAIL
} from '../constants/clientConstants'

import axios from 'axios'

export const listClientDetail = (clave) => async (dispatch) => {
    try{
        dispatch({type: CLIENT_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/clients/${clave}`)

        dispatch({
            type: CLIENT_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: CLIENT_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}