import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Row, Col,Card, Button } from 'react-bootstrap'
import { listProveedorDetail } from '../actions/proveedoresActions'
import { listContratosProveedor } from '../actions/contratoActions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Comment from '../components/Comment'
import ContratoProveedor from '../components/ContratoProveedor'

import { formatearFecha } from '../utils/utils'
import Rating from '../components/Rating'

function ProveedorDetailScreen() {

  let navigate = useNavigate()
  const location = useLocation()
  const match = useParams()
  
  const dispatch = useDispatch()
  const proveedorDetailList = useSelector(state => state.proveedorDetailList)
  const{error, loading, proveedor} = proveedorDetailList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const contratoProveedorList = useSelector(state => state.contratoProveedorList)
  const{contratos} = contratoProveedorList

  useEffect(()=>{
    if(userInfo){ 
        dispatch(listProveedorDetail(userInfo.cliente_id, match))
        dispatch(listContratosProveedor(userInfo.cliente_id, match.id))
    }
  }, [dispatch, match, userInfo])

  const createContratoHandler = () => {
    navigate(`/contratos/create`)
  }
  
  const fromCreate = location.search === '?from=create'
  const handleClick = () => {
    if (fromCreate) {
      // Si se llegó desde la tercera forma, redirige a otro lado
      navigate('/proveedores/');
    } else {
      // En caso contrario, regresa a la página anterior
      navigate(-1);
    }
  };

  return (
    <div className='pt-4'>
        {loading ? <Loader/>
                : error ? <Message variant="danger">{error}</Message>
        : (
            <div>
        <Row className="d-flex align-items-center justify-content-center vh-10" >
            <Col sm="2">
                <Button onClick={handleClick} className='btn btn-light my-3'>Regresar</Button>
            </Col>
            <Col sm="4">
                <h4>Proveedor: {proveedor.nombreProveedor}</h4>
            </Col>
            <Col sm="4">
                <Message variant="info">No hay ninguna acción requerida</Message>
            </Col>
            <hr/>
        </Row>
        <Row>
            <Col>
                <Card style={{background:"#f5f5f5", width:"100%"}} className='rounded'>
                    <div className="p-3">
                        <Row className="flex align-items-center justify-content-center">
                            <Col sm="10">
                                <div>
                                    <h6> Detalles Generales del proveedor</h6>
                                    
                                </div>
                                <hr/>
                                <Row>
                                    <Col>
                                        <strong>Nombre del Proveedor:</strong> {proveedor.nombreProveedor}
                                    </Col>
                                    <Col>
                                        <strong>RFC:</strong> {proveedor.rfc}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>Giro del Proveedor:</strong> {proveedor.giro}
                                    </Col>
                                    <Col>
                                        <strong>Antigüedad:</strong> {formatearFecha(proveedor.antiguedad)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={1}>
                                        <strong>Clase: </strong> 
                                    </Col>
                                    <Col>
                                        <Rating value={proveedor.calificacion} color={'#FFD700'}/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col >
                                <Link to={`/proveedores/${proveedor.id}/edit`} style={{width:'100%'}} className='btn btn-info'>Editar Proveedor</Link>
                                <br/><br/>
                                <Link to={`/proveedores/${proveedor.id}/files`} style={{width:'100%'}} className='btn btn-success'>Documentación</Link>
                            </Col>
                        </Row>
                        
                    </div>
                </Card>
            </Col>
        </Row>
        <Row>
        <Col className='me-2 ms-2'>
            <br/>
            <Row className="d-flex align-items-center justify-content-center">
                <Col>
                    <h4>Contratos</h4>
                </Col>
                <Col className='text-end'>
                    <Button className="btn-sm" variant="warning" style={{ width:"8vw"}} onClick={createContratoHandler}> Crear Contrato</Button>
                </Col>
            </Row>
            <hr/>
            <Row>
                {contratos.length >0 ? 
                    <ContratoProveedor contratos={contratos}/>
                    :
                    <Row><Message variant="info">No hay Contratos registrados</Message></Row>
                } 
            </Row>
        </Col>
        </Row>
        </div>
        )
        }
    </div>
  )
}

export default ProveedorDetailScreen