import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Row, Col,Card, Button } from 'react-bootstrap'
import { listPolizaDetail } from '../actions/polizaActions'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Comment from '../components/Comment'
import { listEndosos } from '../actions/endosoActions'
import Endoso from '../components/Endoso'
import { listPagos } from '../actions/pagoActions'
import Pago from '../components/Pago'

function PolizaDetailScreen() {

  let navigate = useNavigate()
  const location = useLocation()
  const match = useParams()

  const dispatch = useDispatch()
  const polizaList = useSelector(state => state.polizaDetailList)
  const{error, loading, poliza} = polizaList

  const endosoList = useSelector(state => state.endosoList)
  const {endosos} = endosoList

  const pagoList = useSelector(state => state.pagoList)
  const {pagos} = pagoList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(()=>{
        if (!poliza.inicioVigencia || parseInt(match.id) !== parseInt(poliza._id)){
            dispatch(listPolizaDetail(userInfo.cliente_id, match)) 
        }else {
            if(endosos.length === 0){
                dispatch(listEndosos(poliza._id,'seguro'))
            }
            if(pagos.length === 0){
                dispatch(listPagos(poliza._id,'seguro'))
            }
        }

  }, [dispatch, poliza.inicioVigencia, endosos.length, pagos.length, userInfo.cliente_id, match, poliza._id])

  const onButtonClick = () => { 
    // using Java Script method to get PDF file
    fetch(poliza.pdf).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = poliza.pdf ;
            alink.click();
        })
    })
  }

  const fromCreate = location.search === '?from=create'
  const handleClick = () => {
    console.log(location.search)
    if (fromCreate) {
      // Si se llegó desde la tercera forma, redirige a otro lado
      navigate('/polizas/');
    } else {
      // En caso contrario, regresa a la página anterior
      navigate(-1);
    }
  };

  const createEndosoHandler = () => {
    navigate(`/endosos/${poliza._id}/create?concepto=seguro`)
  };

  
  const createPagoHandler = () => {
    navigate(`/pagos/${poliza._id}/create?concepto=seguro`)
  };


  return (
    <div className='pt-4'>
        <Row className="d-flex align-items-center justify-content-center vh-10" >
            <Col sm="2">
                <Button className='btn btn-light my-3' onClick={handleClick}>Regresar</Button>
            </Col>
            <Col sm="4">
                <h4>Poliza: {poliza.numeroPoliza}</h4>
            </Col>
            <Col sm="4">
                <Message variant="info">No hay ninguna acción requerida</Message>
            </Col>
            <Col sm="2" className='text-end'>
                <Comment concepto="poliza" folio={poliza.numeroPoliza}/>
            </Col>
            <hr/>
        </Row>
        <Row>
            <Col>
                <Card style={{background:"#f5f5f5", width:"100%"}} className='rounded'>
                    <div className="p-3">
                        <Row className="flex align-items-center justify-content-center">
                            <Col sm="10">
                                <div>
                                    <h6> Detalles Generales de la Póliza</h6>
                                    
                                </div>
                                <hr/>
                                <Row>
                                    <Col sm={6}>
                                        <strong>Aseguradora:</strong> {poliza.aseguradora}
                                    </Col>
                                    <Col sm={6}>
                                        <strong>Tipo Seguro:</strong> {poliza.ramoSeguro}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <strong>Moneda:</strong> {poliza.moneda}
                                    </Col>
                                    <Col sm={6}>
                                        <strong>S. Asegurada:</strong> {currency(poliza.sumaAsegurada)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <strong>Prima Neta:</strong> {currency(poliza.primaNeta)}
                                    </Col>
                                    <Col sm={6}>
                                        <strong>Prima Total:</strong> {currency(poliza.primaTotal)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <strong>Inicio Vigencia:</strong> {poliza.inicioVigencia}
                                    </Col>
                                    <Col sm={6}>
                                        <strong>Fin Vigencia:</strong> {poliza.finVigencia}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>Observaciones:</strong> {poliza.observaciones}
                                    </Col>
                                </Row>
                                <br/>
                            </Col>
                            <Col >
                                <Link to={`/polizas/${userInfo.cliente_id}/${poliza._id}/edit`} style={{width:'100%'}} className='btn btn-info'>Editar Póliza</Link>
                                <br/>
                                <br/>
                                <Link to={`/polizas/${poliza._id}/files`} className='btn btn-success' style={{width:"100%"}}>Ver Documentos</Link>
                            </Col>
                        </Row>
                        
                    </div>
                </Card>
            </Col>
        </Row>
        <Row>
        <Col className='me-2 ms-2'>
            <br/>
            <Row className="d-flex align-items-center justify-content-center">
                <Col>
                    <h4>Endosos</h4>
                </Col>
                <Col className='text-end'>
                    <Button onClick={createEndosoHandler} className="btn-sm" variant="warning" style={{ width:"8vw"}}> Nuevo Endoso</Button>
                </Col>
            </Row>
            <hr/>
            <Row>
                {endosos.length >0 ? 
                <Endoso endosos={endosos}/>
                :
                <Row><Message variant="info">No hay Endosos registrados</Message></Row>
                }
            </Row>
        </Col>
        <Col className='me-2 ms-2'>
            <br/>
            <Row className="d-flex align-items-center justify-content-center">
                <Col>
                    <h4>Pagos</h4>
                </Col>
                <Col className='text-end'>
                    <Button onClick={createPagoHandler} className="btn-sm" variant="warning" style={{ width:"8vw"}}> Nuevo Pago</Button>
                </Col>
            </Row>
            <hr/>
            <Row>
                {pagos.length >0 ? 
                    <Pago pagos={pagos}/>
                    :
                    <Row><Message variant="info">No hay Pagos registrados</Message></Row>
                }
            </Row>
        </Col>
        </Row>
    </div>
  )
}

export default PolizaDetailScreen

const currency = function(number){
    return new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD', minimumFractionDigits: 2}).format(number);
};