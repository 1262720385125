import axios from 'axios'

import { 
    PROVEEDOR_LIST_FAIL,
    PROVEEDOR_LIST_REQUEST,
    PROVEEDOR_LIST_SUCCESS,

    PROVEEDOR_DETAIL_FAIL,
    PROVEEDOR_DETAIL_REQUEST,
    PROVEEDOR_DETAIL_SUCCESS,

    PROVEEDOR_UPDATE_REQUEST,
    PROVEEDOR_UPDATE_SUCCESS,
    PROVEEDOR_UPDATE_FAIL,

    PROVEEDOR_CREATE_REQUEST,
    PROVEEDOR_CREATE_SUCCESS,
    PROVEEDOR_CREATE_FAIL,

    PROVEEDOR_DELETE_REQUEST,
    PROVEEDOR_DELETE_SUCCESS,
    PROVEEDOR_DELETE_FAIL,

} from '../constants/proveedorConstants'

export const listProveedores = (cliente, keyword = '') => async (dispatch) => {
    try{
        dispatch({type: PROVEEDOR_LIST_REQUEST}) 

        const {data} = await axios.get(`/api/proveedores/${cliente}${keyword}`)

        dispatch({
            type: PROVEEDOR_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: PROVEEDOR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listProveedorDetail = (cliente, match) => async (dispatch) => {
    try{
        dispatch({type: PROVEEDOR_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/proveedores/${cliente}/${match.id}`)

        dispatch({
            type: PROVEEDOR_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: PROVEEDOR_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const createProveedor = (proveedor) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROVEEDOR_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/proveedores/create/`,
            proveedor,
            config
        )
        dispatch({
            type: PROVEEDOR_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: PROVEEDOR_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateProveedor = (proveedor) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROVEEDOR_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/proveedores/update/${proveedor.id}/`,
            proveedor,
            config
        )
        dispatch({
            type: PROVEEDOR_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: PROVEEDOR_DETAIL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PROVEEDOR_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteProveedor = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROVEEDOR_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/proveedores/delete/${id}/`,
            config
        )

        dispatch({
            type: PROVEEDOR_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: PROVEEDOR_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}