import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { createProveedor } from '../actions/proveedoresActions'
import { PROVEEDOR_UPDATE_RESET } from '../constants/proveedorConstants'

import TextField from '@mui/material/TextField';


function ProveedorCreateScreen() {

    let navigate = useNavigate()
    const match = useParams()

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()

    const [nombreProveedor, setNombreProveedor] = useState('')
    const [rfc, setRfc] = useState('')
    const [giro, setGiro] = useState('')
    const [antiguedad, setAntiguedad] = useState(date)

    const dispatch = useDispatch()

    const proveedorCreate = useSelector(state => state.proveedorCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, proveedor: createdProveedor } = proveedorCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {

        if (successCreate) {
            dispatch({ type: PROVEEDOR_UPDATE_RESET })
            navigate(`/proveedores/${createdProveedor.id}?from=create`)
        }

    }, [dispatch, match, successCreate, createdProveedor, navigate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createProveedor({
            nombreProveedor,
            rfc,
            antiguedad,
            giro
        }))
    }

    const handleClick = () => {
        window.history.back();
    };

  return (
    <div>
        <Button onClick={handleClick} className='btn btn-light my-3'>
                Regresar
        </Button>

        <div className='pt-2 p-5'>
                <h1>Nuevo Proveedor </h1>
                {loadingCreate && <Loader />}
                {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

                <Form onSubmit={submitHandler}>
                    <Row>
                        <Col sm={6} className='p-3'>
                            <TextField 
                                id="nombreProveedor"
                                label="Nombre Proveedor" 
                                variant="outlined"
                                type='text'
                                value={nombreProveedor}
                                fullWidth 
                                onChange={(event) => {
                                    setNombreProveedor(event.target.value);
                                }}
                            />
                        </Col>
                        <br/>
                        <Col sm={6} className='p-3'>
                            <TextField 
                                id="rfc"
                                label="RFC Proveedor" 
                                variant="outlined"
                                type='text'
                                value={rfc}
                                fullWidth 
                                onChange={(event) => {
                                    setRfc(event.target.value);
                                }}
                            />
                        </Col>
                        <br/>
                        <Col sm={6} className='p-3'>
                            <TextField 
                                id="antiguedad"
                                label="Antigüedad" 
                                variant="outlined"
                                type='date'
                                value={antiguedad}
                                fullWidth 
                                onChange={(event) => {
                                    setAntiguedad(event.target.value);
                                }}
                            />
                        </Col>
                        <br/>
                        <Col sm={6} className='p-3'>
                            <TextField 
                                id="giro"
                                label="Giro Proveedor" 
                                variant="outlined"
                                type='text'
                                value={giro}
                                fullWidth 
                                onChange={(event) => {
                                    setGiro(event.target.value);
                                }}
                            />
                        </Col>
                        <br/>
                    </Row>
                    <br/>
                    <Button type='submit' variant='success'>
                        Guardar
                    </Button>
                </Form>
            </div>
    </div>
  )
}

export default ProveedorCreateScreen