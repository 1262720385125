import React, { useEffect } from 'react'
import { Row, Col, Table, Button } from 'react-bootstrap'
import SearchBox from '../components/SearchBox'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { listProcesos } from '../actions/procesoActions'
import Proceso from '../components/Proceso'
import Loader from '../components/Loader'
import Message from '../components/Message'

function ProyectosScreen() {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const keyword = useLocation().search

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const procesoList = useSelector(state => state.procesoList)
    const{procesos, loading, error} = procesoList

    useEffect(()=>{
        dispatch(listProcesos(userInfo.cliente_id, keyword))
    },[dispatch, userInfo.cliente_id, keyword])

    const createProyectoHandle = () => {
      navigate(`/procesos/create`)
    }
  return (
    <div className='pt-4'>
        <Row className='align-items-center'>
            <Col sm={2}>
                <h4>Proyectos</h4>
            </Col>
            <Col sm={4}>
                <SearchBox/>
            </Col>
            <Col sm={6} className='text-end'> 
                <Button className='btn btn-info' onClick={createProyectoHandle}>Nuevo Proceso</Button>
            </Col>
        </Row>
        <hr/>
        
        {loading ? <Loader/>
        : error ? <Message variant='danger'>{error}</Message>
        :
        <div className='procesos' style={{maxHeight:'64vh', maxWidth:'100%', overflowY:'auto'}}>
            {procesos.map(proceso=>(
                <Proceso proceso={proceso}/>
            ))}
        </div>
        }
    </div>
  )
}

export default ProyectosScreen