import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar,Nav,Container,NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../actions/userActions'
import { listClientDetail } from '../actions/clientActions'
import {Badge, Popover, Paper, Button as ButtonM, Divider} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import Message from '../components/Message'
import logo from './img/LogoSespecPagina.svg'


function Header() {

  const[anchor, setAnchor] = useState(null)
  const[fullScreen, setFullScreen] = useState(false)

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const dispatch = useDispatch()

  let clave = 0
  if(userInfo){
    clave = userInfo.cliente_id
  }

  const logoutHandler = () => {
    dispatch(logout())

  }

  const openPopover = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null);
  };

  const handleFullscreenToggle = () => {
    const elem = document.documentElement;
    setFullScreen(!fullScreen)
    if (document.fullscreenEnabled) {
      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch((err) => {
          console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }  
  }

  return (
    <header>
      {userInfo ? (
        <Navbar bg='dark' fixed="top" variant="dark" collapseOnSelect expand="lg">
          <Container>
            <LinkContainer to={`/home`}> 
              <Navbar.Brand>
              <img
                src={logo}
                width="40"
                height="40"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
              </Navbar.Brand>
            </LinkContainer>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to={`/dashboard/`}> 
                  <Nav.Link><i className="fas fa-chart-line"></i> Dashboard</Nav.Link>
                </LinkContainer> 
                <LinkContainer to={`/documentos/${userInfo.cliente_id}`}>
                  <Nav.Link><i className="fa fa-file"></i> Documentos</Nav.Link>
                </LinkContainer> 
                <LinkContainer to={`/incidencias/`}>
                  <Nav.Link><i className="fas fa-pen-nib"></i> Incidencias</Nav.Link>
                </LinkContainer>
                <LinkContainer to={`/procesos/`}>
                  <Nav.Link><i className="fas fa-handshake"></i> Proceso Inicial</Nav.Link>
                </LinkContainer>
                <NavDropdown title="Inventario"  id="basic-nav-dropdown"> 
                  <LinkContainer to={`/proveedores/`}>
                    <NavDropdown.Item><i className="fa fa-user"></i> Proveedores</NavDropdown.Item>
                  </LinkContainer> 
                  <LinkContainer to={`/contratos/`}>
                    <NavDropdown.Item><i className="fa fa-file-contract"></i> Contratos</NavDropdown.Item>
                  </LinkContainer> 
                  <LinkContainer to={`/fianzas/`}>
                    <NavDropdown.Item><i className="fa fa-building-columns"></i> Fianzas</NavDropdown.Item>
                  </LinkContainer> 
                  <LinkContainer to={`/polizas/`}>
                    <NavDropdown.Item><i className="fa fa-shield-halved"></i> Seguros</NavDropdown.Item>
                  </LinkContainer> 
                </NavDropdown>
              </Nav>
              <Nav>
                {/*
                  <Nav.Link>     
                    <Badge badgeContent={1} color="primary" >
                      <MailIcon color="white" onClick={openPopover} />
                    </Badge>
                    <Popover 
                      open={Boolean(anchor)}
                      anchorEl={anchor}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical:'top',
                        horizontal: "right"
                      }}
                      onClose={handleClose}
                    >
                      <Paper elevation={3} style={{width:"25vw"}}>
                        <div className='p-4'>
                          <h6>Notificaciones</h6>
                          <Divider light>NUEVO</Divider>
                          <Message variant="info">No tienes notificaciones nuevas</Message>
                          <Divider light>ANTERIORES</Divider>
                          <Message variant="info">No tienes notificaciones anteriores</Message>
                          <Divider/>
                          <LinkContainer to='/profile' style={{width:"100%"}}>
                            <ButtonM variant='success' size='small' >Ver todas</ButtonM>
                          </LinkContainer>
                        </div>
                      </Paper>
                    </Popover>
                  </Nav.Link>
                */}
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='/registro'>
                    <NavDropdown.Item><i className="fa fa-clipboard-list"></i> Registro </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={handleFullscreenToggle}><i className={fullScreen ?'fas fa-compress':'fas fa-expand'}></i> FullScreen</NavDropdown.Item>
                  <NavDropdown.Divider/>
                  <NavDropdown.Item onClick={logoutHandler}><i className="fa fa-arrow-right-from-bracket"></i> Cerrar Sesión</NavDropdown.Item>
                </NavDropdown> 
              </Nav>
              </Navbar.Collapse>  
            </Container>
        </Navbar>
      ): (
        <Navbar bg="pprimay" variant="primary" collapseOnSelect>
          <Container>
            <LinkContainer to='/home'> 
              <Navbar.Brand>SESPEC</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav"> 
            </Navbar.Collapse>  
          </Container>
        </Navbar>
      )
      }
    </header>
    
  )
}

export default Header