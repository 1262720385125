import React from 'react'
import { Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deletePoliza } from '../actions/polizaActions'
import { useNavigate } from 'react-router-dom'

import { formatearFecha } from '../utils/utils'

function PolizaContrato({polizas}) {

  let navigate=useNavigate()
  const dispatch = useDispatch()

  const deleteHandler = (id) => {
 
    if (window.confirm('¿Estás seguro de eliminar esta póliza?')) {
        dispatch(deletePoliza(id))
    }
  }

  const handleClick = (poliza) => {
    navigate(`/polizas/${poliza._id}`)
  }
 
  return (
    <Table bordered className='table-sm text-center fixed-header-table'>
          <thead>
            <tr>
              <th>Número</th>
              <th>Aseguradora</th>
              <th>Inicio Vigencia</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {polizas.length >0 ? polizas.map(poliza =>(
              <tr key={poliza._id}>
                <td>{poliza.numeroPoliza}</td>
                <td>{poliza.aseguradora}</td>
                <td>{formatearFecha(poliza.inicioVigencia)}</td>
                <td>
                  <i className='fas fa-eye' onClick={() =>{ handleClick(poliza)}}/>
                  <span style={{color:'white'}}>{' - '}</span>
                  <i className='fas fa-trash' style={{color:'#D9534F'}} onClick={() =>{ deleteHandler(poliza._id)}}/>
                </td>
              </tr>
            )):
            <tr></tr>}
          </tbody>
    </Table>
  )
}

export default PolizaContrato
