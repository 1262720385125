import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Button, Table } from 'react-bootstrap'
import Fianza from '../components/Fianza' 
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import { listFianzas, } from '../actions/fianzaActions'
import { useLocation, useNavigate} from 'react-router-dom'

import SearchBox from '../components/SearchBox'
import FilterDate from '../components/FilterDate'
import { FIANZA_CREATE_RESET } from '../constants/fianzaConstants'

import {utils, writeFile} from 'xlsx';
import { ENDOSO_LIST_RESET } from '../constants/endosoConstants'
import { PAGO_LIST_RESET } from '../constants/pagoConstants'
import './index.css'

function FianzasScreen() {

  let navigate = useNavigate()
  const dispatch = useDispatch()

  const fianzaList = useSelector(state => state.fianzaList)
  const{error, loading, fianzas, page, pages} = fianzaList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const fianzaDelete = useSelector(state => state.fianzaDelete)
  const { success: successDelete } = fianzaDelete

  let clave = 0
  if(userInfo){
    clave = userInfo.cliente_id
  }

  let vPage = 1
  if(page > pages){
    vPage = pages
  }else if (page < 1){
    vPage = 1
  }else{
    vPage = page
  }

  const keyword = useLocation().search
  let year = ''
  let month = ''
 
  if(keyword){
    year = keyword.split('year=')[1].split('&')[0]
    month = keyword.split('month=')[1].split('&')[0]
  }

  useEffect(()=>{
    dispatch({ type: FIANZA_CREATE_RESET })
    dispatch({ type: ENDOSO_LIST_RESET })
    dispatch({ type: PAGO_LIST_RESET })
    
    dispatch(listFianzas(clave, keyword))
  
  }, [dispatch,clave, keyword, successDelete])

  const createFianzaHandler = () => {
    navigate(`/fianzas/create`)
  }

  const handleOnExport = () => {
    var wb = utils.book_new(),
    ws = utils.json_to_sheet(fianzas)

    utils.book_append_sheet(wb,ws, "Sheet12");

    writeFile(wb, "Ejemplo.xlsx");
  }

  return (
    <div>
      
            <br/>
            <Row  className='align-items-center'>   
                <Col sm="4"> <h3>Inventario de Fianzas</h3></Col>
                <Col sm="5"><SearchBox year={year} month={month}/></Col>
                <Col className='text-end'><Button variant='success' onClick={createFianzaHandler}>Nueva Fianza</Button></Col>
                <Col>
                  <Button className='btn btn-light my-3' style={{background:'#eeeeee'}} onClick={handleOnExport}><i className="fas fa-download"></i> Exportar</Button>
                </Col>
            </Row>
            <hr/>
            {loading ? <Loader/>
                : error ? <Message variant="danger">{error}</Message>
                    : fianzas.length ?
                    <div>
                    <div style={{maxHeight:'64vh', maxWidth:'100%', overflowY:'auto'}}>
                        <Table className="fixed-header-table">
                          <thead>
                            <tr className='text-center'>
                              <th>Fianza</th>
                              <th>Afianzadora</th>
                              <th>Beneficiario</th>
                              <th>Monto</th>
                              <th>Vigencia</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fianzas.map(fianza =>(
                                  <Fianza key={fianza._id} fianza={fianza}/>
                            ))}   
                          </tbody>
                          
                        </Table>
                    </div>
                      <Paginate page={vPage} pages={pages} keyword={keyword} year={year} month={month}/>
                    </div>
                    :
                    <Message variant="warning">No se encontraron Fianzas</Message>
        }
        
    </div>
  )
}

export default FianzasScreen