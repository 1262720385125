import React, { useState } from 'react'

import { Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Proceso({proceso}) {
    const [open,setOpen] = useState(false)
  return (
    <div className='proceso' key={proceso._id}>
        <Row className='d-flex align-items-center' onClick={() => {setOpen(!open)}}>
            <Col sm={7}>
                <div className='proceso-header'>
                    <span>{proceso.nombreProyecto}</span>
                </div>
            </Col>
            <Col>
                <span>
                    <i 
                        className={proceso.hasContrato ? 'fas fa-circle-check' : 'fas fa-circle-xmark'}
                        style={{color:proceso.hasContrato ? 'limegreen' : '#D9534F'}}
                    /> 
                    {proceso.hasContrato ? ' Asignado' : ' Sin Asignar'}
                </span>
            </Col>
            <Col sm={2}>
                <span>Fecha Alta: </span>
                <span>{proceso.fechaAlta}</span>
            </Col>
            <Col sm={1} className='text-end'>
                <Button className='btn-light'><i className={open ? 'fas fa-minus':'fas fa-plus'}/></Button>
            </Col>
        </Row>
        {open &&
            <div className='proceso-docs'>
                <h6>Documentos:</h6>
                <hr/>
                {proceso.documentos.map((documento, index) => (
                    <div>
                        {index+1} - <a target="_blank" rel="noreferrer" style={{textDecoration:'None'}} href={documento.url}>{documento.titulo}</a>
                    </div>
                ))}
                {proceso.contrato &&
                    <div>
                        <br/>
                        <h6>Contrato:</h6>
                        <hr/>
                        <Link to={`/contratos/${proceso.contrato._id}`} style={{textDecoration:'None'}}>
                            <i className='fas fa-file-signature'/> {proceso.contrato.numeroContrato}
                        </Link>
                    </div>
                }
                
            </div>
        }
    </div>
  )
}

export default Proceso