import { 
    ENDOSO_LIST_FAIL,
    ENDOSO_LIST_REQUEST,
    ENDOSO_LIST_SUCCESS,

    ENDOSO_DETAIL_REQUEST,
    ENDOSO_DETAIL_SUCCESS,
    ENDOSO_DETAIL_FAIL,

    ENDOSO_UPDATE_REQUEST,
    ENDOSO_UPDATE_SUCCESS,
    ENDOSO_UPDATE_FAIL,

    ENDOSO_CREATE_REQUEST,
    ENDOSO_CREATE_SUCCESS,
    ENDOSO_CREATE_FAIL,

    ENDOSO_DELETE_REQUEST,
    ENDOSO_DELETE_SUCCESS,
    ENDOSO_DELETE_FAIL,

} from '../constants/endosoConstants'

import axios from 'axios'

export const listEndosos = (idConcepto, concepto, keyword = '') => async (dispatch) => {
    try{
        dispatch({type: ENDOSO_LIST_REQUEST})

        const {data} = await axios.get(`/api/endosos/all/${idConcepto}/${concepto}`)

        dispatch({
            type: ENDOSO_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: ENDOSO_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listEndosoDetail = (cliente, match) => async (dispatch) => {
    try{
        dispatch({type: ENDOSO_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/endosos/${cliente}/${match.id}`)

        dispatch({
            type: ENDOSO_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: ENDOSO_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const createEndoso = (endoso) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ENDOSO_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/endosos/create/`,
            endoso,
            config
        )
        dispatch({
            type: ENDOSO_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: ENDOSO_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateEndoso = (endoso) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ENDOSO_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/endosos/update/${endoso._id}/`,
            endoso,
            config
        )
        dispatch({
            type: ENDOSO_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: ENDOSO_DETAIL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: ENDOSO_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteEndoso = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ENDOSO_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/endosos/delete/${id}/`,
            config
        )

        dispatch({
            type: ENDOSO_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: ENDOSO_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}