import React, { useEffect} from 'react'
import { Container, Row, Col, Button, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Proveedor from '../components/Proveedor' 
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import { listProveedores, } from '../actions/proveedoresActions'
import { useParams, useLocation, useNavigate} from 'react-router-dom'

import SearchBox from '../components/SearchBox'
import FilterDate from '../components/FilterDate'

import {utils, writeFile} from 'xlsx';
import { PROVEEDOR_CREATE_RESET } from '../constants/proveedorConstants'

function ProveedoresScreen() {
  
  const match = useParams()

  let navigate = useNavigate()
  const dispatch = useDispatch()

  const proveedorList = useSelector(state => state.proveedorList)
  const{error, loading, proveedores, page, pages} = proveedorList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const proveedorDelete = useSelector(state => state.proveedorDelete)
  const { success: successDelete } = proveedorDelete

  let clave = 0
  if(userInfo){
    clave = userInfo.cliente_id
  }

  let vPage = 1
  if(page > pages){
    vPage = pages
  }else if (page < 1){
    vPage = 1
  }else{
    vPage = page
  }

  const keyword = useLocation().search
  let year = ''
  let month = ''
 
  if(keyword){
    year = keyword.split('year=')[1].split('&')[0]
    month = keyword.split('month=')[1].split('&')[0]
  }

  useEffect(()=>{
    dispatch({ type: PROVEEDOR_CREATE_RESET })
    dispatch(listProveedores(clave, keyword))
  
  }, [dispatch, match, clave, keyword, successDelete, userInfo])

  const createFianzaHandler = () => {
    navigate(`/proveedores/create`)
  }

  const handleOnExport = () => {
    var wb = utils.book_new(), 
    ws = utils.json_to_sheet(proveedores)

    utils.book_append_sheet(wb,ws, "Sheet12");

    writeFile(wb, "Ejemplo.xlsx");
  }


  return (
    <div>
        
            <br/>
            <Row  className='align-items-center'>   
                <Col sm="4"> <h3>Inventario Proveedores</h3></Col>
                <Col sm="4"><SearchBox year={year} month={month}/></Col>
                <Col className='text-end'>
                  <Button variant='success'  onClick={createFianzaHandler}>Nuevo Proveedor</Button>
                </Col>
                <Col>
                  <Button className='btn btn-light my-3' style={{background:'#eeeeee'}} onClick={handleOnExport}><i className="fas fa-download"></i> Exportar</Button>
                </Col>
            </Row>
            <hr/>
            {loading ? <Loader/>
                : error ? <Message variant="danger">{error}</Message>
                    : proveedores.length ?
                    <div>
                      <div style={{maxHeight:'64vh', maxWidth:'100%', overflowY:'auto'}}>
                        <Table className="fixed-header-table">
                          <thead className='text-center'>
                            <tr>
                              <th>Proveedor</th>
                              <th>RFC</th>
                              <th>Antigüedad</th>
                              <th>Giro</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {proveedores.map(proveedor =>(
                              <Proveedor key={proveedor.id} proveedor={proveedor}/>
                            ))} 
                          </tbody>
                        </Table>
                        <Paginate page={vPage} pages={pages} keyword={keyword} year={year} month={month}/>
                      </div>
                    </div>
                    :
                    <Message variant="warning">No se encontraron Proveedores</Message>
        }
    </div>
  )
}

export default ProveedoresScreen