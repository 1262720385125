import {
    CLIENT_DETAIL_REQUEST,CLIENT_DETAIL_SUCCESS,CLIENT_DETAIL_FAIL
} from '../constants/clientConstants'

export const clientDetailReducer= (state = {client:[]}, action) =>{
    switch(action.type){
        case CLIENT_DETAIL_REQUEST:
            return {loading:true, client:[]}

        case CLIENT_DETAIL_SUCCESS:
            return {loading:false, client: action.payload}

        case CLIENT_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}