import React, { useEffect} from 'react'
import { Container, Row, Col, Button, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Poliza from '../components/Poliza'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import { listPolizas } from '../actions/polizaActions'
import { useParams, useLocation, useNavigate} from 'react-router-dom'

import SearchBox from '../components/SearchBox'
import FilterDate from '../components/FilterDate'
import { POLIZA_CREATE_RESET } from '../constants/polizaConstants'

import {utils, writeFile} from 'xlsx';
import { ENDOSO_LIST_RESET } from '../constants/endosoConstants'
import { PAGO_LIST_RESET } from '../constants/pagoConstants'
import './index.css'

function PolizasScreen() {

    let navigate = useNavigate()
    const match = useParams()

    const dispatch = useDispatch()

    const polizaList = useSelector(state => state.polizaList)
    const{error, loading, polizas, page, pages} = polizaList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const polizaDelete = useSelector(state => state.polizaDelete)
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = polizaDelete

    let clave = 0
    if(userInfo){
        clave = userInfo.cliente_id
    }

    let vPage = 1
    if(page > pages){
        vPage = pages
    }else if (page < 1){
        vPage = 1
    }else{
        vPage = page
    }

    const keyword = useLocation().search
    let year = ''
    let month = ''

    if(keyword){
        year = keyword.split('year=')[1].split('&')[0]
        month = keyword.split('month=')[1].split('&')[0]
    }

    useEffect(()=>{
        dispatch({ type: POLIZA_CREATE_RESET })
        dispatch({ type: ENDOSO_LIST_RESET })
        dispatch({ type: PAGO_LIST_RESET })

        dispatch(listPolizas(clave, keyword))

    }, [dispatch, match, clave, keyword, successDelete, userInfo])

    const createProductHandler = () => {
        navigate(`/polizas/create`)
    }

    const handleOnExport = () => {
        var wb = utils.book_new(),
        ws = utils.json_to_sheet(polizas)
    
        utils.book_append_sheet(wb,ws, "Sheet12");
    
        writeFile(wb, "Ejemplo.xlsx");
      }

    return (
        <div>
                <br/>
                <Row  className='align-items-center'>   
                    <Col sm="4"> <h3>Inventario de Seguros</h3></Col>
                    <Col sm="5"><SearchBox year={year} month={month}/></Col>
                    <Col className='text-end'><Button variant='success' onClick={createProductHandler}>Nuevo Seguro</Button></Col> 
                    <Col>
                        <Button className='btn btn-light my-3' style={{background:'#eeeeee'}} onClick={handleOnExport}><i className="fas fa-download"></i> Exportar</Button>
                    </Col>
                </Row>
                <hr/>
                {loading ? <Loader/>
                    : error ? <Message variant="danger">{error}</Message>
                        : polizas.length ?
                        <div>
                        <div style={{maxHeight:'64vh', maxWidth:'100%', overflowY:'auto'}}>
                            <Table className="fixed-header-table">
                                <thead>
                                    <tr className="text-center">
                                        <th>Póliza</th>
                                        <th>Aseguradora</th>
                                        <th>Prima Total</th>
                                        <th>Moneda</th>
                                        <th>Inicio Vigencia</th>
                                        <th><Button variant='success' onClick={createProductHandler}>Nuevo Seguro</Button></th>
                                   </tr>
                                </thead>
                                <tbody>
                                    {polizas.map(poliza =>(
                                        <Poliza key={poliza._id} poliza={poliza}/>
                                    ))} 
                                </tbody>
                            </Table>
                            <Paginate page={vPage} pages={pages} keyword={keyword} year={year} month={month}/>
                            </div>
                        </div>
                        :
                        <Message variant="warning">No se encontraron Pólizas</Message>
            }
        </div>
      )
}

export default PolizasScreen