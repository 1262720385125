export const PROCESO_LIST_REQUEST = 'PROCESO_LIST_REQUEST'
export const PROCESO_LIST_SUCCESS = 'PROCESO_LIST_SUCCESS'
export const PROCESO_LIST_FAIL = 'PROCESO_LIST_FAIL'
export const PROCESO_LIST_RESET = 'PROCESO_LIST_RESET'

export const PROCESO_UPDATE_REQUEST= 'PROCESO_UPDATE_REQUEST'
export const PROCESO_UPDATE_SUCCESS= 'PROCESO_UPDATE_SUCCESS'
export const PROCESO_UPDATE_FAIL= 'PROCESO_UPDATE_FAIL'
export const PROCESO_UPDATE_RESET= 'PROCESO_UPDATE_RESET'

export const PROCESO_DELETE_REQUEST = 'PROCESO_DELETE_REQUEST'
export const PROCESO_DELETE_SUCCESS = 'PROCESO_DELETE_SUCCESS'
export const PROCESO_DELETE_FAIL = 'PROCESO_DELETE_FAIL'

export const PROCESO_CREATE_REQUEST = 'PROCESO_CREATE_REQUEST'
export const PROCESO_CREATE_SUCCESS = 'PROCESO_CREATE_SUCCESS'
export const PROCESO_CREATE_FAIL = 'PROCESO_CREATE_FAIL'
export const PROCESO_CREATE_RESET = 'PROCESO_CREATE_RESET'