import { 

    DASHBOARD_COUNTS_REQUEST,
    DASHBOARD_COUNTS_SUCCESS,
    DASHBOARD_COUNTS_FAIL,

    DASHBOARD_VENCIMIENTOS_REQUEST,
    DASHBOARD_VENCIMIENTOS_SUCCESS,
    DASHBOARD_VENCIMIENTOS_FAIL,

    DASHBOARD_INCIDENCIAS_REQUEST,
    DASHBOARD_INCIDENCIAS_SUCCESS,
    DASHBOARD_INCIDENCIAS_FAIL,

    VENCIMIENTO_ATENDIDO_REQUEST,
    VENCIMIENTO_ATENDIDO_SUCCESS,
    VENCIMIENTO_ATENDIDO_FAIL,

} from '../constants/dashboardConstants'

export const dashboardCountsReducer = (state = {counts:[]}, action) =>{
    switch(action.type){
        case DASHBOARD_COUNTS_REQUEST:
            return {loading:true, counts:[]}

        case DASHBOARD_COUNTS_SUCCESS:
            return {loading:false, 
                counts: action.payload,
            }

        case DASHBOARD_COUNTS_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const dashboardVencimientosReducer = (state = {acciones:[]}, action) =>{
    switch(action.type){
        case DASHBOARD_VENCIMIENTOS_REQUEST:
            return {loading:true, acciones:[]}

        case DASHBOARD_VENCIMIENTOS_SUCCESS:
            return {loading:false, 
                acciones: action.payload,
            }

        case DASHBOARD_VENCIMIENTOS_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const vencimientoAtendidoReducer = (state = {accion:[]}, action) =>{
    switch(action.type){
        case VENCIMIENTO_ATENDIDO_REQUEST:
            return {loading:true, accion:[]}

        case VENCIMIENTO_ATENDIDO_SUCCESS:
            return {loading:false, 
                accion: action.payload,
            }

        case VENCIMIENTO_ATENDIDO_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const dashboardIncidenciasReducer = (state = {global:[],incumplimiento:[],siniestro:[]}, action) =>{
    switch(action.type){
        case DASHBOARD_INCIDENCIAS_REQUEST:
            return {loading:true, global:[], incumplimiento:[],siniestro:[]}

        case DASHBOARD_INCIDENCIAS_SUCCESS:
            return {loading:false, 
                global: action.payload.global,
                incumplimiento: action.payload.incumplimiento,
                siniestro: action.payload.siniestro
            }

        case DASHBOARD_INCIDENCIAS_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}