import { 
    PROCESO_LIST_FAIL,
    PROCESO_LIST_REQUEST,
    PROCESO_LIST_SUCCESS,

    PROCESO_UPDATE_REQUEST,
    PROCESO_UPDATE_SUCCESS,
    PROCESO_UPDATE_FAIL,

    PROCESO_CREATE_REQUEST,
    PROCESO_CREATE_SUCCESS,
    PROCESO_CREATE_FAIL,

    PROCESO_DELETE_REQUEST,
    PROCESO_DELETE_SUCCESS,
    PROCESO_DELETE_FAIL,

} from '../constants/procesoConstants'

import axios from 'axios'

export const listProcesos = (idCliente, keyword = '') => async (dispatch, getState) => {

    try{
        dispatch({type: PROCESO_LIST_REQUEST})

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.get(`/api/procesos/${idCliente}${keyword}`, config)

        dispatch({
            type: PROCESO_LIST_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({
            type: PROCESO_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const createProceso = (proceso) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROCESO_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        
        const formData = new FormData()
        formData.append('nombreProyecto',proceso.nombreProyecto)
        formData.append('fechaAlta',proceso.fechaAlta)
        formData.append('file1',proceso.file1)
        formData.append('file2',proceso.file2)
        formData.append('file3',proceso.file3)

        const { data } = await axios.post(
            `/api/procesos/create/`,
            formData,
            config
        )

        dispatch({
            type: PROCESO_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: PROCESO_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateProceso = (proceso) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROCESO_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/procesos/update/${proceso._id}/`,
            proceso,
            config
        )
        dispatch({
            type: PROCESO_UPDATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: PROCESO_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteProceso = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PROCESO_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/procesos/delete/${id}/`,
            config
        )

        dispatch({
            type: PROCESO_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: PROCESO_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}