import React, {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'

import { login } from '../actions/userActions'

function LoginScreen() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  let navigate = useNavigate()

  const dispatch = useDispatch()

  const userLogin = useSelector(state => state.userLogin)
  const {error, loading, userInfo} = userLogin

  useEffect(() =>{
    if(userInfo){
      navigate(`/home`)
    }
  }, [navigate, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <FormContainer>
      <h1>Inicia Sesión</h1>
      <br/>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader/>}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Dirección de Correo: </Form.Label>
          <Form.Control
            type= 'email'
            placeholder='Ingrese Correo'
            value={email}
            onChange={(e)=> setEmail(e.target.value)}
          >  
          </Form.Control>
        </Form.Group>
        <br/>
        <Form.Group controlId='password'>
          <Form.Label>Contraseña: </Form.Label>
          <Form.Control
            type= 'password'
            placeholder='Ingrese Contraseña'
            value={password}
            onChange={(e)=> setPassword(e.target.value)}
          >  
          </Form.Control>
        </Form.Group>
        <br/>
        <Button style={{background:'#47d7ac'}} type='submit'>
          Inicia Sesión
        </Button>

      </Form>
    </FormContainer>
  )
}

export default LoginScreen