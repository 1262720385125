export const DASHBOARD_COUNTS_REQUEST = 'DASHBOARD_COUNTS_REQUEST'
export const DASHBOARD_COUNTS_SUCCESS = 'DASHBOARD_COUNTS_SUCCESS'
export const DASHBOARD_COUNTS_FAIL = 'DASHBOARD_COUNTS_FAIL'

export const DASHBOARD_VENCIMIENTOS_REQUEST = 'DASHBOARD_VENCIMIENTOS_REQUEST'
export const DASHBOARD_VENCIMIENTOS_SUCCESS = 'DASHBOARD_VENCIMIENTOS_SUCCESS'
export const DASHBOARD_VENCIMIENTOS_FAIL = 'DASHBOARD_VENCIMIENTOS_FAIL'

export const DASHBOARD_INCIDENCIAS_REQUEST = 'DASHBOARD_INCIDENCIAS_REQUEST'
export const DASHBOARD_INCIDENCIAS_SUCCESS = 'DASHBOARD_INCIDENCIAS_SUCCESS'
export const DASHBOARD_INCIDENCIAS_FAIL = 'DASHBOARD_INCIDENCIAS_FAIL'

export const VENCIMIENTO_ATENDIDO_REQUEST = 'VENCIMIENTO_ATENDIDO_REQUEST'
export const VENCIMIENTO_ATENDIDO_SUCCESS = 'VENCIMIENTO_ATENDIDO_SUCCESS'
export const VENCIMIENTO_ATENDIDO_FAIL = 'VENCIMIENTO_ATENDIDO_FAIL'