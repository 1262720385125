import React, {useEffect} from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import './index.css'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FilePoliza from '../components/FilePoliza';
import Message from '../components/Message';
import { useDispatch, useSelector } from 'react-redux';
import { listDocumentos } from '../actions/documentoActions';
import { useParams } from 'react-router-dom';

function PolizaDocScreen() {
    
    const match = useParams()
    const [keyword, setKeyword] = React.useState('')

    const documentoList = useSelector(state => state.documentoList)
    const{ documentos } = documentoList 

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(listDocumentos('seguro', match.id,'?keyword='+keyword))
    }, [dispatch, match.id, keyword])

    const handleClick = () => {
        window.history.back();
    };

    const submitHandler = (e) => {
        e.preventDefault()
    }
  return (
    <div>
        <Row className="d-flex align-items-center vh-10" >
            <Col sm="2">
                <Button className='btn btn-light my-3' onClick={handleClick}>Regresar</Button>
            </Col>
            <Col sm="8">
                <h4>Documentos</h4>
            </Col>
            <Col className='text-end pe-4'>
                <Button className='btn btn-info btn-sm'>Agregar Archivo</Button>
            </Col>
            <hr/>
        </Row>
        <Row className='d-flex p-3'>
            <Col sm={3} className='p-2'>
                <div className='folder-card'>
                    <div className='pt-3 ps-3'>
                        <h6>Carpeta</h6>
                    </div>
                    <hr/>
                    <div className='pt-1 p-3'>
                        <div className='icon-card'>
                            <Row>
                                <Col sm={3} className='text-center'>
                                    <i className='fas fa-folder-open'/>
                                </Col>
                                <Col sm={9}>
                                    <span>S-540030</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={9} className='p-2'>
                <Row>
                    <Col>
                        <div className='files-card'>
                            <div className='pt-3 ps-3'>
                                <h6>Archivos</h6>
                            </div>
                            <hr/>
                            <div className='pt-1 pb-1 p-3'>
                                <Form onSubmit={submitHandler}>
                                    <TextField 
                                        style={{backgroundColor:'#fafafa'}}
                                        id="sumaAsegurada"
                                        label="Busqueda" 
                                        variant='outlined'
                                        size="small"
                                        value={keyword}
                                        type='text'
                                        fullWidth 
                                        onChange={(event) => {
                                            setKeyword(event.target.value);
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><i className='fas fa-search'/></InputAdornment>,
                                        }}
                                    />
                                </Form>
                            </div>
                            <div className='p-3' style={{maxHeight:'65%', maxWidth:'100%', overflowY:'auto'}}>
                                {documentos.length >0 ? documentos.map(documento =>(
                                    <FilePoliza documento={documento}/>
                                ))
                                :
                                <Message variant='info'>No hay Documentos Asociados</Message>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
  )
}

export default PolizaDocScreen