import { 
    ENDOSO_LIST_FAIL,
    ENDOSO_LIST_REQUEST,
    ENDOSO_LIST_SUCCESS,

    ENDOSO_DETAIL_REQUEST,
    ENDOSO_DETAIL_SUCCESS,
    ENDOSO_DETAIL_FAIL,
    ENDOSO_LIST_RESET,

    ENDOSO_UPDATE_REQUEST,
    ENDOSO_UPDATE_SUCCESS,
    ENDOSO_UPDATE_FAIL,
    ENDOSO_UPDATE_RESET,

    ENDOSO_CREATE_REQUEST,
    ENDOSO_CREATE_SUCCESS,
    ENDOSO_CREATE_FAIL,
    ENDOSO_CREATE_RESET,

    ENDOSO_DELETE_REQUEST,
    ENDOSO_DELETE_SUCCESS,
    ENDOSO_DELETE_FAIL,

} from '../constants/endosoConstants'

export const endosoListReducer = (state = {endosos:[]}, action) =>{
    switch(action.type){
        case ENDOSO_LIST_REQUEST:
            return {loading:true, ...state}

        case ENDOSO_LIST_SUCCESS:
            return {loading:false, 
                endosos: action.payload,
            }

        case ENDOSO_LIST_FAIL:
            return {loading:false, error: action.payload}

        case ENDOSO_LIST_RESET:
            return {endosos:[]}

        default:
            return state
    }
}

export const endosoDetailReducer= (state = {endoso:[]}, action) =>{
    switch(action.type){
        case ENDOSO_DETAIL_REQUEST:
            return {loading:true, endoso:[]}

        case ENDOSO_DETAIL_SUCCESS:
            return {loading:false, endoso: action.payload}

        case ENDOSO_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const endosoCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case ENDOSO_CREATE_REQUEST:
            return { loading: true }

        case ENDOSO_CREATE_SUCCESS:
            return { loading: false, success: true, endoso: action.payload }

        case ENDOSO_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case ENDOSO_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const endosoUpdateReducer = (state = { endoso: {} }, action) => {
    switch (action.type) {
        case ENDOSO_UPDATE_REQUEST:
            return { loading: true }

        case ENDOSO_UPDATE_SUCCESS:
            return { loading: false, success: true, endoso: action.payload }

        case ENDOSO_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case ENDOSO_UPDATE_RESET:
            return { endoso: {} }

        default:
            return state
    }
}

export const endosoDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ENDOSO_DELETE_REQUEST:
            return { loading: true }

        case ENDOSO_DELETE_SUCCESS:
            return { loading: false, success: true }

        case ENDOSO_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}