import axios from 'axios'

import { 
    PROYECTO_LIST_FAIL,
    PROYECTO_LIST_REQUEST,
    PROYECTO_LIST_SUCCESS,

} from '../constants/proyectoConstants'

export const listProyectos = (cliente, keyword = '') => async (dispatch) => {
    try{
        dispatch({type: PROYECTO_LIST_REQUEST}) 

        const {data} = await axios.get(`/api/proyectos/${cliente}${keyword}`)

        dispatch({
            type: PROYECTO_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: PROYECTO_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}