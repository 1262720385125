import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { deleteProveedor } from '../actions/proveedoresActions'

import { formatearFecha } from '../utils/utils'

function Proveedor({proveedor}) {

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const dispatch = useDispatch()

  const deleteHandler = (id) => {
 
    if (window.confirm('¿Estás seguro de eliminar a este proveedor?')) {
        dispatch(deleteProveedor(id))
    }
  }

  return (
    <tr>
            <td className="text-center">{proveedor.nombreProveedor}</td>
            <td className="text-center">{proveedor.rfc}</td>
            <td className="text-center">{formatearFecha(proveedor.antiguedad)}</td>
            <td className="text-center">{proveedor.giro}</td>
            <td className="text-center">
              <Row>
                <Col>               
                  <LinkContainer to={`/proveedores/${proveedor.id}`}>
                    <Button variant='light' className='btn-sm'>
                      <i className='fas fa-eye'></i>
                    </Button>
                  </LinkContainer>
                  <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(proveedor.id)}>
                    <i className='fas fa-trash'></i>
                  </Button>
                </Col>
              </Row>
            </td>
    </tr>
    
  )
}

export default Proveedor