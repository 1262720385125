import { 
    FIANZA_LIST_FAIL,
    FIANZA_LIST_REQUEST,
    FIANZA_LIST_SUCCESS,

    FIANZA_LIST_CONTRATO_FAIL,
    FIANZA_LIST_CONTRATO_REQUEST,
    FIANZA_LIST_CONTRATO_SUCCESS,

    FIANZA_DETAIL_REQUEST,
    FIANZA_DETAIL_SUCCESS,
    FIANZA_DETAIL_FAIL,

    FIANZA_VIGENCIA_REQUEST,
    FIANZA_VIGENCIA_SUCCESS,
    FIANZA_VIGENCIA_FAIL,

    FIANZA_TOP_VENCIDAS_REQUEST,
    FIANZA_TOP_VENCIDAS_SUCCESS,
    FIANZA_TOP_VENCIDAS_FAIL,

    FIANZA_UPDATE_REQUEST,
    FIANZA_UPDATE_SUCCESS,
    FIANZA_UPDATE_FAIL,
    FIANZA_UPDATE_RESET,

    FIANZA_CREATE_REQUEST,
    FIANZA_CREATE_SUCCESS,
    FIANZA_CREATE_FAIL, 
    FIANZA_CREATE_RESET,

    FIANZA_DELETE_REQUEST,
    FIANZA_DELETE_SUCCESS,
    FIANZA_DELETE_FAIL,
    FIANZA_LIST_CONTRATO_RESET,


} from '../constants/fianzaConstants'


export const fianzaListReducer = (state = {fianzas:[]}, action) =>{
    switch(action.type){
        case FIANZA_LIST_REQUEST:
            return {loading:true, fianzas:[]}

        case FIANZA_LIST_SUCCESS:
            return {loading:false, 
                fianzas: action.payload.fianzas, 
                page: action.payload.page, 
                pages: action.payload.pages
            }

        case FIANZA_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const fianzaContratoListReducer = (state = {fianzas:[]}, action) =>{
    switch(action.type){
        case FIANZA_LIST_CONTRATO_REQUEST:
            return {loading:true, fianzas:[]}

        case FIANZA_LIST_CONTRATO_SUCCESS:
            return {loading:false, 
                fianzas: action.payload.fianzas
            }

        case FIANZA_LIST_CONTRATO_FAIL:
            return {loading:false, error: action.payload}

        case FIANZA_LIST_CONTRATO_RESET:
            return {fianzas:[]}

        default:
            return state
    }
}

export const fianzaDetailReducer= (state = {fianza:[]}, action) =>{
    switch(action.type){
        case FIANZA_DETAIL_REQUEST:
            return {loading:true, fianza:[]}

        case FIANZA_DETAIL_SUCCESS:
            return {loading:false, fianza: action.payload}

        case FIANZA_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const fianzaVigenciaReducer= (state = {fianzas:[]}, action) =>{
    switch(action.type){
        case FIANZA_VIGENCIA_REQUEST:
            return {loading:true, fianzas:[]}

        case FIANZA_VIGENCIA_SUCCESS:
            return {loading:false, fianzas: action.payload}

        case FIANZA_VIGENCIA_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const fianzaTopVencidasReducer= (state = {vencidas:[]}, action) =>{
    switch(action.type){
        case FIANZA_TOP_VENCIDAS_REQUEST:
            return {loading:true, vencidas:[]}

        case FIANZA_TOP_VENCIDAS_SUCCESS:
            return {loading:false, vencidas: action.payload}

        case FIANZA_TOP_VENCIDAS_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const fianzaCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case FIANZA_CREATE_REQUEST:
            return { loading: true }

        case FIANZA_CREATE_SUCCESS:
            return { loading: false, success: true, fianza: action.payload }

        case FIANZA_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case FIANZA_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const fianzaUpdateReducer = (state = { fianza: {} }, action) => {
    switch (action.type) {
        case FIANZA_UPDATE_REQUEST:
            return { loading: true }

        case FIANZA_UPDATE_SUCCESS:
            return { loading: false, success: true, fianza: action.payload }

        case FIANZA_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case FIANZA_UPDATE_RESET:
            return { fianza: {} }

        default:
            return state
    }
}

export const fianzaDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case FIANZA_DELETE_REQUEST:
            return { loading: true }

        case FIANZA_DELETE_SUCCESS:
            return { loading: false, success: true }

        case FIANZA_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

