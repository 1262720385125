import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { createPoliza } from '../actions/polizaActions'
import { POLIZA_UPDATE_RESET } from '../constants/polizaConstants'
import { useParams, useNavigate } from 'react-router-dom'
import { listContratos } from '../actions/contratoActions'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

function PolizaCreateScreen() {

    let navigate = useNavigate()
    const match = useParams()

    var today = new Date()

    function formatDate(d){
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }
    
    var date2 = (today.getFullYear()+ 1) + '-' + (today.getMonth()<9 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()
    
    const [idContrato, setIdContrato] = useState(null)
    const [moneda, setMoneda] = useState(null)
    const [numeroPoliza, setNumeroPoliza] = useState('')
    const [ramoSeguro, setRamoSeguro] = useState(null)
    const [claveAgente, setClaveAgente] = useState('')
    const [aseguradora, setAseguradora] = useState(null)
    const [sumaAsegurada, setSumaAsegurada] = useState('')
    const [inicioVigencia, setInicioVigencia] = useState(formatDate(today))
    const [finVigencia, setFinVigencia] = useState(date2)
    const [primaNeta, setPrimaNeta] = useState('')
    const [primaTotal, setPrimaTotal] = useState('')
    const [observaciones, setObservaciones] = useState('')

    const dispatch = useDispatch()

    const polizaList = useSelector(state => state.polizaDetailList)
    const { error, loading, poliza } = polizaList

    const polizaCreate = useSelector(state => state.polizaCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, poliza: createdPoliza } = polizaCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const contratoList = useSelector(state => state.contratoList)
    const{errorC, loadingC, contratos, page, pages} = contratoList

    useEffect(() => {
        if (contratos.length === 0){
            dispatch(listContratos(userInfo.cliente_id))
        }

        if (successCreate) {
            dispatch({ type: POLIZA_UPDATE_RESET })
            navigate(`/polizas/${createdPoliza._id}/pdf`)
        }

        if (aseguradora !== null) {
            if (aseguradora === 'ATLAS') {
                setClaveAgente('2976')
            }
            else if (aseguradora === 'CHUBB') {
                setClaveAgente('141663')
            }
            else if (aseguradora === 'GNP') {
                setClaveAgente('79692')
            }
            else if (aseguradora === 'GMX') {
                setClaveAgente('689')
            }
        }

    }, [dispatch, match, successCreate, createPoliza, navigate, aseguradora])

    let contratoID = 0
    if (idContrato !== null) {
        contratoID = idContrato._id
    }
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createPoliza({
            contratoID,
            moneda,
            numeroPoliza, 
            ramoSeguro,
            claveAgente,
            aseguradora,
            sumaAsegurada,
            primaNeta,
            primaTotal,
            inicioVigencia,
            finVigencia,
            observaciones,
        }))
    }

    const defaultProps = {
        options: contratos,
        getOptionLabel: (option) => option.numeroContrato,
    };

    const optionSeguro=['TRC', 'RC', 'RCP','Automóviles', 'Equipo Electrónico']
    const optionAseguradora=['ATLAS','CHUBB', 'GNP', 'GMX']
    const optionMoneda=['MXN', 'USD', 'EUR']

    const handleClick = () => {
        window.history.back();
    };

    return (
        <div>
            
            <Button onClick={handleClick} className='btn btn-light my-3'>
                Regresar
            </Button>

            <div className='pt-2 p-5'>
                <h1>Nuevo Seguro </h1>
                <h6>{aseguradora!== null && `En ${aseguradora} Se trabaja con la clave de Agente: ${claveAgente}`}</h6>
                {loadingCreate && <Loader />}
                {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Row>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                {...defaultProps}
                                value={idContrato}
                                onChange={(event, newValue) => {
                                    setIdContrato(newValue);
                                }}
                                autoComplete
                                disablePortal
                                id="idContrato"
                                options={contratos}
                                getOptionLabel={(option) => option.numeroContrato}
                                renderInput={(params) => <TextField {...params} label="Contrato"/>}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="numeroSeguro"
                                label="Número Seguro" 
                                variant="outlined"
                                type='text'
                                value={numeroPoliza}
                                fullWidth 
                                onChange={(event) => {
                                    setNumeroPoliza(event.target.value);
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={ramoSeguro}
                                onChange={(event, newValue) => {
                                    setRamoSeguro(newValue);
                                }}
                                disablePortal
                                id="ramoSeguro"
                                options={optionSeguro}
                                renderInput={(params) => <TextField {...params} label="Ramo Seguro" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={aseguradora}
                                onChange={(event, newValue) => {
                                    setAseguradora(newValue);
                                }}
                                disablePortal
                                id="aseguradora"
                                options={optionAseguradora}
                                renderInput={(params) => <TextField {...params} label="Aseguradora" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={moneda}
                                onChange={(event, newValue) => {
                                    setMoneda(newValue);
                                }}
                                disablePortal
                                id="moneda"
                                options={optionMoneda}
                                renderInput={(params) => <TextField {...params} label="Moneda" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="sumaAsegurada"
                                label="Suma Asegurada" 
                                variant="outlined"
                                value={sumaAsegurada}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setSumaAsegurada(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="primaNeta"
                                label="Prima Neta" 
                                variant="outlined"
                                value={primaNeta}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setPrimaNeta(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="primaTotal"
                                label="Prima Total" 
                                variant="outlined"
                                value={primaTotal}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setPrimaTotal(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="inicioVigencia"
                                label="Inicio Vigencia" 
                                variant="outlined"
                                value={inicioVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setInicioVigencia(event.target.value);
                                }}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="finVigencia"
                                label="Fin Vigencia" 
                                variant="outlined"
                                value={finVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setFinVigencia(event.target.value);
                                }}
                            />
                            </Col>
                            <br/>
                            <hr/>
                            <TextField
                                id="observaciones"
                                label="Observaciones"
                                multiline
                                rows={4}
                                value={observaciones}
                                onChange={(event) => {
                                    setObservaciones(event.target.value);
                                }}
                            />
                            </Row>
                            <br/>
                            <Button type='submit' variant='success'>
                                Guardar
                            </Button>
                        </Form>
                    )}

            </div>
        </div> 

    )
}

export default PolizaCreateScreen