import { 
    INCIDENCIA_LIST_FAIL,
    INCIDENCIA_LIST_REQUEST,
    INCIDENCIA_LIST_SUCCESS,

    INCIDENCIA_DETAIL_REQUEST,
    INCIDENCIA_DETAIL_SUCCESS,
    INCIDENCIA_DETAIL_FAIL,

    INCIDENCIA_UPDATE_REQUEST,
    INCIDENCIA_UPDATE_SUCCESS,
    INCIDENCIA_UPDATE_FAIL,

    INCIDENCIA_CREATE_REQUEST,
    INCIDENCIA_CREATE_SUCCESS,
    INCIDENCIA_CREATE_FAIL,

    INCIDENCIA_DELETE_REQUEST,
    INCIDENCIA_DELETE_SUCCESS,
    INCIDENCIA_DELETE_FAIL,

} from '../constants/incidenciaConstants'

import axios from 'axios'

export const listIncidencias = (idCliente, concepto, keyword = '') => async (dispatch, getState) => {
    try{
        dispatch({type: INCIDENCIA_LIST_REQUEST})

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.get(`/api/incidencias/${idCliente}/${concepto}${keyword}`, config)

        dispatch({
            type: INCIDENCIA_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: INCIDENCIA_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listIncidenciaDetail = (idCliente, match) => async (dispatch) => {
    try{
        dispatch({type: INCIDENCIA_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/incidencias/detail/${idCliente}/${match.id}`)

        dispatch({
            type: INCIDENCIA_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: INCIDENCIA_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const createIncidencia = (incidencia) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INCIDENCIA_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/incidencias/create/`,
            incidencia,
            config
        )
        dispatch({
            type: INCIDENCIA_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: INCIDENCIA_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateIncidencia = (incidencia) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INCIDENCIA_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/incidencias/update/${incidencia._id}/`,
            incidencia,
            config
        )
        dispatch({
            type: INCIDENCIA_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: INCIDENCIA_DETAIL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: INCIDENCIA_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteIncidencia = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: INCIDENCIA_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/incidencias/delete/${id}/`,
            config
        )

        dispatch({
            type: INCIDENCIA_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: INCIDENCIA_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}