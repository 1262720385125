import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Col, Card, Row} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { useParams, useNavigate } from 'react-router-dom'
import { listContratoDetail, updateContrato } from '../actions/contratoActions'
import { CONTRATO_UPDATE_RESET } from '../constants/contratoConstants'


import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { listProyectos } from '../actions/proyectoActions'

function ContratoEditScreen() {

    let navigate = useNavigate()
    const match = useParams()

    const [idProyecto, setIdProyecto] = useState(null)
    const [moneda, setMoneda] = useState('')
    const [tipo, setTipo] = useState('')
    const [categoria, setCategoria] = useState('')
    const [nombreProveedor, setNombreProveedor] = useState('')
    const [numeroContrato, setNumeroContrato] = useState('')
    const [sociedad, setSociedad] = useState('')
    const [valorContrato, setValorContrato] = useState(0)
    const [inicioVigencia, setInicioVigencia] = useState('')
    const [finVigencia, setFinVigencia] = useState('')
    const [requerimientoSeguros, setRequerimientoSeguros] = useState(null)
    const [requerimientoFianzas, setRequerimientoFianzas] = useState(null)
    const [descripcion, setDescripcion] = useState('')

    const dispatch = useDispatch()

    const contratoList = useSelector(state => state.contratoDetailList)
    const { error, loading, contrato } = contratoList

    const contratoUpdate = useSelector(state => state.contratoUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = contratoUpdate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    const proyectoList = useSelector(state => state.proyectoList)
    const{ proyectos } = proyectoList

    useEffect(() => {
        console.log(idProyecto)
        
        if (proyectos.length === 0){
            dispatch(listProyectos(userInfo.cliente_id))
        }

        if (successUpdate) {
            dispatch({ type: CONTRATO_UPDATE_RESET })
            navigate(`/contratos/${contrato._id}?from=create`)

        } else {

            if (!contrato.inicioVigencia || contrato._id !== Number(match.id)) {
                dispatch(listContratoDetail(match))

            } else {
                setMoneda(contrato.moneda)
                setCategoria(contrato.categoria)
                setDescripcion(contrato.descripcion)
                setFinVigencia(contrato.finVigencia)
                setInicioVigencia(contrato.inicioVigencia)
                setTipo(contrato.tipo)
                setNombreProveedor(contrato.nombreProveedor)
                setNumeroContrato(contrato.numeroContrato)
                setSociedad(contrato.sociedad)
                setValorContrato(contrato.valorContrato)
            }
        }
        if (idProyecto !== null) {
            const concepto = idProyecto.concepto
            if (concepto === 'OBRA') {
                setRequerimientoFianzas('Vicios Ocultos')
                setRequerimientoSeguros(['TRC','RC'])
            }
            else if (concepto === 'SERVICIOS') {
                setRequerimientoFianzas('')
                setRequerimientoSeguros(['RC','RCP'])
            }
            else if (concepto === 'SUMINISTROS/COMPRA VENTA') {
                setRequerimientoFianzas('')
                setRequerimientoSeguros(null)
            }
            else if (concepto === 'MANTENIMIENTO') {
                setRequerimientoFianzas('')
                setRequerimientoSeguros(['RC'])
            }
            else if (concepto === 'ARRENDAMIENTO') {
                setRequerimientoFianzas('')
                setRequerimientoSeguros(['Automóviles','Equipo Electrónico'])
            }
        }
        

    }, [dispatch, contrato, match, successUpdate, navigate, idProyecto])

    let proyectoID = 0
    if (idProyecto !== null) {
        proyectoID = idProyecto._id
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateContrato({ 
            _id: match.id, 
            proyectoID,
            moneda,
            tipo,
            categoria, 
            numeroContrato,
            nombreProveedor,
            sociedad,
            valorContrato,
            inicioVigencia,
            finVigencia,
            requerimientoFianzas,
            requerimientoSeguros,
            descripcion,
        }))
    }

    const defaultPropsProyectos = {
        options: proyectos,
        getOptionLabel: (option) => option.concepto,
    };
    
    const optionSociedad=['OPCEM', 'Concesionaria Mexiquense']
    const optionMoneda=['MXN', 'USD', 'EUR']

    return (
        <div>
            
            <Link to={`/contratos/${contrato._id}?from=create`} className='btn btn-light my-3'>
                Regresar
            </Link>

            
                <h1>Editar Contrato </h1>
                {contrato && <h6>Tipo de compra actual: {contrato.proyecto}</h6>}
                {loadingUpdate && <Loader />}
                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                      <Form onSubmit={submitHandler}>
                        <Row>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                {...defaultPropsProyectos}
                                value={idProyecto}
                                onChange={(event, newValue) => {
                                    setIdProyecto(newValue);
                                }}
                                autoComplete
                                disablePortal
                                id="idProyecto"
                                renderInput={(params) => <TextField {...params} label="Tipo de Compra"/>}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="numeroContrato"
                                label="Número Contrato" 
                                variant="outlined"
                                type='text'
                                value={numeroContrato}
                                fullWidth 
                                onChange={(event) => {
                                    setNumeroContrato(event.target.value);
                                }}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={sociedad}
                                onChange={(event, newValue) => {
                                    setSociedad(newValue);
                                }}
                                disablePortal
                                id="sociedad"
                                options={optionSociedad}
                                renderInput={(params) => <TextField {...params} label="Sociedad" />}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={moneda}
                                onChange={(event, newValue) => {
                                    setMoneda(newValue);
                                }}
                                disablePortal
                                id="moneda"
                                options={optionMoneda}
                                renderInput={(params) => <TextField {...params} label="Moneda" />}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="valorContrato"
                                label="Valor del Contrato" 
                                variant="outlined"
                                value={valorContrato}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setValorContrato(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                                }}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="inicioVigencia"
                                label="Inicio Vigencia" 
                                variant="outlined"
                                value={inicioVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setInicioVigencia(event.target.value);
                                }}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="finVigencia"
                                label="Fin Vigencia" 
                                variant="outlined"
                                value={finVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setFinVigencia(event.target.value);
                                }}
                            />
                            </Col> 
                            <br/>
                            <hr/>
                            <Col sm={6} className='p-3'>
                                Requerimiento Seguros
                                <Card className='p-3' style={{backgroundColor:'#fafafa'}}>
                                    {requerimientoSeguros === null
                                    ?
                                    <div style={{height:'13vh'}}>
                                        No se requieren seguros para el tipo de compra seleccionado
                                    </div>
                                    :
                                    <div style={{height:'13vh'}}>Se requieren los siguientes seguros
                                        <il>
                                            {requerimientoSeguros.map(req =>(
                                                <li>Seguro de {req}</li>
                                            ))}
                                        </il>
                                    </div>
                                    }
                                </Card>
                            </Col> 
                            <br/>
                            <Col sm={6} className='p-3'>
                                Requerimiento Fianzas
                                <Card className='p-3' style={{backgroundColor:'#fafafa'}}>
                                {requerimientoFianzas === null
                                    ?
                                    <div style={{height:'13vh'}}>No se requieren fianzas para el tipo de compra seleccionado</div>
                                    :
                                    <div style={{height:'13vh'}}>Se requieren lass siguientes fianzas
                                        <il>
                                            <li>Fianza de Anticipo</li>
                                            <li>Fianza de Cumplimiento</li>
                                            {requerimientoFianzas !== '' && 
                                                <li>Fianza de {requerimientoFianzas}</li>
                                            }
                                        </il>
                                    </div>
                                }
                                </Card>
                            </Col> 
                            <br/>
                            <hr/>
                            <TextField
                                id="descripcion"
                                label="Descripción"
                                multiline
                                rows={4}
                                value={descripcion}
                                onChange={(event) => {
                                    setDescripcion(event.target.value);
                                }}
                            />
                            </Row>
                            <br/>
                        <Button type='submit' variant='success'>
                            Guardar
                        </Button>
                      </Form>
                    )}
        </div> 

    )
}

export default ContratoEditScreen