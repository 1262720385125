import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFianza } from '../actions/fianzaActions'
import { LinkContainer } from 'react-router-bootstrap'

import { formatearFecha, currency } from '../utils/utils'

function Fianza({fianza}) {

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const dispatch = useDispatch()

  const deleteHandler = (id) => {
 
    if (window.confirm('¿Estás seguro de eliminar esta fianza?')) {
        dispatch(deleteFianza(id))
    }
  }
  return (
    <tr>
      <td className="text-center"> {fianza.numeroFianza}</td>
      <td className="text-center">{fianza.afianzadora}</td>
      <td className="text-center">{fianza.beneficiario}</td>
      <td className="text-center">{currency(fianza.montoAfianzado)}</td>
      <td className="text-center">{formatearFecha(fianza.inicioVigencia)}</td>
      <td className="text-center">
        <Row>
          <Col>               
            <LinkContainer to={`/fianzas/${fianza._id}`}>
              <Button variant='light' className='btn-sm'>
                <i className='fas fa-eye'></i>
              </Button>
            </LinkContainer>
            <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(fianza._id)}>
              <i className='fas fa-trash'></i>
            </Button>
          </Col>
        </Row>
      </td>
    </tr>
  )
}

export default Fianza
