import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Row, Col,Card, Button } from 'react-bootstrap'
import { listFianzaDetail } from '../actions/fianzaActions'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Comment from '../components/Comment'
import Endoso from '../components/Endoso'
import { listEndosos } from '../actions/endosoActions'
import { listPagos } from '../actions/pagoActions'
import Pago from '../components/Pago'
import { PAGO_ENDOSO_LIST_RESET } from '../constants/pagoConstants'

import { formatearFecha, currency } from '../utils/utils'

function FianzaDetailScreen() {

  let navigate = useNavigate()
  const location = useLocation();
  const match = useParams()
  
  const dispatch = useDispatch()
  const fianzaList = useSelector(state => state.fianzaDetailList)
  const{error, loading, fianza} = fianzaList

  const endosoList = useSelector(state => state.endosoList)
  const {endosos} = endosoList

  const pagoList = useSelector(state => state.pagoList)
  const {pagos} = pagoList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(()=>{
    
    dispatch({ type: PAGO_ENDOSO_LIST_RESET })

    if (!fianza.inicioVigencia || parseInt(match.id) != parseInt(fianza._id)){
        dispatch(listFianzaDetail(userInfo.cliente_id, match))
    }else {
        if(endosos.length === 0){
            dispatch(listEndosos(fianza._id,'fianza'))
        }
        if(pagos.length === 0){
            dispatch(listPagos(fianza._id,'fianza'))
        }
    }
  }, [dispatch, fianza.numeroFianza, endosos.length, pagos.length, userInfo.cliente_id, match])

  const createEndosoHandler = () => {
    navigate(`/endosos/${fianza._id}/create?concepto=fianza`)
  };

  const createPagoHandler = () => {
    navigate(`/pagos/${fianza._id}/create?concepto=fianza`)
  };
  const fromCreate = location.search === '?from=create'
  const handleClick = () => {
    if (fromCreate) {
      // Si se llegó desde la tercera forma, redirige a otro lado
      navigate('/fianzas/');
    } else {
      // En caso contrario, regresa a la página anterior
      navigate(-1);
    }
  };
  return (
    <div className='pt-4'>
        <Row className="d-flex align-items-center justify-content-center vh-10" >
            <Col sm="2">
                <Button onClick={handleClick} className='btn btn-light my-3'>Regresar</Button>
            </Col>
            <Col sm="4">
                <h4>Fianza: {fianza.numeroFianza}</h4>
            </Col>
            <Col sm="4">
                <Message variant="info">No hay ninguna acción requerida</Message>
            </Col>
            <Col sm="2" className='text-end'>
                <Comment concepto="fianza" folio={fianza.numeroFianza}/>
            </Col>
            <hr/>
        </Row>
        <Row>
            <Col>
                <Card style={{background:"#f5f5f5", width:"100%"}} className='rounded'>
                    <div className="p-3">
                        <Row className="flex align-items-center justify-content-center">
                            <Col sm="10">
                                <div>
                                    <h6> Detalles Generales de la Fianza</h6>
                                    
                                </div>
                                <hr/>
                                <Row>
                                    <Col>
                                        <strong>Afianzadora:</strong> {fianza.afianzadora}
                                    </Col>
                                    <Col>
                                        <strong>Beneficiario:</strong> {fianza.beneficiario}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>Tipo Fianza:</strong> {fianza.tipoFianza}
                                    </Col>
                                    <Col>
                                        <strong>Monto Afianzado:</strong> {currency(fianza.montoAfianzado)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>Inicio Vigencia:</strong> {formatearFecha(fianza.inicioVigencia)}
                                    </Col>
                                    <Col>
                                        <strong>Fin Vigencia:</strong> {formatearFecha(fianza.finVigencia)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>Observaciones:</strong> {fianza.observaciones}
                                    </Col>
                                </Row>
                            </Col>
                            <Col >
                                <Link to={`/fianzas/${userInfo.cliente_id}/${fianza._id}/edit`} style={{width:'100%'}} className='btn btn-info'>Editar Fianza</Link>
                                <br/>
                                <br/>
                                <Link to={`/fianzas/${fianza._id}/files`} className='btn btn-success' style={{width:"100%"}}>Ver Documentos</Link>
                            </Col>
                        </Row>
                        
                    </div>
                </Card>
            </Col>
        </Row>
        <Row>
        <Col className='me-2 ms-2'>
            <br/>
            <Row className="d-flex align-items-center justify-content-center">
                <Col>
                    <h4>Endosos</h4>
                </Col>
                <Col className='text-end'>
                    <Button onClick={createEndosoHandler} className="btn-sm" variant="warning" style={{ width:"8vw"}}> Nuevo Endoso</Button>
                </Col>
            </Row>
            <hr/>
            <Row>
                {endosos.length >0 ? 
                    <Endoso endosos={endosos}/>
                    :
                    <Row><Message variant="info">No hay Endosos registrados</Message></Row>
                }
            </Row>
        </Col>
        <Col className='me-2 ms-2'>
            <br/>
            <Row className="d-flex align-items-center justify-content-center">
                <Col>
                    <h4>Pagos</h4>
                </Col>
                <Col className='text-end'>
                    <Button onClick={createPagoHandler} className="btn-sm" variant="warning" style={{ width:"8vw"}}> Nuevo Pago</Button>
                </Col>
            </Row>
            <hr/>
            <Row>
                {pagos.length >0 ? 
                    <Pago pagos={pagos}/>
                    :
                    <Row><Message variant="info">No hay Pagos registrados</Message></Row>
                }
            </Row>
        </Col>
        </Row>
    </div>
  )
}

export default FianzaDetailScreen