import { 
    POLIZA_LIST_FAIL,
    POLIZA_LIST_REQUEST,
    POLIZA_LIST_SUCCESS,

    POLIZA_LIST_CONTRATO_FAIL,
    POLIZA_LIST_CONTRATO_REQUEST,
    POLIZA_LIST_CONTRATO_SUCCESS,

    POLIZA_DETAIL_REQUEST,
    POLIZA_DETAIL_SUCCESS,
    POLIZA_DETAIL_FAIL,

    POLIZA_UPDATE_REQUEST,
    POLIZA_UPDATE_SUCCESS,
    POLIZA_UPDATE_FAIL,

    POLIZA_CREATE_REQUEST,
    POLIZA_CREATE_SUCCESS,
    POLIZA_CREATE_FAIL,

    POLIZA_DELETE_REQUEST,
    POLIZA_DELETE_SUCCESS,
    POLIZA_DELETE_FAIL,

} from '../constants/polizaConstants'

import axios from 'axios'

export const listPolizas = (cliente, keyword = '') => async (dispatch) => {
    try{
        dispatch({type: POLIZA_LIST_REQUEST})

        const {data} = await axios.get(`/api/polizas/${cliente}${keyword}`)

        dispatch({
            type: POLIZA_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: POLIZA_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listPolizasContrato = (cliente, contrato) => async (dispatch) => {
    try{
        dispatch({type: POLIZA_LIST_CONTRATO_REQUEST})

        const {data} = await axios.get(`/api/polizas/contratos/${cliente}/${contrato}`)

        dispatch({
            type: POLIZA_LIST_CONTRATO_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: POLIZA_LIST_CONTRATO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listPolizaDetail = (cliente, match) => async (dispatch) => {
    try{
        dispatch({type: POLIZA_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/polizas/${cliente}/${match.id}`)

        dispatch({
            type: POLIZA_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: POLIZA_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const createPoliza = (poliza) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POLIZA_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/polizas/create/`,
            poliza,
            config
        )
        dispatch({
            type: POLIZA_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: POLIZA_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updatePoliza = (poliza) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POLIZA_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/polizas/update/${poliza._id}/`,
            poliza,
            config
        )
        dispatch({
            type: POLIZA_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: POLIZA_DETAIL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: POLIZA_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deletePoliza = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: POLIZA_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/polizas/delete/${id}/`,
            config
        )

        dispatch({
            type: POLIZA_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: POLIZA_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}