import React, { useEffect} from 'react'
import { Row, Col, Button, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Contrato from '../components/Contrato'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import { listContratos } from '../actions/contratoActions'
import { useParams, useLocation, useNavigate} from 'react-router-dom'

import SearchBox from '../components/SearchBox'
import FilterDate from '../components/FilterDate'

import {utils, writeFile} from 'xlsx';
import { FIANZA_LIST_CONTRATO_RESET } from '../constants/fianzaConstants'
import { POLIZA_LIST_CONTRATO_RESET } from '../constants/polizaConstants'
import { CONTRATO_CREATE_RESET } from '../constants/contratoConstants'

function ContratosScreen() {

    const match = useParams() 

    let navigate = useNavigate()
    const dispatch = useDispatch()

    const contratoList = useSelector(state => state.contratoList)
    const{error, loading, contratos, page, pages} = contratoList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    const contratoCreate = useSelector(state => state.contratoCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, contrato: createdContrato } = contratoCreate

    const contratoDelete = useSelector(state => state.contratoDelete)
    const { loading: loadingDelete, error: errorDelete, success: successDelete } = contratoDelete

    let clave = 0
    if(userInfo){
        clave = userInfo.cliente_id
    }

    let vPage = 1
    if(page > pages){
        vPage = pages
    }else if (page < 1){
        vPage = 1
    }else{
        vPage = page
    }

    const keyword = useLocation().search
    let year = ''
    let month = '' 

    if(keyword){
        year = keyword.split('year=')[1].split('&')[0]
        month = keyword.split('month=')[1].split('&')[0]
    }

    useEffect(()=>{
        dispatch({ type: CONTRATO_CREATE_RESET })
        dispatch({ type: FIANZA_LIST_CONTRATO_RESET })
        dispatch({ type: POLIZA_LIST_CONTRATO_RESET})
        dispatch(listContratos(clave, keyword))

    }, [dispatch, clave, keyword, successDelete, successCreate])

    const createContractHandler = () => {
        navigate(`/contratos/create`)
      }
    
    const handleOnExport = () => {
        var wb = utils.book_new(),
        ws = utils.json_to_sheet(contratos)
    
        utils.book_append_sheet(wb,ws, "Sheet12");
    
        writeFile(wb, "Ejemplo.xlsx");
    }

    return (
        <div>
        
                <br/>
                <Row  className='align-items-center'>   
                    <Col sm="4"> <h3>Inventario de Contratos</h3></Col>
                    <Col sm="4"><SearchBox year={year} month={month}/></Col>
                    <Col className='text-end'><Button variant='success' onClick={createContractHandler}>Nuevo Contrato</Button></Col>
                    <Col>
                    <Button className='btn btn-light my-3' style={{background:'#eeeeee'}} onClick={handleOnExport}><i className="fas fa-download"></i> Exportar</Button>
                    </Col>
                </Row>
                <hr/>
                {loading ? <Loader/>
                    : error ? <Message variant="danger">{error}</Message>
                        : contratos.length ?
                        <div style={{maxHeight:'64vh', maxWidth:'100%', overflowY:'auto'}}>
                            <Table className="fixed-header-table">
                                <thead className='text-center'>
                                    <tr>
                                        <th>Contrato</th>
                                        <th>Proveedor</th>
                                        <th>Sociedad</th>
                                        <th>Monto</th>
                                        <th>Vigencia</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contratos.map(contrato =>(
                                        <Contrato key={contrato._id} contrato={contrato}/>
                                    ))} 
                                </tbody>
                            </Table>
                            <div>
                              <Paginate page={vPage} pages={pages} keyword={keyword} year={year} month={month}/>
                            </div>
                        </div>
                        :
                        <Message variant="warning">No se encontraron Contratos</Message>
            }
            
        </div>
      )
}

export default ContratosScreen