import React, {useState} from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import { createProceso } from '../actions/procesoActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function ProyectosCreateScreen() {
    const dispatch = useDispatch()
    let navigate = useNavigate()

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()

    const [nombreProyecto, setNombreProyecto] = useState(null)
    const [fechaAlta, setFechaAlta] = useState(date)
    const [file1, setFile1] = useState(null)
    const [file2, setFile2] = useState(null)
    const [file3, setFile3] = useState(null)

    const handleClick = () => {
        window.history.back();
    };
    
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createProceso({
            nombreProyecto,
            fechaAlta,
            file1,
            file2,
            file3,
        }))
        navigate('/procesos/')
    }
  return (
    <div>
        <Button onClick={handleClick} className='btn btn-light my-3'>
                Regresar
        </Button>
        <div className='pt-2 p-5'>
            <h1>Nuevo Proyecto </h1>
            <Form onSubmit={submitHandler}>
                <Row>
                    <Col sm={4} className='p-3'>
                        <TextField 
                            id="nombreProyecto"
                            label="Nombre del Proyecto" 
                            variant="outlined"
                            value={nombreProyecto}
                            type='text'
                            required
                            fullWidth 
                            onChange={(event) => {
                                setNombreProyecto(event.target.value);
                            }}
                        />
                    </Col>
                    <Col sm={4} className='p-3'>
                        <TextField 
                            id="fechaAlta"
                            label="Fecha de Alta" 
                            variant="outlined"
                            value={fechaAlta}
                            type='date'
                            fullWidth
                            required
                            onChange={(event) => {
                                setFechaAlta(event.target.value);
                            }}
                        />
                    </Col>
                </Row>
                <hr/>
                <h5>Ficha de Riesgos</h5>
                <Form.Group controlId='pdf'>
                    <Form.Control
                        id='pdf-file'
                        label='Seleccione archivo'
                        type="file"
                        custom
                        required
                        accept="application/pdf"
                        onChange={(e) => setFile1(e.target.files[0])}
                    >
                    </Form.Control>
                </Form.Group>
                <hr/>
                <h5>Clausulas de Garantías y Seguros</h5>
                <Form.Group controlId='pdf'>
                    <Form.Control
                        id='pdf-file'
                        label='Seleccione archivo'
                        type="file"
                        custom
                        required
                        accept="application/pdf"
                        onChange={(e) => setFile2(e.target.files[0])}
                    >
                    </Form.Control>
                </Form.Group>
                <hr/>
                <h5>Solicitud de Compra</h5>
                <Form.Group controlId='pdf'>
                    <Form.Control
                        id='pdf-file'
                        label='Seleccione archivo'
                        type="file"
                        custom
                        required
                        accept="application/pdf"
                        onChange={(e) => setFile3(e.target.files[0])}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
                <Button type='submit' className='btn btn-success'>Guardar</Button>
            </Form>
        </div>
    </div>
  )
}

export default ProyectosCreateScreen