import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { createContrato } from '../actions/contratoActions'
import { CONTRATO_UPDATE_RESET } from '../constants/contratoConstants'
import { useParams, useNavigate } from 'react-router-dom'
import { listProyectos } from '../actions/proyectoActions'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { listProveedores } from '../actions/proveedoresActions'
import { listProcesos } from '../actions/procesoActions'

function ContratoCreateScreen() {

    let navigate = useNavigate()
    const match = useParams()

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate(),
    date2 = (today.getFullYear()+ 1) + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()
    

    const [idProyecto, setIdProyecto] = useState(null)
    const [moneda, setMoneda] = useState(null)
    const [nombreProveedor, setNombreProveedor] = useState(null)
    const [numeroContrato, setNumeroContrato] = useState('')
    const [sociedad, setSociedad] = useState(null)
    const [valorContrato, setValorContrato] = useState('')
    const [inicioVigencia, setInicioVigencia] = useState(date)
    const [finVigencia, setFinVigencia] = useState(date2)
    const [descripcion, setDescripcion] = useState('')

    const [procesosFilter, setProcesosFilter] = useState([])

    const dispatch = useDispatch()

    const contratoList = useSelector(state => state.contratoDetailList)
    const { contrato } = contratoList

    const contratoCreate = useSelector(state => state.contratoCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, contrato: createdContrato } = contratoCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const proyectoList = useSelector(state => state.proyectoList)
    const{error: errorProy, loading: loadingProy, proyectos, page, pages} = proyectoList

    const proveedorList = useSelector(state => state.proveedorList)
    const{errorC, loadingC, proveedores} = proveedorList

    const procesoList = useSelector(state => state.procesoList)
    const{error, loading, procesos} = procesoList

    useEffect(() => {
        dispatch(listProcesos(userInfo.cliente_id))
        

        if(proveedores.length === 0){
            dispatch(listProveedores(userInfo.cliente_id))
        }

        if (successCreate) {
            dispatch({ type: CONTRATO_UPDATE_RESET })
            navigate(`/contratos/${createdContrato._id}/pdf`)
        }        

    }, [dispatch, successCreate, navigate])


    let proyectoID = 0
    if (idProyecto !== null) {
        proyectoID = idProyecto._id
    }

    let proveedorID = 0
    if (nombreProveedor !== null){
        proveedorID = nombreProveedor.id
    }
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createContrato({
            proyectoID,
            moneda,
            numeroContrato,
            proveedorID,
            sociedad,
            valorContrato,
            inicioVigencia,
            finVigencia,
            descripcion,
        }))
    }

    const defaultPropsProcesos = {
        options: procesos.filter(proceso => !proceso.hasContrato),
        getOptionLabel: (option) => option.nombreProyecto,
    };

    const defaultPropsProyectos = {
        options: proyectos,
        getOptionLabel: (option) => option.concepto,
    };

    const defaultPropsProveedores = {
        options: proveedores,
        getOptionLabel: (option) => option.nombreProveedor,
    };

    const optionSociedad=['OPCEM', 'Concesionaria Mexiquense']
    const optionMoneda=['MXN', 'USD', 'EUR']

    const handleClick = () => {
        window.history.back();
    };
    
    return (
        <div>
            
            <Button onClick={handleClick} className='btn btn-light my-3'>
                Regresar
            </Button>

            <div className='pt-2 p-5'>
                <h1>Nuevo Contrato </h1>
                {loadingCreate && <Loader />}
                {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Row>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                {...defaultPropsProveedores}
                                value={nombreProveedor}
                                onChange={(event, newValue) => {
                                    setNombreProveedor(newValue);
                                }}
                                autoComplete
                                disablePortal
                                id="nombreProveedor"
                                renderInput={(params) => <TextField {...params} label="Nombre del Proveedor"/>}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="numeroContrato"
                                label="Número Contrato" 
                                variant="outlined"
                                type='text'
                                value={numeroContrato}
                                fullWidth 
                                onChange={(event) => {
                                    setNumeroContrato(event.target.value);
                                }}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                {...defaultPropsProcesos}
                                value={idProyecto}
                                onChange={(event, newValue) => {
                                    setIdProyecto(newValue);
                                }}
                                autoComplete
                                disablePortal
                                id="idProyecto"
                                renderInput={(params) => <TextField {...params} label="Proceso Inicial"/>}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={sociedad}
                                onChange={(event, newValue) => {
                                    setSociedad(newValue);
                                }}
                                disablePortal
                                id="sociedad"
                                options={optionSociedad}
                                renderInput={(params) => <TextField {...params} label="Sociedad" />}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={moneda}
                                onChange={(event, newValue) => {
                                    setMoneda(newValue);
                                }}
                                disablePortal
                                id="moneda"
                                options={optionMoneda}
                                renderInput={(params) => <TextField {...params} label="Moneda" />}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="valorContrato"
                                label="Valor del Contrato" 
                                variant="outlined"
                                value={valorContrato}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setValorContrato(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                                }}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="inicioVigencia"
                                label="Inicio Vigencia" 
                                variant="outlined"
                                value={inicioVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setInicioVigencia(event.target.value);
                                }}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="finVigencia"
                                label="Fin Vigencia" 
                                variant="outlined"
                                value={finVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setFinVigencia(event.target.value);
                                }}
                            />
                            </Col> 
                            <br/>
                            <hr/>
                            <TextField
                                id="descripcion"
                                label="Descripción"
                                multiline
                                rows={4}
                                value={descripcion}
                                onChange={(event) => {
                                    setDescripcion(event.target.value);
                                }}
                            />
                            </Row>
                            <br/>
                            <Button type='submit' variant='success'>
                                Guardar
                            </Button>
                        </Form>
                    )}

            </div >
        </div> 

    )
}

export default ContratoCreateScreen