import { 
    DOCUMENTO_FAIL,
    DOCUMENTO_REQUEST,
    DOCUMENTO_SUCCESS,

    DOCUMENTO_DETAIL_FAIL,
    DOCUMENTO_DETAIL_REQUEST,
    DOCUMENTO_DETAIL_SUCCESS,
} from '../constants/documentoConstants'

import axios from 'axios'

export const listDocumentos = (concepto, folio, keyword='') => async (dispatch) => {
    try{
        console.log(concepto,folio)
        dispatch({type: DOCUMENTO_REQUEST})

        const {data} = await axios.get(`/api/documentos/${concepto}/${folio}${keyword}`)

        dispatch({
            type: DOCUMENTO_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: DOCUMENTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listDocumentoDetail = (id) => async (dispatch) => {
    try{
        dispatch({type: DOCUMENTO_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/documentos/${id}`)

        dispatch({
            type: DOCUMENTO_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: DOCUMENTO_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}