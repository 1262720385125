import React, { useState } from 'react'
import { Container, Card, Modal, Row, Col, Button, Form } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { formatearFecha, currency } from '../utils/utils'
import { useDispatch } from 'react-redux';
import { updatePago } from '../actions/pagoActions';

function ModalPago({ pago, show, handleClose}) {

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()

    const [fechaLimitePago, setFechaLimitePago] = useState(pago.fechaLimitePago)
    const [fechaPago, setFechaPago] = useState(date)
    const [factura, setFactura] = useState(pago.numeroFactura)
    const [isChecked, setIsChecked] = useState(pago.isPaid)
    const [editar, setEditar] = useState(false)

    const handleModalClose = () => {
        // Llamar a la función handleClose para actualizar el estado de "show"
        handleClose();
    }

    const [priority, setPriority] = useState({
        false: '#F45C5C',
        true: '#69CB3C',
    })

    const dispatch = useDispatch()

    let _id = 0
    if (pago != null){
        _id = pago._id
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(updatePago({
            _id,
            fechaLimitePago,
            fechaPago,
            factura,
            isChecked
        }))
        window.location.reload();
    }

  return (
    <Modal
            size='xl'
            show={show}
            centered
            onHide={handleModalClose}
            aria-labelledby="example-modal-sizes-title-xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-xl">
                    Pago - Numero de Factura {pago.numeroFactura}
                </Modal.Title>
            </Modal.Header>   
            <Modal.Body>
                <Container>
                <Card style={{background:"#f5f5f5", width:"100%"}} className='rounded'>
                    <div className='p-3'>
                        <Row className='d-flex align-items-center'>
                            <Col>
                                <h6>Detalles del pago</h6>
                            </Col>
                            <Col sm={2} className='text-center'>
                                <div className='priority-box' style={{backgroundColor:priority[pago.isPaid]}}>{pago.isPaid ? 'PAGADO':'NO PAGADO'}</div>
                            </Col>
                        </Row>
                        <hr/>
                        {!editar ?(
                        <Row>
                            <Col sm={6}>
                                Fecha Límite: {formatearFecha(pago.fechaLimitePago)}
                            </Col>
                            <Col sm={6}>
                                Fecha Pago: {pago.fechaPago !== null ? formatearFecha(pago.fechaPago): '"Sin Registrar"'}
                            </Col>
                            <Col sm={6}>
                                Moneda: {pago.moneda}
                            </Col>
                            <Col sm={6}>
                                Monto: {currency(pago.monto)}
                            </Col>
                            <Col sm={6}>
                                Comisión: {currency(pago.comision)}
                            </Col>
                            <Col sm={6}>
                                Porcentaje de Comisión: {pago.porcentajeComision}%
                            </Col>
                        </Row>
                        ):(
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={4} className='p-3'>
                                    <TextField 
                                        id="factura"
                                        label="Factura" 
                                        variant="outlined"
                                        value={factura}
                                        type='text'
                                        fullWidth 
                                        onChange={(event) => {
                                            setFactura(event.target.value);
                                        }}
                                    />
                                </Col>
                                <Col sm={4} className='p-3'>
                                    <TextField 
                                        id="fechaLimitePago"
                                        label="Fecha Limite Pago" 
                                        variant="outlined"
                                        value={fechaLimitePago}
                                        type='date'
                                        fullWidth 
                                        onChange={(event) => {
                                            setFechaLimitePago(event.target.value);
                                        }}
                                    />
                                </Col>
                                <Col sm={4} className='p-3'>
                                    <TextField 
                                        id="fechaLimitePago"
                                        label="Fecha de Pago" 
                                        variant="outlined"
                                        value={fechaPago}
                                        type='date'
                                        fullWidth 
                                        onChange={(event) => {
                                            setFechaPago(event.target.value);
                                        }}
                                    />
                                </Col>
                                <Col sm={4} className='p-3'>
                                    <FormControlLabel control={<Checkbox checked={isChecked} onChange={() => {setIsChecked(!isChecked)}}/>} label="Pagado" />
                                </Col>
                            </Row>
                            <br/>
                            <Button type='submit' variant='success'>
                                Guardar
                            </Button>
                        </Form>
                        )}
                    </div>
                </Card>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={editar ? 'danger':'info'} onClick={() =>{setEditar(!editar)}}>{editar ? 'Cancelar':'Editar Pago'}</Button>
                <Button variant="light" onClick={handleModalClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
    }

export default ModalPago
