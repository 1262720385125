import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listPolizaDetail, updatePoliza } from '../actions/polizaActions'
import { POLIZA_UPDATE_RESET } from '../constants/polizaConstants'
import { useParams, useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

function PolizaEditScreen() {

    let navigate = useNavigate()
    const match = useParams()

    const [moneda, setMoneda] = useState(null)
    const [numeroPoliza, setNumeroPoliza] = useState('')
    const [ramoSeguro, setRamoSeguro] = useState(null)
    const [claveAgente, setClaveAgente] = useState('')
    const [aseguradora, setAseguradora] = useState(null)
    const [sumaAsegurada, setSumaAsegurada] = useState('')
    const [inicioVigencia, setInicioVigencia] = useState('')
    const [finVigencia, setFinVigencia] = useState('')
    const [primaNeta, setPrimaNeta] = useState('')
    const [primaTotal, setPrimaTotal] = useState('')
    const [observaciones, setObservaciones] = useState('')

    const dispatch = useDispatch()

    const polizaList = useSelector(state => state.polizaDetailList)
    const { error, loading, poliza } = polizaList

    const polizaUpdate = useSelector(state => state.polizaUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = polizaUpdate

    useEffect(() => {

        if (successUpdate) {
            dispatch({ type: POLIZA_UPDATE_RESET })
            navigate(`/polizas/${poliza._id}?from=create`)

        } else {

            if (!poliza.inicioVigencia || poliza._id !== Number(match.id)) {
                dispatch(listPolizaDetail(match))

            } else {
                setMoneda(poliza.moneda)
                setNumeroPoliza(poliza.numeroPoliza)
                setAseguradora(poliza.aseguradora)
                setClaveAgente(poliza.claveAgente)
                setSumaAsegurada(poliza.sumaAsegurada)
                setRamoSeguro(poliza.ramoSeguro)
                setPrimaNeta(poliza.primaNeta)
                setPrimaTotal(poliza.primaTotal)
                setInicioVigencia(poliza.inicioVigencia)
                setFinVigencia(poliza.finVigencia)
                setObservaciones(poliza.observaciones)
            }
        }

        if (aseguradora !== null) {
            if (aseguradora === 'ATLAS') {
                setClaveAgente('2976')
            }
            else if (aseguradora === 'CHUBB') {
                setClaveAgente('141663')
            }
            else if (aseguradora === 'GNP') {
                setClaveAgente('79692')
            }
            else if (aseguradora === 'GMX') {
                setClaveAgente('689')
            }
        }

    }, [dispatch, poliza, match, successUpdate, navigate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updatePoliza({ 
            _id: match.id, 
            moneda,
            numeroPoliza, 
            aseguradora,
            claveAgente,
            sumaAsegurada,
            ramoSeguro,
            primaNeta,
            primaTotal,
            inicioVigencia,
            finVigencia,
            observaciones,
        }))
    }

    const optionSeguro=['TRC', 'RC', 'RCP','Automóviles', 'Equipo Electrónico']
    const optionAseguradora=['ATLAS','CHUBB', 'GNP', 'GMX']
    const optionMoneda=['MXN', 'USD', 'EUR']

    return (
        <div>
            
            <Link to={`/polizas/${poliza._id}?from=create`} className='btn btn-light my-3'>
                Regresar
            </Link>

            <div className='pt-2 p-5'>
                <h1>Editar Seguro </h1>
                <h6>{aseguradora!== null && `En ${aseguradora} Se trabaja con la clave de Agente: ${claveAgente}`}</h6>
                {loadingUpdate && <Loader />}
                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                      <Form onSubmit={submitHandler}>
                        <Row>
                        <Col sm={4} className='p-3'>
                            <TextField 
                                id="numeroSeguro"
                                label="Número Seguro" 
                                variant="outlined"
                                type='text'
                                value={numeroPoliza}
                                fullWidth 
                                onChange={(event) => {
                                    setNumeroPoliza(event.target.value);
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={ramoSeguro}
                                onChange={(event, newValue) => {
                                    setRamoSeguro(newValue);
                                }}
                                disablePortal
                                id="ramoSeguro"
                                options={optionSeguro}
                                renderInput={(params) => <TextField {...params} label="Ramo Seguro" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={aseguradora}
                                onChange={(event, newValue) => {
                                    setAseguradora(newValue);
                                }}
                                disablePortal
                                id="aseguradora"
                                options={optionAseguradora}
                                renderInput={(params) => <TextField {...params} label="Aseguradora" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={moneda}
                                onChange={(event, newValue) => {
                                    setMoneda(newValue);
                                }}
                                disablePortal
                                id="moneda"
                                options={optionMoneda}
                                renderInput={(params) => <TextField {...params} label="Moneda" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="sumaAsegurada"
                                label="Suma Asegurada" 
                                variant="outlined"
                                value={sumaAsegurada}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setSumaAsegurada(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="primaNeta"
                                label="Prima Neta" 
                                variant="outlined"
                                value={primaNeta}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setPrimaNeta(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="primaTotal"
                                label="Prima Total" 
                                variant="outlined"
                                value={primaTotal}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setPrimaTotal(event.target.value);
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="inicioVigencia"
                                label="Inicio Vigencia" 
                                variant="outlined"
                                value={inicioVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setInicioVigencia(event.target.value);
                                }}
                            />
                            </Col> 
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="finVigencia"
                                label="Fin Vigencia" 
                                variant="outlined"
                                value={finVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setFinVigencia(event.target.value);
                                }}
                            />
                            </Col>
                            <br/>
                            <hr/>
                            <TextField
                                id="observaciones"
                                label="Observaciones"
                                multiline
                                rows={4}
                                value={observaciones}
                                onChange={(event) => {
                                    setObservaciones(event.target.value);
                                }}
                            />
                            </Row>
                        <br/>
                        <Button type='submit' variant='success'>
                            Guardar
                        </Button>
                      </Form>
                    )}
            </div>
        </div> 

    )
}

export default PolizaEditScreen
