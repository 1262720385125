export const PAGO_LIST_REQUEST = 'PAGO_LIST_REQUEST'
export const PAGO_LIST_SUCCESS = 'PAGO_LIST_SUCCESS'
export const PAGO_LIST_FAIL = 'PAGO_LIST_FAIL'
export const PAGO_LIST_RESET = 'PAGO_LIST_RESET'

export const PAGO_ENDOSO_LIST_REQUEST = 'PAGO_ENDOSO_LIST_REQUEST'
export const PAGO_ENDOSO_LIST_SUCCESS = 'PAGO_ENDOSO_LIST_SUCCESS'
export const PAGO_ENDOSO_LIST_FAIL = 'PAGO_ENDOSO_LIST_FAIL'
export const PAGO_ENDOSO_LIST_RESET = 'PAGO_ENDOSO_LIST_RESET'

export const PAGO_DETAIL_REQUEST = 'PAGO_DETAIL_REQUEST'
export const PAGO_DETAIL_SUCCESS = 'PAGO_DETAIL_SUCCESS'
export const PAGO_DETAIL_FAIL = 'PAGO_DETAIL_FAIL'

export const PAGO_UPDATE_REQUEST= 'PAGO_UPDATE_REQUEST'
export const PAGO_UPDATE_SUCCESS= 'PAGO_UPDATE_SUCCESS'
export const PAGO_UPDATE_FAIL= 'PAGO_UPDATE_FAIL'
export const PAGO_UPDATE_RESET= 'PAGO_UPDATE_RESET'

export const PAGO_DELETE_REQUEST = 'PAGO_DELETE_REQUEST'
export const PAGO_DELETE_SUCCESS = 'PAGO_DELETE_SUCCESS'
export const PAGO_DELETE_FAIL = 'PAGO_DELETE_FAIL'

export const PAGO_CREATE_REQUEST = 'PAGO_CREATE_REQUEST'
export const PAGO_CREATE_SUCCESS = 'PAGO_CREATE_SUCCESS'
export const PAGO_CREATE_FAIL = 'PAGO_CREATE_FAIL'
export const PAGO_CREATE_RESET = 'PAGO_CREATE_RESET'