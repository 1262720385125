import { 
    POLIZA_LIST_FAIL,
    POLIZA_LIST_REQUEST,
    POLIZA_LIST_SUCCESS,

    POLIZA_LIST_CONTRATO_FAIL,
    POLIZA_LIST_CONTRATO_REQUEST,
    POLIZA_LIST_CONTRATO_SUCCESS,

    POLIZA_DETAIL_REQUEST,
    POLIZA_DETAIL_SUCCESS,
    POLIZA_DETAIL_FAIL,
    POLIZA_DETAIL_RESET,

    POLIZA_UPDATE_REQUEST,
    POLIZA_UPDATE_SUCCESS,
    POLIZA_UPDATE_FAIL,
    POLIZA_UPDATE_RESET,

    POLIZA_CREATE_REQUEST,
    POLIZA_CREATE_SUCCESS,
    POLIZA_CREATE_FAIL,
    POLIZA_CREATE_RESET,

    POLIZA_DELETE_REQUEST,
    POLIZA_DELETE_SUCCESS,
    POLIZA_DELETE_FAIL,
    POLIZA_LIST_CONTRATO_RESET,

} from '../constants/polizaConstants'

export const polizaListReducer = (state = {polizas:[]}, action) =>{
    switch(action.type){
        case POLIZA_LIST_REQUEST:
            return {loading:true, polizas:[]}

        case POLIZA_LIST_SUCCESS:
            return {loading:false, 
                polizas: action.payload.polizas, 
                page: action.payload.page, 
                pages: action.payload.pages
            }

        case POLIZA_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const polizaContratoListReducer = (state = {polizas:[]}, action) =>{
    switch(action.type){
        case POLIZA_LIST_CONTRATO_REQUEST:
            return {loading:true, polizas:[]}

        case POLIZA_LIST_CONTRATO_SUCCESS:
            return {loading:false, 
                polizas: action.payload.polizas, 
            }   

        case POLIZA_LIST_CONTRATO_FAIL:
            return {loading:false, error: action.payload}

        case POLIZA_LIST_CONTRATO_RESET:
            return {polizas:[]}

        default:
            return state
    }
}

export const polizaDetailReducer= (state = {poliza:[]}, action) =>{
    switch(action.type){
        case POLIZA_DETAIL_REQUEST:
            return {loading:true, poliza:[]}

        case POLIZA_DETAIL_SUCCESS:
            return {loading:false, poliza: action.payload}

        case POLIZA_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const polizaCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case POLIZA_CREATE_REQUEST:
            return { loading: true }

        case POLIZA_CREATE_SUCCESS:
            return { loading: false, success: true, poliza: action.payload }

        case POLIZA_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case POLIZA_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const polizaUpdateReducer = (state = { poliza: {} }, action) => {
    switch (action.type) {
        case POLIZA_UPDATE_REQUEST:
            return { loading: true }

        case POLIZA_UPDATE_SUCCESS:
            return { loading: false, success: true, poliza: action.payload }

        case POLIZA_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case POLIZA_UPDATE_RESET:
            return { poliza: {} }

        default:
            return state
    }
}

export const polizaDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case POLIZA_DELETE_REQUEST:
            return { loading: true }

        case POLIZA_DELETE_SUCCESS:
            return { loading: false, success: true }

        case POLIZA_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}