import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { createFianza } from '../actions/fianzaActions'
import { FIANZA_UPDATE_RESET } from '../constants/fianzaConstants'
import { useParams, useNavigate } from 'react-router-dom'
import { listContratos } from '../actions/contratoActions'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material'

function FianzaCreateScreen() {

    let navigate = useNavigate()
    const match = useParams()

    var today = new Date();

    function formatDate(d){
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const [idContrato, setIdContrato] = useState(null)
    const [tipoFianza, setTipoFianza] = useState(null)
    const [numeroFianza, setNumeroFianza] = useState('')
    const [afianzadora, setAfianzadora] = useState(null)
    const [montoAfianzado, setMontoAfianzado] = useState('')
    const [primaNeta, setPrimaNeta] = useState('')
    const [primaTotal, setPrimaTotal] = useState('')
    const [inicioVigencia, setInicioVigencia] = useState(formatDate(today))
    const [beneficiario, setBeneficiario] = useState(null)
    const [observaciones, setObservaciones] = useState('')

    const dispatch = useDispatch() 

    const fianzaList = useSelector(state => state.fianzaDetailList)
    const { error, loading, fianza } = fianzaList

    const fianzaCreate = useSelector(state => state.fianzaCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, fianza: createdFianza } = fianzaCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const contratoList = useSelector(state => state.contratoList)
    const{errorC, loadingC, contratos, page, pages} = contratoList

    useEffect(() => {
        if(userInfo){
            dispatch(listContratos(userInfo.cliente_id))
        }

        if (successCreate) {
            dispatch({ type: FIANZA_UPDATE_RESET })
            navigate(`/fianzas/${createdFianza._id}/pdf`)
        }

    }, [dispatch, match, successCreate, createdFianza, navigate])

    let contratoID = 0
    if (idContrato !== null) {
        contratoID = idContrato._id
    }

    const submitHandler = (e) => {
        e.preventDefault()
        console.log(numeroFianza)
        dispatch(createFianza({
            contratoID,
            tipoFianza,
            numeroFianza, 
            afianzadora,
            montoAfianzado,
            primaNeta,
            primaTotal,
            inicioVigencia,
            beneficiario,
            observaciones,
        }))
    }

    
    const defaultProps = {
        options: contratos,
        getOptionLabel: (option) => option.numeroContrato,
    };

    const optionFianza=['Cumplimiento', 'Anticipo', 'Vicios Ocultos']
    const optionAfianzadora=['CHUBB', 'ATLAS', 'SOFIMEX']
    const optionBeneficiario=['OPCEM','Concesionaria Mexiquense']

    const handleClick = () => {
        window.history.back();
    };
    return (
        <div>
            
            <Button onClick={handleClick} className='btn btn-light my-3'>
                Regresar
            </Button>

            <div className='pt-2 p-5'>
                <h1>Nueva Fianza </h1>
                {loadingCreate && <Loader />}
                {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Row>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                {...defaultProps}
                                value={idContrato}
                                onChange={(event, newValue) => {
                                    setIdContrato(newValue);
                                }}
                                autoComplete
                                disablePortal
                                id="idContrato"
                                options={contratos}
                                getOptionLabel={(option) => option.numeroContrato}
                                renderInput={(params) => <TextField {...params} label="Contrato"/>}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={tipoFianza}
                                onChange={(event, newValue) => {
                                    setTipoFianza(newValue);
                                }}
                                disablePortal
                                id="tipoFianza"
                                options={optionFianza}
                                renderInput={(params) => <TextField {...params} label="Tipo de Fianza" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="numeroFianza"
                                label="Número Fianza" 
                                variant="outlined"
                                type='text'
                                value={numeroFianza}
                                fullWidth 
                                onChange={(event) => {
                                    setNumeroFianza(event.target.value);
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={afianzadora}
                                onChange={(event, newValue) => {
                                    setAfianzadora(newValue);
                                }}
                                disablePortal
                                id="afianzadora"
                                options={optionAfianzadora}
                                renderInput={(params) => <TextField {...params} label="Afianzadora" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="montoAfianzado"
                                label="Monto Afianzado" 
                                variant="outlined"
                                value={montoAfianzado}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setMontoAfianzado(event.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="primaNeta"
                                label="Prima Neta" 
                                variant="outlined"
                                value={primaNeta}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setPrimaNeta(event.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="primaTotal"
                                label="Prima Total" 
                                variant="outlined"
                                value={primaTotal}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setPrimaTotal(event.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </Col>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="inicioVigencia"
                                label="Inicio Vigencia" 
                                variant="outlined"
                                value={inicioVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setInicioVigencia(event.target.value);
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={beneficiario}
                                onChange={(event, newValue) => {
                                    setBeneficiario(newValue);
                                }}
                                disablePortal
                                id="beneficiario"
                                options={optionBeneficiario}
                                renderInput={(params) => <TextField {...params} label="Beneficiario" />}
                            />
                            </Col>
                            <br/>
                            <hr/>
                            <TextField
                                id="observaciones"
                                label="Observaciones"
                                multiline
                                rows={4}
                                value={observaciones}
                                onChange={(event) => {
                                    setObservaciones(event.target.value);
                                }}
                            />
                            <br/>
                            </Row>
                            <br/>
                            <Button type='submit' variant='success'>
                                Guardar
                            </Button>
                        </Form>
                    )}

            </div>
        </div> 

    )
}

export default FianzaCreateScreen