import { 
    PROVEEDOR_LIST_FAIL,
    PROVEEDOR_LIST_REQUEST,
    PROVEEDOR_LIST_SUCCESS,

    PROVEEDOR_DETAIL_FAIL,
    PROVEEDOR_DETAIL_REQUEST,
    PROVEEDOR_DETAIL_SUCCESS,

    PROVEEDOR_UPDATE_REQUEST,
    PROVEEDOR_UPDATE_SUCCESS,
    PROVEEDOR_UPDATE_FAIL,
    PROVEEDOR_UPDATE_RESET,

    PROVEEDOR_CREATE_REQUEST,
    PROVEEDOR_CREATE_SUCCESS,
    PROVEEDOR_CREATE_FAIL,
    PROVEEDOR_CREATE_RESET,

    PROVEEDOR_DELETE_REQUEST,
    PROVEEDOR_DELETE_SUCCESS,
    PROVEEDOR_DELETE_FAIL,

} from '../constants/proveedorConstants'

export const proveedorListReducer = (state = {proveedores:[]}, action) =>{ 
    switch(action.type){
        case PROVEEDOR_LIST_REQUEST:
            return {loading:true, proveedores:[]}

        case PROVEEDOR_LIST_SUCCESS:
            return {loading:false, 
                proveedores: action.payload.proveedores,
                page: action.payload.page, 
                pages: action.payload.pages
            }

        case PROVEEDOR_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const porveedorDetailReducer= (state = {proveedor:[]}, action) =>{
    switch(action.type){
        case PROVEEDOR_DETAIL_REQUEST:
            return {loading:true, proveedor:[]}

        case PROVEEDOR_DETAIL_SUCCESS:
            return {loading:false, proveedor: action.payload}

        case PROVEEDOR_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const proveedorCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PROVEEDOR_CREATE_REQUEST:
            return { loading: true }

        case PROVEEDOR_CREATE_SUCCESS:
            return { loading: false, success: true, proveedor: action.payload }

        case PROVEEDOR_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PROVEEDOR_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const proveedorUpdateReducer = (state = { proveedor: {} }, action) => {
    switch (action.type) {
        case PROVEEDOR_UPDATE_REQUEST:
            return { loading: true }

        case PROVEEDOR_UPDATE_SUCCESS:
            return { loading: false, success: true, proveedor: action.payload }

        case PROVEEDOR_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case PROVEEDOR_UPDATE_RESET:
            return { proveedor: {} }

        default:
            return state
    }
}

export const proveedorDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PROVEEDOR_DELETE_REQUEST:
            return { loading: true }

        case PROVEEDOR_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PROVEEDOR_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}