import React from 'react'

function MissingScreen() {
  return (
    <div id="wrapper">
        <div id="info" className='text-center mt-5'>
            <h3>¡Lo sentimos, Esta página no existe!</h3>
            <img src="https://cdn.dribbble.com/users/1408464/screenshots/6377404/404_illustration_4x.png" width="45%" alt="404"/>
        </div>
    </div >
  )
}

export default MissingScreen