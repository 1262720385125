export const FIANZA_LIST_REQUEST = 'FIANZA_LIST_REQUEST'
export const FIANZA_LIST_SUCCESS = 'FIANZA_LIST_SUCCESS'
export const FIANZA_LIST_FAIL = 'FIANZA_LIST_FAIL'

export const FIANZA_LIST_CONTRATO_REQUEST = 'FIANZA_LIST_CONTRATO_REQUEST'
export const FIANZA_LIST_CONTRATO_SUCCESS = 'FIANZA_LIST_CONTRATO_SUCCESS'
export const FIANZA_LIST_CONTRATO_FAIL = 'FIANZA_LIST_CONTRATO_FAIL'
export const FIANZA_LIST_CONTRATO_RESET = 'FIANZA_LIST_CONTRATO_RESET'

export const FIANZA_DETAIL_REQUEST = 'FIANZA_DETAIL_REQUEST'
export const FIANZA_DETAIL_SUCCESS = 'FIANZA_DETAIL_SUCCESS'
export const FIANZA_DETAIL_FAIL = 'FIANZA_DETAIL_FAIL'

export const FIANZA_VIGENCIA_REQUEST = 'FIANZA_VIGENCIA_REQUEST'
export const FIANZA_VIGENCIA_SUCCESS = 'FIANZA_VIGENCIA_SUCCESS'
export const FIANZA_VIGENCIA_FAIL = 'FIANZA_VIGENCIA_FAIL'

export const FIANZA_TOP_VENCIDAS_REQUEST = 'FIANZA_TOP_VENCIDAS_REQUEST'
export const FIANZA_TOP_VENCIDAS_SUCCESS = 'FIANZA_TOP_VENCIDAS_SUCCESS'
export const FIANZA_TOP_VENCIDAS_FAIL = 'FIANZA_TOP_VENCIDAS_FAIL'

export const FIANZA_UPDATE_REQUEST= 'FIANZA_UPDATE_REQUEST'
export const FIANZA_UPDATE_SUCCESS= 'FIANZA_UPDATE_SUCCESS'
export const FIANZA_UPDATE_FAIL= 'FIANZA_UPDATE_FAIL'
export const FIANZA_UPDATE_RESET= 'FIANZA_UPDATE_RESET'

export const FIANZA_DELETE_REQUEST = 'FIANZA_DELETE_REQUEST'
export const FIANZA_DELETE_SUCCESS = 'FIANZA_DELETE_SUCCESS'
export const FIANZA_DELETE_FAIL = 'FIANZA_DELETE_FAIL'

export const FIANZA_CREATE_REQUEST = 'FIANZA_CREATE_REQUEST'
export const FIANZA_CREATE_SUCCESS = 'FIANZA_CREATE_SUCCESS'
export const FIANZA_CREATE_FAIL = 'FIANZA_CREATE_FAIL'
export const FIANZA_CREATE_RESET = 'FIANZA_CREATE_RESET' 
