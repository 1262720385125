import { 
    PAGO_LIST_FAIL,
    PAGO_LIST_REQUEST,
    PAGO_LIST_SUCCESS,
    PAGO_LIST_RESET,

    PAGO_ENDOSO_LIST_FAIL,
    PAGO_ENDOSO_LIST_REQUEST,
    PAGO_ENDOSO_LIST_SUCCESS,
    PAGO_ENDOSO_LIST_RESET,

    PAGO_DETAIL_REQUEST,
    PAGO_DETAIL_SUCCESS,
    PAGO_DETAIL_FAIL,

    PAGO_UPDATE_REQUEST,
    PAGO_UPDATE_SUCCESS,
    PAGO_UPDATE_FAIL,
    PAGO_UPDATE_RESET,

    PAGO_CREATE_REQUEST,
    PAGO_CREATE_SUCCESS,
    PAGO_CREATE_FAIL,
    PAGO_CREATE_RESET,

    PAGO_DELETE_REQUEST,
    PAGO_DELETE_SUCCESS,
    PAGO_DELETE_FAIL,

} from '../constants/pagoConstants'

export const pagoListReducer = (state = {pagos:[]}, action) =>{
    switch(action.type){
        case PAGO_LIST_REQUEST:
            return {loading:true, ...state}

        case PAGO_LIST_SUCCESS:
            return {loading:false, 
                pagos: action.payload,
            }

        case PAGO_LIST_FAIL:
            return {loading:false, error: action.payload}

        case PAGO_LIST_RESET:
            return {pagos:[]}

        default:
            return state
    }
}

export const pagoEndosoListReducer = (state = {pagos:[]}, action) =>{
    switch(action.type){
        case PAGO_ENDOSO_LIST_REQUEST:
            return {loading:true, ...state}

        case PAGO_ENDOSO_LIST_SUCCESS:
            return {loading:false, 
                pagos: action.payload,
            }

        case PAGO_ENDOSO_LIST_FAIL:
            return {loading:false, error: action.payload}

        case PAGO_ENDOSO_LIST_RESET:
            return {pagos:[]}
            
        default:
            return state
    }
}

export const pagoDetailReducer= (state = {pago:[]}, action) =>{
    switch(action.type){
        case PAGO_DETAIL_REQUEST:
            return {loading:true, pago:[]}

        case PAGO_DETAIL_SUCCESS:
            return {loading:false, pago: action.payload}

        case PAGO_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const pagoCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PAGO_CREATE_REQUEST:
            return { loading: true }

        case PAGO_CREATE_SUCCESS:
            return { loading: false, success: true, pago: action.payload }

        case PAGO_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PAGO_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const pagoUpdateReducer = (state = { pago: {} }, action) => {
    switch (action.type) {
        case PAGO_UPDATE_REQUEST:
            return { loading: true }

        case PAGO_UPDATE_SUCCESS:
            return { loading: false, success: true, pago: action.payload }

        case PAGO_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case PAGO_UPDATE_RESET:
            return { pago: {} }

        default:
            return state
    }
}

export const pagoDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PAGO_DELETE_REQUEST:
            return { loading: true }

        case PAGO_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PAGO_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}