export const CONTRATO_LIST_REQUEST = 'CONTRATO_LIST_REQUEST'
export const CONTRATO_LIST_SUCCESS = 'CONTRATO_LIST_SUCCESS'
export const CONTRATO_LIST_FAIL = 'CONTRATO_LIST_FAIL' 

export const CONTRATO_LIST_PROVEEDOR_REQUEST = 'CONTRATO_LIST_PROVEEDOR_REQUEST'
export const CONTRATO_LIST_PROVEEDOR_SUCCESS = 'CONTRATO_LIST_PROVEEDOR_SUCCESS'
export const CONTRATO_LIST_PROVEEDOR_FAIL = 'CONTRATO_LIST_PROVEEDOR_FAIL' 

export const CONTRATO_DETAIL_REQUEST = 'CONTRATO_DETAIL_REQUEST'
export const CONTRATO_DETAIL_SUCCESS = 'CONTRATO_DETAIL_SUCCESS'
export const CONTRATO_DETAIL_FAIL = 'CONTRATO_DETAIL_FAIL'

export const CONTRATO_UPDATE_REQUEST= 'CONTRATO_UPDATE_REQUEST'
export const CONTRATO_UPDATE_SUCCESS= 'CONTRATO_UPDATE_SUCCESS'
export const CONTRATO_UPDATE_FAIL= 'CONTRATO_UPDATE_FAIL'
export const CONTRATO_UPDATE_RESET= 'CONTRATO_UPDATE_RESET'

export const CONTRATO_DELETE_REQUEST = 'CONTRATO_DELETE_REQUEST'
export const CONTRATO_DELETE_SUCCESS = 'CONTRATO_DELETE_SUCCESS'
export const CONTRATO_DELETE_FAIL = 'CONTRATO_DELETE_FAIL'

export const CONTRATO_CREATE_REQUEST = 'CONTRATO_CREATE_REQUEST'
export const CONTRATO_CREATE_SUCCESS = 'CONTRATO_CREATE_SUCCESS'
export const CONTRATO_CREATE_FAIL = 'CONTRATO_CREATE_FAIL'
export const CONTRATO_CREATE_RESET = 'CONTRATO_CREATE_RESET'