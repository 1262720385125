import React, {useState} from 'react'
import { Container, Card, Modal, Row, Col, Button, Form } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { updateIncidencia } from '../actions/incidenciaActions'
import { useDispatch } from 'react-redux';

import { formatearFecha } from '../utils/utils'

function ModalInfo({incidencia=null, show, handleClose}) {

    const dispatch = useDispatch()

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()

    const [edit, setEdit] = useState(false)
    const [prioridad, setPrioridad] = useState(null)
    const [status, setStatus] = useState(null)
    const [fechaFin, setFechaFin] = useState(date)

    const optionPrioridad=['BAJA', 'MEDIA', 'ALTA']
    const otpionStatus=['Atrasado', 'Pendiente', 'Concluido']

    const handleModalClose = () => {
        // Llamar a la función handleClose para actualizar el estado de "show"
        handleClose();
    }

    const handleEdit = () => {
        setEdit(!edit)
        setPrioridad(incidencia.prioridad)
        setStatus(incidencia.status)
    }
    let _id = 0
    if (incidencia != null){
        _id = incidencia._id
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(updateIncidencia({
            _id,
            prioridad,
            status,
            fechaFin
        }))
        window.location.reload();
    }

  return (
    <Modal
            size='lg'
            show={show}
            centered
            onHide={handleModalClose}
            aria-labelledby="example-modal-sizes-title-xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-xl">
                    Información de Incidencia: {incidencia ? incidencia.concepto == 'Incumplimiento' ? incidencia.fianza : incidencia.seguro: ''}
                </Modal.Title>
            </Modal.Header>   
            <Modal.Body>
                <Container>
                <Card style={{background:"#f5f5f5", width:"100%"}} className='rounded'>
                    <div className='p-3'>
                        {incidencia && (
                            <div>
                            <h6>Datos Generales</h6>
                            <hr/>
                            Incidencia de {incidencia.concepto}
                            <br/><br/>
                            {!edit ? (
                            <div>
                            <Row>
                                <Col sm={4}>
                                {incidencia && incidencia.concepto == 'Incumplimiento' ? 'Afianzadora:' : 'Aseguradora:'} {incidencia.aseguradora}
                                </Col>
                                <Col sm={4}>
                                Prioridad: {incidencia.prioridad}
                                </Col>
                                <Col sm={4}>
                                Status: {incidencia.status}
                                </Col>
                                <Col sm={4}>
                                Fecha Inicio: {formatearFecha(incidencia.fechaInicio)}
                                </Col>
                                <Col sm={4}>
                                Fecha Fin: {incidencia.fechaFin === null ? '"Sin Registrar"': formatearFecha(incidencia.fechaFin)}
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col sm={12}> Descripción: {incidencia.descripcion === '' ? 'N/A': incidencia.descripcion}</Col>
                            </Row>
                            </div>
                            ):(
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col sm={4} className='p-3'>
                                            <Autocomplete
                                                value={status}
                                                onChange={(event, newValue) => {
                                                    setStatus(newValue);
                                                }}
                                                disablePortal
                                                id="status"
                                                options={otpionStatus}
                                                renderInput={(params) => <TextField {...params} label="Status" />}
                                            />
                                        </Col>
                                        <Col sm={4} className='p-3'>
                                            <Autocomplete
                                                value={prioridad}
                                                onChange={(event, newValue) => {
                                                    setPrioridad(newValue);
                                                }}
                                                disablePortal
                                                id="prioridad"
                                                options={optionPrioridad}
                                                renderInput={(params) => <TextField {...params} label="Prioridad" />}
                                            />
                                        </Col>
                                        <Col sm={4} className='p-3'>
                                            <TextField 
                                                id="fechaFin"
                                                label="Fecha Fin" 
                                                variant="outlined"
                                                value={fechaFin}
                                                type='date'
                                                fullWidth 
                                                onChange={(event) => {
                                                    setFechaFin(event.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Button type='submit' variant='success'>
                                        Guardar
                                    </Button>
                                </Form>
                            )}
                            </div>
                        )}
                    </div>
                </Card>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={edit ? 'danger':'info'} onClick={() =>{setEdit(!edit)}}>{edit ? 'Cancelar':'Editar Incidencia'}</Button>
                <Button variant="light" onClick={handleModalClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
  )
}

export default ModalInfo