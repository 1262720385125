import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listPolizaDetail } from '../actions/polizaActions'
import { createEndoso } from '../actions/endosoActions'
import { POLIZA_UPDATE_RESET } from '../constants/polizaConstants'
import { useParams, useNavigate } from 'react-router-dom'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { listFianzaDetail } from '../actions/fianzaActions'

function EndosoCreateScreen() {

    let navigate = useNavigate()
    const match = useParams()
    const keyword = useLocation().search

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate(),
    date2 = (today.getFullYear()+ 1) + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()

    const [moneda, setMoneda] = useState(null)
    const [numeroEndoso, setNumeroEndoso] = useState('')
    const [tipoEndoso, setTipoEndoso] = useState(null)
    const [aseguradora, setAseguradora] = useState(null)
    const [sumaAsegurada, setSumaAsegurada] = useState('')
    const [inicioVigencia, setInicioVigencia] = useState(date)
    const [finVigencia, setFinVigencia] = useState(date2)
    const [primaNeta, setPrimaNeta] = useState('')
    const [primaTotal, setPrimaTotal] = useState('')
    const [observaciones, setObservaciones] = useState('')

    const dispatch = useDispatch()

    const endosoCreate = useSelector(state => state.endosoCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, endoso: createdEndoso } = endosoCreate

    const fianzaList = useSelector(state => state.fianzaDetailList)
    const{error, loading, fianza} = fianzaList

    const polizaList = useSelector(state => state.polizaDetailList)
    const{poliza} = polizaList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const concepto = keyword.split('concepto=')[1]

    useEffect(()=>{
        if(concepto === 'seguro'){ 
            dispatch(listPolizaDetail(userInfo.cliente_id, match))
        }else if (concepto === 'fianza'){
            dispatch(listFianzaDetail(userInfo.cliente_id, match))
        }
        if(successCreate){
            if(concepto === 'seguro'){
                navigate(`/polizas/${poliza._id}/`)
            }else if (concepto === 'fianza'){
                navigate(`/fianzas/${fianza._id}/`)
            }
        }
      }, [dispatch, match, userInfo, successCreate])

    const handleClick = () => {
        window.history.back();
    };

    const idCliente = userInfo.cliente_id
    const idConcepto = match.id
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createEndoso({
            idCliente,
            idConcepto,
            tipoEndoso,
            numeroEndoso,
            concepto,
            aseguradora,
            inicioVigencia,
            finVigencia,
            moneda,
            sumaAsegurada,
            primaNeta,
            primaTotal,
            observaciones
        }))
    }

    const optionAseguradora=['ATLAS', 'CHUBB',"GNP","GMX"]
    const optionAfianzadora=['ATLAS',"CHUBB","SOFIMEX"]
    const optionEndoso=['A-Adición', 'D-Devolución']
    const optionMoneda=['MXN','USD','EURO']
  return (
    <div>
        <Button onClick={handleClick} className='btn btn-light my-3'>
                Regresar
        </Button>
        <div className='pt-2 p-5'>
                <h1>Nuevo Endoso </h1>
                <h6>Endoso para {concepto} {concepto==='seguro' ? poliza.numeroPoliza : fianza.numeroFianza}</h6>
                <Form onSubmit={submitHandler}>
                    <Row>
                    
                    <Col sm={4} className='p-3'>
                    <TextField 
                        id="numeroEndoso"
                        label="Número Endoso" 
                        variant="outlined"
                        type='text'
                        value={numeroEndoso}
                        fullWidth 
                        onChange={(event) => {
                            setNumeroEndoso(event.target.value);
                        }}
                    />
                    </Col>
                    <br/>
                    <Col sm={4} className='p-3'>
                    <Autocomplete
                        value={tipoEndoso}
                        onChange={(event, newValue) => {
                            setTipoEndoso(newValue);
                        }}
                        disablePortal
                        id="tipoEndoso"
                        options={optionEndoso}
                        renderInput={(params) => <TextField {...params} label="Tipo Endoso" />}
                    />
                    </Col>
                    <br/>
                    <Col sm={4} className='p-3'>
                    <Autocomplete
                        value={aseguradora}
                        onChange={(event, newValue) => {
                            setAseguradora(newValue);
                        }}
                        disablePortal
                        id="aseguradora"
                        options={concepto === 'seguro' ? optionAseguradora:optionAfianzadora}
                        renderInput={(params) => <TextField {...params} label={concepto==='seguro'? "Aseguradora":"Afianzadora"} />}
                    />
                    </Col>
                    <br/>
                    <Col sm={4} className='p-3'>
                    <Autocomplete
                        value={moneda}
                        onChange={(event, newValue) => {
                            setMoneda(newValue);
                        }}
                        disablePortal
                        id="moneda"
                        options={optionMoneda}
                        renderInput={(params) => <TextField {...params} label="Moneda" />}
                    />
                    </Col>
                    <br/>
                    <Col sm={4} className='p-3'>
                    <TextField 
                        id="sumaAsegurada"
                        label={concepto==='seguro'? "Suma Asegurada":"Monto Afianzado"} 
                        variant="outlined"
                        value={sumaAsegurada}
                        type='number'
                        fullWidth 
                        onChange={(event) => {
                            setSumaAsegurada(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                        }}
                    />
                    </Col>
                    <br/>
                    <Col sm={4} className='p-3'>
                    <TextField 
                        id="primaNeta"
                        label="Prima Neta" 
                        variant="outlined"
                        value={primaNeta}
                        type='number'
                        fullWidth 
                        onChange={(event) => {
                            setPrimaNeta(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                        }}
                    />
                    </Col>
                    <br/>
                    <Col sm={4} className='p-3'>
                    <TextField 
                        id="primaTotal"
                        label="Prima Total" 
                        variant="outlined"
                        value={primaTotal}
                        type='number'
                        fullWidth 
                        onChange={(event) => {
                            setPrimaTotal(event.target.value);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                        }}
                    />
                    </Col>
                    <br/>
                    <Col sm={4} className='p-3'>
                    <TextField 
                        id="inicioVigencia"
                        label="Inicio Vigencia" 
                        variant="outlined"
                        value={inicioVigencia}
                        type='date'
                        fullWidth 
                        onChange={(event) => {
                            setInicioVigencia(event.target.value);
                        }}
                    />
                    </Col> 
                    <br/>
                    <Col sm={4} className='p-3'>
                    <TextField 
                        id="finVigencia"
                        label="Fin Vigencia" 
                        variant="outlined"
                        value={finVigencia}
                        type='date'
                        fullWidth 
                        onChange={(event) => {
                            setFinVigencia(event.target.value);
                        }}
                    />
                    </Col>
                    <br/>
                    <hr/>
                    <TextField
                        id="observaciones"
                        label="Observaciones"
                        multiline
                        rows={4}
                        value={observaciones}
                        onChange={(event) => {
                            setObservaciones(event.target.value);
                        }}
                    />
                    </Row>
                    <br/>
                    <Button type='submit' variant='success'>
                        Guardar
                    </Button>
                </Form>
        </div>
    </div>
  )
}

export default EndosoCreateScreen