import React, {useEffect} from 'react'
import { Row, Col, Button, Form, Card } from 'react-bootstrap'
import './index.css'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { listProveedores, } from '../actions/proveedoresActions'

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

function DocumentosScreen() {
    const match = useParams()
    const dispatch = useDispatch()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [keyword, setKeyword] = React.useState('')

    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin    

    const proveedorList = useSelector(state => state.proveedorList)
    const{error, loading, proveedores, page, pages} = proveedorList

    let clave = 0
    if(userInfo){
        clave = userInfo.cliente_id
    }

    useEffect(()=>{
        dispatch(listProveedores(clave, '?doc=1 &keyword='+keyword))
      
    }, [dispatch, match, clave, keyword, userInfo])

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = () => {
        window.history.back();
    };

    const submitHandler = (e) => {
        e.preventDefault()
    }
  return (
    <div>
        <Row className="d-flex align-items-center vh-10" >
            <Col sm="2">
                <Button className='btn btn-light my-3' onClick={handleClick}>Regresar</Button>
            </Col>
            <Col sm="8">
                <h4>Documentos</h4>
            </Col>
            <Col className='text-end pe-4'>
                <Button className='btn btn-info btn-sm'>Agregar Archivo</Button>
            </Col>
            <hr/>
        </Row>
        <Row className='d-flex p-3'>
            <Col sm={3} className='p-2'>
                <div className='folder-card'>
                    <div className='pt-3 ps-3'>
                        <h6>Carpeta</h6>
                    </div>
                    <hr/>
                    <div className='pt-1 p-3'>
                        <div className='icon-card'>
                            <Row>
                                <Col sm={3} className='text-center'>
                                    <i className='fas fa-folder-open'/>
                                </Col>
                                <Col sm={9}>
                                    <span>CEM</span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={9} className='p-2'>
                <Row>
                    <Col>
                        <div className='files-card'>
                            <div className='pt-3 ps-3'>
                                <h6>Archivos</h6>
                            </div>
                            <hr/>
                            <div className='pt-1 pb-1 p-3'>
                                <Form onSubmit={submitHandler}>
                                    <TextField 
                                        style={{backgroundColor:'#fafafa'}}
                                        id="sumaAsegurada"
                                        label="Busqueda" 
                                        variant='outlined'
                                        size="small"
                                        value={keyword}
                                        type='text'
                                        fullWidth 
                                        onChange={(event) => {
                                            setKeyword(event.target.value);
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><i className='fas fa-search'/></InputAdornment>,
                                        }}
                                    />
                                </Form>
                            </div>
                            <div className='p-3' style={{maxHeight:'65%', maxWidth:'100%', overflowY:'auto'}}>
                                {proveedores && proveedores.map(proveedor =>(
                                    <Link to={`/documentos/${clave}/${proveedor.id}`} style={{textDecoration:'None'}}>
                                    <Card className='shadow-doc p-3 mt-1'>
                                        <Row>
                                            <Col sm={1} lg={1} className='text-start'>
                                                <i className='fas fa-folder-open' style={{fontSize:'25px', color:'#0065CC'}}/>
                                            </Col>
                                            <Col sm={9} lg={9}>
                                                <h5 className='m-1'>{proveedor.nombreProveedor}</h5>
                                            </Col>
                                            <Col sm={2} lg={2} className='text-end pe-4'>
                                                <i className='fas fa-ellipsis' style={{fontSize:'25px'}} onClick={handleMenu}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handleClose}><i className='fas fa-download me-2'/> Descargar</MenuItem>
            <MenuItem onClick={handleClose}><i className='fas fa-trash me-2'/>Eliminar</MenuItem>
        </Menu>
    </div>
  )
}

export default DocumentosScreen