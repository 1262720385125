import React, {useState, useEffect} from 'react'
import { Card, Modal, Row, Col, Table, Container, Button } from 'react-bootstrap'
import { deletePago, listPagosEndoso } from '../actions/pagoActions'
import { useDispatch, useSelector } from 'react-redux';
import ModalPago from './ModalPago'

import { formatearFecha, currency } from '../utils/utils'
import { useNavigate } from 'react-router-dom';

function ModalEndoso({ endoso, show, handleClose}) {

        let navigate = useNavigate()

        const handleModalClose = () => {
            // Llamar a la función handleClose para actualizar el estado de "show"
            handleClose();
        }

        const createPagoHandler = () => {
            navigate(`/pagos/${endoso._id}/create?concepto=endoso`)
        };

        const [showPago, setShowPago] = useState(false)

        const handleClosePago = () => {
            setShowPago(false);
        } 
        
        const [pago, setPago] = useState(null)
        const handleShowPago = (pago) => {
            setShowPago(true);
            setPago(pago)
        }

        const deleteHandler = (id) => {
   
            if (window.confirm('¿Estás seguro de eliminar este pago?')) {
                dispatch(deletePago(id))
            }
        }

        const dispatch = useDispatch()
        const pagoEndosoList = useSelector(state => state.pagoEndosoList)
        const {pagos} = pagoEndosoList

        useEffect(()=>{
            dispatch(listPagosEndoso(endoso._id,'endoso'))
        }, [dispatch, pagos.length, endoso._id])

    return (
        <Modal
            size='xl'
            show={show}
            centered
            onHide={handleModalClose}
            aria-labelledby="example-modal-sizes-title-xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-xl">
                    Endoso: {endoso.numeroEndoso}
                </Modal.Title>
            </Modal.Header>   
            <Modal.Body>
                <Container>
                <Card style={{background:"#f5f5f5", width:"100%"}} className='rounded'>
                    <div className='p-3'>
                        <h6>Detalles del Endoso</h6><hr/>
                        <Row>
                            <Col sm={6}>
                                Tipo de Endoso: {endoso.tipoEndoso}
                            </Col>
                            <Col sm={6}>
                                {endoso.concepto==='seguro' ? 'Aseguradora' : 'Afianzadora'}: {endoso.aseguradora}
                            </Col>
                            <Col sm={6}>
                                Moneda: {endoso.moneda}
                            </Col>
                            <Col sm={6}>
                                {endoso.concepto==='seguro' ? 'Suma Asegurada' : 'Monto Afianzado'}: {currency(endoso.sumaAsegurada)}
                            </Col>
                            <Col sm={6}>
                                Inicio Vigencia: {formatearFecha(endoso.inicioVigencia)}
                            </Col>
                            <Col sm={6}>
                                Fin Vigencia: {formatearFecha(endoso.finVigencia)}
                            </Col>
                            <Col sm={6}>
                                Prima Neta: {currency(endoso.primaNeta)}
                            </Col>
                            <Col sm={6}>
                                Prima Total: {currency(endoso.primaTotal)}
                            </Col>
                        </Row>
                    </div>
                </Card>
                <div className='p-3'>
                    <h6>Pagos</h6><hr/>
                    <Table className="fixed-header-table align-items-center">
                        <thead>
                            <tr className="text-center">
                                <th>Factura</th>
                                <th>Fecha Límite</th>
                                <th>Monto</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {pagos.map(pago =>(
                                <tr>
                                    <td>{pago.numeroFactura}</td>
                                    <td>{formatearFecha(pago.fechaLimitePago)}</td>
                                    <td>{currency(pago.monto)}</td>
                                    <td>{pago.isPaid ? 'PAGADO':'NO PAGADO'}</td>
                                    <td>
                                        <i className='fas fa-eye' onClick={() =>{ handleShowPago(pago)}}/>
                                        <span style={{color:'white'}}>{' - '}</span>
                                        <i className='fas fa-trash' style={{color:'#D9534F'}} onClick={() =>{ deleteHandler(pago._id)}}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                {pago != null &&<ModalPago pago={pago} show={showPago} handleClose={handleClosePago}/>}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={createPagoHandler}>Nuevo Pago</Button>
                <Button variant="light" onClick={handleModalClose}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
    }

export default ModalEndoso
