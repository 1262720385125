import React from 'react'
import Message from '../components/Message'
import { Col, Row } from 'react-bootstrap'

function RegistroScreen() {
  return (
    <div className='pt-4'>
        <h4>Registro</h4>
        <hr/>
        <div>
            <Message variant='success'>
                <Row className='d-flex align-items-center' style={{height:'100%'}}>
                    <Col sm='1' className='text-center'>
                        <i style={{fontSize:"35px"}} className='fas fa-building-columns'/>
                    </Col>
                    <Col sm='8'>
                        <Row>
                            <Col>
                                <span>Se ha dado de alta: Nueva Fianza</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Responsable: Eduardo Urrutia</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='text-end'>
                        <span>Hace 3 horas</span>
                    </Col>
                </Row>
            </Message>
            <Message variant='danger'>
                <Row className='d-flex align-items-center' style={{height:'100%'}}>
                    <Col sm='1' className='text-center'>
                        <i style={{fontSize:"35px"}} className='fas fa-building-columns'/>
                    </Col>
                    <Col sm='8'>
                        <Row>
                            <Col>
                                <span>Se ha eliminado: Fianza SD-040443000</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Responsable: Eduardo Urrutia</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='text-end'>
                        <span>Hace 3 horas</span>
                    </Col>
                </Row>
            </Message>
            <Message variant='warning'>
                <Row className='d-flex align-items-center' style={{height:'100%'}}>
                    <Col sm='1' className='text-center'>
                        <i style={{fontSize:"35px"}} className='fas fa-building-columns'/>
                    </Col>
                    <Col sm='8'>
                        <Row>
                            <Col>
                                <span>Se ha modificado: Fianza SD-203043000</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Responsable: Eduardo Urrutia</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='text-end'>
                        <span>Hace 3 horas</span>
                    </Col>
                </Row>
            </Message>
        </div>
    </div>
  )
}

export default RegistroScreen