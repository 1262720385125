import React from 'react'
import { Table} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { formatearFecha } from '../utils/utils'

function ContratoProveedor({contratos}) {

  let navigate = useNavigate()
  const dispatch = useDispatch()

  const deleteHandler = (id) => {
 
    if (window.confirm('¿Estás seguro de eliminar este contrato?')) {
        /*dispatch(deleteContrato(id))*/
    }
  }

  const handleClick = (contrato) => {
    navigate(`/contratos/${contrato._id}`)
  }
  return (
    <div>
      <Table bordered className='table-sm text-center fixed-header-table'>
          <thead>
            <tr>
              <th>Número</th>
              <th>Aseguradora</th>
              <th>Inicio Vigencia</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {contratos.length >0 ? contratos.map(contrato =>(
              <tr key={contrato._id}>
                <td>{contrato.numeroContrato}</td>
                <td>{contrato.sociedad}</td>
                <td>{formatearFecha(contrato.inicioVigencia)}</td>
                <td>
                  <i className='fas fa-eye' onClick={() =>{ handleClick(contrato)}}/>
                  <span style={{color:'white'}}>{' - '}</span>
                  <i className='fas fa-trash' style={{color:'#D9534F'}} onClick={() =>{ deleteHandler(contrato._id)}}/>
                </td>
              </tr>
            )):
            <tr></tr>}
          </tbody>
      </Table>
    </div>
    
  )
}

export default ContratoProveedor