import React, {useState, useEffect} from 'react'
import { Row, Col, Form, Button, Table} from 'react-bootstrap'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SearchBox from '../components/SearchBox';
import ModalInfo from '../components/ModalInfo';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { listIncidencias } from '../actions/incidenciaActions';

import { formatearFecha } from '../utils/utils'

function IncidenciasScreen() {
    const [info, setInfo] = useState(null)
    const [tipo, setTipo] = useState('Incumplimiento')
    const [show, setShow] = useState(false);

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const incidenciaList = useSelector(state => state.incidenciaList)
    const{error, loading, incidencias} = incidenciaList

    const [priority, setPriority] = useState({
      ALTA: '#F45C5C',
      BAJA: '#69CB3C',
      MEDIA: '#FFD700',
    })

    const [status, setStatus] = useState({
      Atrasado: '#F45C5C',
      Concluido: '#69CB3C',
      Pendiente: '#FFD700',
    })
    const keyword = useLocation().search

    useEffect(()=>{
      dispatch(listIncidencias(userInfo.cliente_id,tipo,keyword))
    
    }, [dispatch,userInfo.cliente_id, keyword, tipo])
  
    const handleInfo = (incidencia) => {
      setShow(true);
      setInfo(incidencia)
    }

    let navigate = useNavigate()
    const createIncidenciaHandler = () => {
      navigate(`/incidencias/create`)
    }

    const optionIncidencia = ['Incumplimiento', 'Siniestro']

    const handleClose = () => {
      setShow(false);
    } 

  return (
    <div className='pt-4'>
        <Row className='align-items-center'>
          <Col sm={2}>
            <h4>Incidencias</h4>
          </Col>
          <Col sm={4}>
            <SearchBox/>
          </Col>
          <Col sm={4}>
                <Autocomplete
                    style={{width:'100%'}}
                    value={tipo}
                    onChange={(event, newValue) => {
                        setTipo(newValue);
                    }}
                    disablePortal
                    id="tipo"
                    options={optionIncidencia}
                    renderInput={(params) => <TextField {...params} label="Tipo Incidencia" />}
                />
          </Col>
          <Col>
            <Button className='btn btn-light my-3' style={{background:'#eeeeee'}}><i className="fas fa-download"></i> Exportar</Button>
          </Col>
        </Row>
        <hr/>
        {loading ? <Loader/>:
        <Table bordered hover responsive className='table-sm text-center justify-content-center'>
            <thead>
                  <tr>
                      <th>{tipo == 'Incumplimiento' ? 'N° Fianza' : tipo == 'Siniestro' && 'N° Seguro'}</th>
                      <th>{tipo == 'Incumplimiento' ? 'Afianzadora' : tipo == 'Siniestro' && 'Aseguradora'} </th>
                      <th>PRIORIDAD</th>
                      <th>FECHA</th>
                      <th>Status</th>
                      <th><i className='fas fa-plus'style={{color:'#003a70'}}  onClick={() => createIncidenciaHandler()}/></th>
                  </tr>
              </thead>
              <tbody>
                  {incidencias && incidencias.map(incidencia =>(
                    <tr key={incidencia._id}>
                        <td> {tipo === 'Incumplimiento' ? incidencia.fianza:incidencia.seguro}</td>
                        <td> {incidencia.aseguradora} </td>
                        <td><div className='priority-box' style={{backgroundColor:`${priority[incidencia.prioridad]}`}}>{incidencia.prioridad}</div></td>
                        <td> {formatearFecha(incidencia.fechaInicio)} </td>
                        <td><i className='fas fa-circle' style={{color:`${status[incidencia.status]}`}}/> {incidencia.status}</td>
                        <td onClick={() => handleInfo(incidencia)}><i className='fas fa-info-circle' style={{color:'#0198E1'}} /></td>
                    </tr>
                  ))}
              </tbody>
        </Table>
        }
        {tipo === null && (
          <Message variant='info'>Selecciona un tipo de Incidencia</Message>
        )}
        <ModalInfo incidencia={info} show={show} handleClose={handleClose}/>
    </div>
  )
}

export default IncidenciasScreen