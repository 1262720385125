export const POLIZA_LIST_REQUEST = 'POLIZA_LIST_REQUEST'
export const POLIZA_LIST_SUCCESS = 'POLIZA_LIST_SUCCESS'
export const POLIZA_LIST_FAIL = 'POLIZA_LIST_FAIL'

export const POLIZA_LIST_CONTRATO_REQUEST = 'POLIZA_LIST_CONTRATO_REQUEST'
export const POLIZA_LIST_CONTRATO_SUCCESS = 'POLIZA_LIST_CONTRATO_SUCCESS'
export const POLIZA_LIST_CONTRATO_FAIL = 'POLIZA_LIST_CONTRATO_FAIL'
export const POLIZA_LIST_CONTRATO_RESET = 'POLIZA_LIST_CONTRATO_RESET'

export const POLIZA_DETAIL_REQUEST = 'POLIZA_DETAIL_REQUEST'
export const POLIZA_DETAIL_SUCCESS = 'POLIZA_DETAIL_SUCCESS'
export const POLIZA_DETAIL_FAIL = 'POLIZA_DETAIL_FAIL'
export const POLIZA_DETAIL_RESET = 'POLIZA_DETAIL_RESET'

export const POLIZA_UPDATE_REQUEST= 'POLIZA_UPDATE_REQUEST'
export const POLIZA_UPDATE_SUCCESS= 'POLIZA_UPDATE_SUCCESS'
export const POLIZA_UPDATE_FAIL= 'POLIZA_UPDATE_FAIL'
export const POLIZA_UPDATE_RESET= 'POLIZA_UPDATE_RESET'

export const POLIZA_DELETE_REQUEST = 'POLIZA_DELETE_REQUEST'
export const POLIZA_DELETE_SUCCESS = 'POLIZA_DELETE_SUCCESS'
export const POLIZA_DELETE_FAIL = 'POLIZA_DELETE_FAIL'

export const POLIZA_CREATE_REQUEST = 'POLIZA_CREATE_REQUEST'
export const POLIZA_CREATE_SUCCESS = 'POLIZA_CREATE_SUCCESS'
export const POLIZA_CREATE_FAIL = 'POLIZA_CREATE_FAIL'
export const POLIZA_CREATE_RESET = 'POLIZA_CREATE_RESET'