export const PROVEEDOR_LIST_REQUEST = 'PROVEEDOR_LIST_REQUEST'
export const PROVEEDOR_LIST_SUCCESS = 'PROVEEDOR_LIST_SUCCESS'
export const PROVEEDOR_LIST_FAIL = 'PROVEEDOR_LIST_FAIL' 

export const PROVEEDOR_DETAIL_REQUEST = 'PROVEEDOR_DETAIL_REQUEST'
export const PROVEEDOR_DETAIL_SUCCESS = 'PROVEEDOR_DETAIL_SUCCESS'
export const PROVEEDOR_DETAIL_FAIL = 'PROVEEDOR_DETAIL_FAIL' 

export const PROVEEDOR_UPDATE_REQUEST= 'PROVEEDOR_UPDATE_REQUEST'
export const PROVEEDOR_UPDATE_SUCCESS= 'PROVEEDOR_UPDATE_SUCCESS'
export const PROVEEDOR_UPDATE_FAIL= 'PROVEEDOR_UPDATE_FAIL'
export const PROVEEDOR_UPDATE_RESET= 'PROVEEDOR_UPDATE_RESET'

export const PROVEEDOR_DELETE_REQUEST = 'PROVEEDOR_DELETE_REQUEST'
export const PROVEEDOR_DELETE_SUCCESS = 'PROVEEDOR_DELETE_SUCCESS'
export const PROVEEDOR_DELETE_FAIL = 'PROVEEDOR_DELETE_FAIL'

export const PROVEEDOR_CREATE_REQUEST = 'PROVEEDOR_CREATE_REQUEST'
export const PROVEEDOR_CREATE_SUCCESS = 'PROVEEDOR_CREATE_SUCCESS'
export const PROVEEDOR_CREATE_FAIL = 'PROVEEDOR_CREATE_FAIL'
export const PROVEEDOR_CREATE_RESET = 'PROVEEDOR_CREATE_RESET'
