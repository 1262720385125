import React, {useState, useEffect} from 'react'
import { Popover, Paper, Button as ButtonM, Divider} from '@mui/material';
import TimePicker from './TimePicker'
import {  Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listClientDetail } from '../actions/clientActions'

function FilterDate({keyword=''}) {

    const[anchor, setAnchor] = useState(null)

    const openPopover = (event) => {
        setAnchor(event.currentTarget)
    }

    const handleClose = () => {
        setAnchor(null);
    };
  return (
    <Col className='text-start'>
        <Button type="submit" variant='warning' onClick={openPopover}>
            <i className="fas fa-sliders"></i>
        </Button>
        <Popover 
            open={Boolean(anchor)}
            anchorEl={anchor}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical:'top',
            horizontal: "center"
            }}
            onClose={handleClose}
        >
            <Paper elevation={3} style={{width:"25vw"}}>
            <div className='p-4'>
                <h6>Selección de Filtros</h6>
                <Divider light>FIN VIGENCIA</Divider>
                <TimePicker keyword={keyword}/>
            </div>
            </Paper>
        </Popover>
    </Col>
    
  )
}

export default FilterDate