import React, { useEffect, useState }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import {Popover, Paper, Button as ButtonM, Divider} from '@mui/material';
import Message from '../components/Message'
import { Row, Col, Table, Button } from 'react-bootstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './index.css'

import { formatearFecha, currency } from '../utils/utils'

import {Line} from 'react-chartjs-2'
import {
    Chart as Chartjs,
    LineElement, 
    CategoryScale,
    LinearScale,
    PointElement
} from 'chart.js'
import { countsDashboard, incidenciasDashboard, vencimientoAtendidoDashboard, vencimientosDashboard } from '../actions/dashboardActions'
Chartjs.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement
)


function HomeScreen() {

  const match = useParams()

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const dashboardCounts = useSelector(state => state.dashboardCounts)
  const {counts} = dashboardCounts

  const dashboardVencimientos = useSelector(state => state.dashboardVencimientos)
  const {acciones} = dashboardVencimientos

  const dashboardIncidencias= useSelector(state => state.dashboardIncidencias)
  const {global, incumplimiento, siniestro} = dashboardIncidencias

  let clave = 0
    if(userInfo){
      clave = userInfo.cliente_id
  }

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(countsDashboard(clave))
    dispatch(vencimientosDashboard(clave))
    dispatch(incidenciasDashboard(clave))

  }, [dispatch, clave])

  const[anchor, setAnchor] = useState(null)
  const[accion, setAccion] = useState('')
  const[endoso, setEndoso] = useState('')
  const openPopover = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null);
  };

  const [tipo, setTipo] = useState(true)
  const [priority, setPriority] = useState({
    ALTA: '#F45C5C',
    BAJA: '#69CB3C',
    MEDIA: '#FFD700',
  })

  const handleTipo = () => {
    setTipo(!tipo)
  }
  
  function polizaEndoso(accion) {
    setAccion(accion)
    if (accion.conceptoPago.split(" ")[0] === 'endoso'){
      const partes = accion.conceptoPago.split(":");
      if (partes.length > 1) {
          const palabrasAntes = partes[0].trim().split(" ");
          setEndoso(palabrasAntes[palabrasAntes.length - 1])
      }
    }else if (accion.conceptoPago.split(" ")[0] === 'seguro'){
      setEndoso('Seguro')
    }else{
      setEndoso('Fianza')
    }
  }

  const attendHandler = (id) => {
    if (window.confirm('¿Estás seguro de marcar como atentido esta acción?')) {
        dispatch(vencimientoAtendidoDashboard(id))
        window.location.reload();
    }
  }

  const dataIncumplimiento ={
    labels:["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
    datasets:[{
      label: 'Dataset 1',
      data: incumplimiento, // tus datos para la segunda línea
      fill: true,
      backgroundColor: 'rgba(255, 99, 132, 0.2)', // cambia el color para la segunda línea
      borderColor: 'rgba(255, 99, 132, 1)', // cambia el color para la segunda línea
      borderWidth: 1,
      tension: 0.4,
    
    }]
  }
  const dataSiniestro ={
    labels:["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
    datasets:[{
      label: 'Dataset 1',
      data: siniestro, // tus datos para la segunda línea
      fill: true,
      backgroundColor: 'rgba(99, 132, 255, 0.2)', // cambia el color para la segunda línea
      borderColor: 'rgba(99, 132, 255, 1)', // cambia el color para la segunda línea
      borderWidth: 1,
      tension: 0.4,
    
    }]
  }
  const data ={
    labels:["Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"],
    datasets:[{
        data: incumplimiento,
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        tension: 0.4,
    },
    {
      label: 'Dataset 2',
      data: siniestro, // tus datos para la segunda línea
      fill: true,
      backgroundColor: 'rgba(99, 132, 255, 0.2)', // cambia el color para la segunda línea
      borderColor: 'rgba(99, 132, 255, 1)', // cambia el color para la segunda línea
      borderWidth: 1,
      tension: 0.4,
    },
    {
      label: 'Dataset 3',
      data: global, // tus datos para la segunda línea
      fill: true,
      backgroundColor: 'rgba(20, 132, 30, 0.2)', // cambia el color para la segunda línea
      borderColor: 'rgba(20, 132, 30, 1)', // cambia el color para la segunda línea
      borderWidth: 1,
      tension: 0.4,
    }]
  }

  const options ={
      plugins: {
          legend:false
      },
      scales: {
          x:{
              grid:{
                  display:false
              }
          },
          y:{
              min:0,
              max:5,
              ticks: {
                  stepSize:1,
                  callback: (value) => value + " Incidentes"
              },
              grid:{
                  borderDash:[10]
              }
          }
      }
  }

  return (
    
    <div className='pt-4'>
      <h4>Dashboard general</h4>
      <hr/>
      <Row>
        <Col>
          <Card className='m-1' variant="outlined" sx={{ width:'100%' }}>
              <CardContent style={{color:'#003a70'}}>
                  <Typography gutterBottom variant="h5" component="div">
                      <Row className='align-items-center pt-3'>
                          <Col sm={1}>
                              <div className='circle text-center' style={{backgroundColor:'#FF7F7F'}}> <i className='fas fa-street-view' style={{color:'#DC143C'}}/></div>
                          </Col>
                          <Col className='text-center pt-1'>
                              {counts.proveedores} Proveedores
                          </Col>
                      </Row>
                  </Typography>
              </CardContent>
          </Card>
        </Col>
        <Col>
          <Card className='m-1' variant="outlined" sx={{ width:'100%' }}>
              <CardContent style={{color:'#003a70'}}>
                  <Typography gutterBottom variant="h5" component="div">
                      <Row className='align-items-center pt-3'>
                          <Col sm={1}>
                              <div className='circle text-center' style={{backgroundColor:'#FFFACD'}}> <i className='fas fa-file-contract' style={{color:'#FFD700'}}/></div>
                          </Col>
                          <Col className='text-center pt-1'>
                            {counts.contratos} Contratos
                          </Col>
                      </Row>
                  </Typography> 
              </CardContent>
          </Card>
        </Col>
        <Col>
          <Card className='m-1' variant="outlined" sx={{ width:'100%' }}>
              <CardContent style={{color:'#003a70'}}>
                  <Typography gutterBottom variant="h5" component="div">
                      <Row className='align-items-center pt-3'>
                          <Col sm={1}>
                              <div className='circle text-center' style={{backgroundColor:'#C9FFC9'}}> <i className='fas fa-building-columns' style={{color:'#32CD32'}}/></div>
                          </Col>
                          <Col className='text-center pt-1'>
                            {counts.fianzas} Fianzas
                          </Col>
                      </Row>
                  </Typography> 
              </CardContent>
          </Card>
        </Col>
        <Col>
          <Card className='m-1' variant="outlined" sx={{ width:'100%' }}>
              <CardContent style={{color:'#003a70'}}>
                  <Typography gutterBottom variant="h5" component="div">
                      <Row className='align-items-center pt-3'>
                          <Col sm={1}>
                              <div className='circle text-center' style={{backgroundColor:'#BFEFFF'}}> <i className='fas fa-shield-halved' style={{color:'#1E90FF'}}/></div>
                          </Col>
                          <Col className='text-center pt-1'>
                            {counts.seguros} Seguros
                          </Col>
                      </Row>
                  </Typography> 
              </CardContent>
          </Card>
        </Col>
      </Row>
      <br/>
      <Row style={{height:'50vh'}}>
        <Col sm='7'>
          <Card className='rounded p-4' style={{height:'100%'}}>
            <Row className='d-flex align-items-center'>
              <Col><h6>Acciones Requeridas</h6></Col>
              <Col><Button className='btn-secondary btn-sm' style={{color:'white'}}>°</Button></Col>
            </Row>
            <hr/>
            <div style={{maxHeight:'33vh', maxWidth:'100%', overflowY:'auto'}}>
            <Table bordered hover className='table-sm text-center fixed-header-table'>
              <thead>
                  <tr>
                      <th>CONCEPTO</th>
                      <th>PRIORIDAD</th>
                      <th>DESCRIPCIÓN</th>
                      <th>FECHA</th>
                      <th></th>
                  </tr>
              </thead>

              <tbody>
                {acciones && acciones.map(accion =>(
                  <tr>
                    <td> {accion.concepto} </td>
                    <td><div className='priority-box' style={{backgroundColor:`${priority[accion.prioridad]}`}}>{accion.prioridad}</div></td>
                    <td>  {accion.tema}</td>
                    <td> {formatearFecha(accion.fecha)} </td>
                    <td>
                      <i className='fas fa-eye' onClick={(event) => {openPopover(event); polizaEndoso(accion)}}/>
                      <span style={{color:'white'}}>{' - '}</span>
                      <i className='fas fa-circle-check' style={{color:'#4BBF73'}} onClick={() =>{ attendHandler(accion._id)}}/>
                    </td>
                  </tr>
                ))}
              </tbody>
          </Table>
          </div>
          </Card>
        </Col>
        <Col>
          <Card className='rounded p-4' style={{height:'100%'}}>
            <Row className='d-flex align-items-center'>
              <Col><h6>Incidencias 2023</h6></Col>
              <Col className='text-end'><Button className='btn-sm btn-info' style={{width:'60%'}} onClick={handleTipo}>{tipo ? 'Incumplimientos': 'Siniestros'}</Button></Col>
            </Row>
            <hr/>
            <Line data={tipo ? dataIncumplimiento: dataSiniestro} options={options}></Line>
          </Card>
        </Col>
      </Row>
      <Popover 
        open={Boolean(anchor)}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical:'top',
          horizontal: "right"
        }}
        onClose={handleClose}
      >
        <Paper elevation={3} style={{width:"40vw"}}>
          <div className='p-4'>
            <h6>{accion && accion.concepto==='Pago' ? 'Pago: '+ accion.conceptoPago : accion.concepto+': '+accion.numero}</h6>
            <br/>
            <Card className='p-3' style={{backgroundColor:'#F9F9F9'}}>
              <p>Es necesario marcar como pagado, así como cargar el comprobante de pago y factura</p>
            </Card>
            <br/>
            <Divider/>
            <LinkContainer to={`${accion.concepto === 'Seguro' ? '/polizas/'
                                : accion.concepto === 'Contrato' ? '/contratos/'
                                : accion.concepto === 'Fianza' ? '/fianzas/'
                                : accion.concepto === 'Pago' && endoso === 'Seguro' ? '/polizas/'
                                : endoso === 'Fianza' && '/fianzas/'
                                }${accion.idConcepto}`} style={{width:"100%"}}>
              <ButtonM variant='success' size='small' >Ver Ficha</ButtonM>
            </LinkContainer>
          </div>
        </Paper>
      </Popover>
    </div>
      
  )
}

export default HomeScreen