import { 
    CONTRATO_LIST_FAIL,
    CONTRATO_LIST_REQUEST,
    CONTRATO_LIST_SUCCESS,

    CONTRATO_LIST_PROVEEDOR_FAIL,
    CONTRATO_LIST_PROVEEDOR_REQUEST,
    CONTRATO_LIST_PROVEEDOR_SUCCESS,

    CONTRATO_DETAIL_REQUEST,
    CONTRATO_DETAIL_SUCCESS,
    CONTRATO_DETAIL_FAIL,

    CONTRATO_UPDATE_REQUEST,
    CONTRATO_UPDATE_SUCCESS,
    CONTRATO_UPDATE_FAIL,
    CONTRATO_UPDATE_RESET,

    CONTRATO_CREATE_REQUEST,
    CONTRATO_CREATE_SUCCESS,
    CONTRATO_CREATE_FAIL,
    CONTRATO_CREATE_RESET,

    CONTRATO_DELETE_REQUEST,
    CONTRATO_DELETE_SUCCESS,
    CONTRATO_DELETE_FAIL,

} from '../constants/contratoConstants'

import axios from 'axios'

export const listContratos = (cliente, keyword = '') => async (dispatch) => {
    try{
        dispatch({type: CONTRATO_LIST_REQUEST}) 

        const {data} = await axios.get(`/api/contratos/${cliente}${keyword}`)

        dispatch({
            type: CONTRATO_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: CONTRATO_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listContratosProveedor = (cliente, proveedor, keyword='') => async (dispatch) => {
    try{
        dispatch({type: CONTRATO_LIST_PROVEEDOR_REQUEST}) 

        const {data} = await axios.get(`/api/contratos/proveedor/${cliente}/${proveedor}${keyword}`)

        dispatch({
            type: CONTRATO_LIST_PROVEEDOR_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: CONTRATO_LIST_PROVEEDOR_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listContratoDetail = (cliente, match) => async (dispatch) => {
    try{
        dispatch({type: CONTRATO_DETAIL_REQUEST})
        const {data} = await axios.get(`/api/contratos/${cliente}/${match.id}`)

        dispatch({
            type: CONTRATO_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: CONTRATO_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const createContrato = (contrato) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTRATO_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/contratos/create/`,
            contrato,
            config
        )
        dispatch({
            type: CONTRATO_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: CONTRATO_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateContrato = (contrato) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTRATO_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/contratos/update/${contrato._id}/`,
            contrato,
            config
        )
        dispatch({
            type: CONTRATO_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: CONTRATO_DETAIL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: CONTRATO_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteContrato = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONTRATO_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/contratos/delete/${id}/`,
            config
        )

        dispatch({
            type: CONTRATO_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: CONTRATO_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}