import { 
    CONTRATO_LIST_FAIL,
    CONTRATO_LIST_REQUEST,
    CONTRATO_LIST_SUCCESS,

    CONTRATO_LIST_PROVEEDOR_FAIL,
    CONTRATO_LIST_PROVEEDOR_REQUEST,
    CONTRATO_LIST_PROVEEDOR_SUCCESS,

    CONTRATO_DETAIL_REQUEST,
    CONTRATO_DETAIL_SUCCESS,
    CONTRATO_DETAIL_FAIL,

    CONTRATO_UPDATE_REQUEST,
    CONTRATO_UPDATE_SUCCESS,
    CONTRATO_UPDATE_FAIL,
    CONTRATO_UPDATE_RESET,

    CONTRATO_CREATE_REQUEST,
    CONTRATO_CREATE_SUCCESS,
    CONTRATO_CREATE_FAIL,
    CONTRATO_CREATE_RESET,

    CONTRATO_DELETE_REQUEST,
    CONTRATO_DELETE_SUCCESS,
    CONTRATO_DELETE_FAIL,

} from '../constants/contratoConstants'

export const contratoListReducer = (state = {contratos:[]}, action) =>{
    switch(action.type){
        case CONTRATO_LIST_REQUEST:
            return {loading:true, contratos:[]}

        case CONTRATO_LIST_SUCCESS:
            return {loading:false, 
                contratos: action.payload.contratos, 
                page: action.payload.page, 
                pages: action.payload.pages
            }

        case CONTRATO_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const contratoProveedorListReducer = (state = {contratos:[]}, action) =>{
    switch(action.type){
        case CONTRATO_LIST_PROVEEDOR_REQUEST:
            return {loading:true, contratos:[]}

        case CONTRATO_LIST_PROVEEDOR_SUCCESS:
            return {loading:false, 
                contratos: action.payload,
            }

        case CONTRATO_LIST_PROVEEDOR_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const contratoDetailReducer= (state = {contrato:[]}, action) =>{
    switch(action.type){
        case CONTRATO_DETAIL_REQUEST:
            return {loading:true, contrato:[]}

        case CONTRATO_DETAIL_SUCCESS:
            return {loading:false, contrato: action.payload}

        case CONTRATO_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const contratoCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTRATO_CREATE_REQUEST:
            return { loading: true }

        case CONTRATO_CREATE_SUCCESS:
            return { loading: false, success: true, contrato: action.payload }

        case CONTRATO_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case CONTRATO_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const contratoUpdateReducer = (state = { contrato: {} }, action) => {
    switch (action.type) {
        case CONTRATO_UPDATE_REQUEST:
            return { loading: true }

        case CONTRATO_UPDATE_SUCCESS:
            return { loading: false, success: true, contrato: action.payload }

        case CONTRATO_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case CONTRATO_UPDATE_RESET:
            return { contrato: {} }

        default:
            return state
    }
}

export const contratoDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTRATO_DELETE_REQUEST:
            return { loading: true }

        case CONTRATO_DELETE_SUCCESS:
            return { loading: false, success: true }

        case CONTRATO_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}