import { 
    COMENTARIO_LIST_FAIL,
    COMENTARIO_LIST_REQUEST,
    COMENTARIO_LIST_SUCCESS,

    COMENTARIO_CREATE_REQUEST,
    COMENTARIO_CREATE_SUCCESS,
    COMENTARIO_CREATE_FAIL,
    COMENTARIO_CREATE_RESET,
} from '../constants/comentarioConstants'

export const comentarioListReducer = (state = {comentarios:[]}, action) =>{
    switch(action.type){
        case COMENTARIO_LIST_REQUEST:
            return {loading:true, comentarios:[]}

        case COMENTARIO_LIST_SUCCESS:
            return {loading:false, 
                comentarios: action.payload,
            }

        case COMENTARIO_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const comentarioCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case COMENTARIO_CREATE_REQUEST:
            return { loading: true }

        case COMENTARIO_CREATE_SUCCESS:
            return { loading: false, success: true, comentario: action.payload }

        case COMENTARIO_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case COMENTARIO_CREATE_RESET:
            return {}

        default:
            return state
    }
}