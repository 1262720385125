import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function MenuPageScreen() {
  return (
    <Container>
      <div className='p-5'>
        <h2>Menú Principal</h2>
        <Row className='pt-3'>
          <Col sm={6} className='mt-3'>
            <div className='menu-card p-3'>
              <Row className='d-flex align-items-center'>
                <Col sm={4} className='text-center'>
                  <i className='fas fa-users' style={{fontSize:'50px', color:'#D9534F'}}/>
                </Col>
                <Col>
                  <h6>Proveedores</h6>
                  <p>Información relacionada con los proveedores de Aleatica</p>
                  <Row>
                    <Col>
                      <Link to='/proveedores/'>
                        <Button variant='danger' className='btn btn-sm' style={{width:'100%'}}>Listado</Button>
                      </Link>
                    </Col>
                    <Col>
                      <Link to='/proveedores/create/'>
                        <Button variant='light' className='btn btn-sm' style={{width:'100%'}}>Agregar</Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={6} className='mt-3'>
            <div className='menu-card p-3'>
              <Row className='d-flex align-items-center'>
                <Col sm={4} className='text-center'>
                  <i className='fas fa-file-signature' style={{fontSize:'50px', color:'#F0AD4E '}}/>
                </Col>
                <Col>
                  <h6>Contratos</h6>
                  <p>Información relacionada con los proveedores de Aleatica</p>
                  <Row>
                    <Col>
                      <Link to='/contratos/'>
                        <Button variant='warning' className='btn btn-sm' style={{width:'100%'}}>Listado</Button>
                      </Link>
                    </Col>
                    <Col>
                      <Link to='/contratos/create/'>
                        <Button variant='light' className='btn btn-sm' style={{width:'100%'}}>Agregar</Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={6} className='mt-3'>
            <div className='menu-card p-3'>
              <Row className='d-flex align-items-center'>
                <Col sm={4} className='text-center'>
                  <i className='fas fa-building-columns' style={{fontSize:'50px', color:'#4BBF73 '}}/>
                </Col>
                <Col>
                  <h6>Fianzas</h6>
                  <p>Información relacionada con los proveedores de Aleatica</p>
                  <Row>
                    <Col>
                      <Link to='/fianzas/'>
                        <Button variant='success' className='btn btn-sm' style={{width:'100%'}}>Listado</Button>
                      </Link>
                    </Col>
                    <Col>
                      <Link to='/fianzas/create/'>
                        <Button variant='light' className='btn btn-sm' style={{width:'100%'}}>Agregar</Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={6} className='mt-3'>
            <div className='menu-card p-3'>
              <Row className='d-flex align-items-center'>
                <Col sm={4} className='text-center'>
                  <i className='fas fa-shield-halved' style={{fontSize:'50px', color:'#18719D'}}/>
                </Col>
                <Col>
                  <h6>Seguros</h6>
                  <p>Información relacionada con los proveedores de Aleatica</p>
                  <Row>
                    <Col>
                      <Link to='/polizas/'>
                        <Button variant='info' className='btn btn-sm' style={{width:'100%'}}>Listado</Button>
                      </Link>
                    </Col>
                    <Col>
                      <Link to='/polizas/create'>
                        <Button variant='light' className='btn btn-sm' style={{width:'100%'}}>Agregar</Button>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col sm={4}>
            <Link to='/documentos/1' style={{textDecoration:'None'}}>
              <div className='menu-card p-3'>
                <Row className='d-flex align-items-center'>
                  <Col className='text-center'>
                    <i className='fas fa-folder-tree' style={{fontSize:'50px', color:'#0198E1'}}/>
                  </Col>
                  <Col>
                    <h6>DOCUMENTOS</h6>
                  </Col>
                </Row>
              </div>
            </Link>
          </Col>
          <Col sm={4}>
            <Link to='/dashboard/' style={{textDecoration:'None'}}>
              <div className='menu-card p-3'>
                <Row className='d-flex align-items-center'>
                  <Col className='text-center'>
                    <i className='fas fa-chart-pie' style={{fontSize:'50px', color:'#FF7D6D'}}/>
                  </Col>
                  <Col>
                    <h6>DASHBOARD</h6>
                  </Col>
                </Row>
              </div>
            </Link>
          </Col>
          <Col sm={4}>
            <Link to='/incidencias/' style={{textDecoration:'None'}}>
              <div className='menu-card p-3'>
                <Row className='d-flex align-items-center'>
                  <Col className='text-center'>
                    <i className='fas fa-pen-nib' style={{fontSize:'50px', color:'#1ABC9C'}}/>
                  </Col>
                  <Col>
                    <h6>INCIDENCIAS</h6>
                  </Col>
                </Row>
              </div>
            </Link>
          </Col>  
        </Row>
      </div>
      
    </Container>
  )
}

export default MenuPageScreen