import React, {useState} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom'

function Paginate({pages, page, keyword='', color='#ff0000',isAdmin = false , year, month}) {

    let navigate = useNavigate()

    if(keyword){
        keyword= keyword.split('?keyword=')[1].split('&')[0]
    }

    const handleChange = newPage => {
        if(!isAdmin){
            navigate(`?keyword=${keyword}&page=${newPage}&year=${year}&month=${month}`)
        }else{
            navigate(`?keyword=${keyword}&page=${newPage}&year=${year}&month=${month}`)
        }
         
    }

  return (pages > 1 && (
    /*
        <Pagination className='pagination justify-content-center'>
        {[...Array(pages).keys()].map((x)=>(
            <div>
                <LinkContainer 
                    key={x + 1}
                    to={!isAdmin ?
                        `?keyword=${keyword}&page=${x + 1}`
                        : `/admin/productlist/?keyword=${keyword}&page=${x + 1}`
                    }
                >
                    <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
                </LinkContainer>
            </div>
        ))}
    </Pagination>
    */
    <Pagination
        count={pages}
        page={page}
        onChange={e =>handleChange(e.target.textContent)}
        nextvalue={page}
        hidePrevButton hideNextButton 
        style={{
            display:"flex",
            justifyContent:"center"
        }}>

    </Pagination>
    
  )
    
  )
}

export default Paginate