import { 
    PROCESO_LIST_FAIL,
    PROCESO_LIST_REQUEST,
    PROCESO_LIST_SUCCESS,
    PROCESO_LIST_RESET,

    PROCESO_UPDATE_REQUEST,
    PROCESO_UPDATE_SUCCESS,
    PROCESO_UPDATE_FAIL,
    PROCESO_UPDATE_RESET,

    PROCESO_CREATE_REQUEST,
    PROCESO_CREATE_SUCCESS,
    PROCESO_CREATE_FAIL,
    PROCESO_CREATE_RESET,

    PROCESO_DELETE_REQUEST,
    PROCESO_DELETE_SUCCESS,
    PROCESO_DELETE_FAIL,

} from '../constants/procesoConstants'

export const procesoListReducer = (state = {procesos:[]}, action) =>{
    switch(action.type){
        case PROCESO_LIST_REQUEST:
            return {loading:true, procesos:[]}

        case PROCESO_LIST_SUCCESS:
            return {loading:false, 
                procesos: action.payload,
            }

        case PROCESO_LIST_FAIL:
            return {loading:false, error: action.payload}

        case PROCESO_LIST_RESET:
            return {procesos:[]}

        default:
            return state
    }
}

export const procesosCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PROCESO_CREATE_REQUEST:
            return { loading: true }

        case PROCESO_CREATE_SUCCESS:
            return { loading: false, success: true, proceso: action.payload }

        case PROCESO_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PROCESO_CREATE_RESET:
            return {}

        default:
            return state
    }
}

export const procesoUpdateReducer = (state = { proceso: {} }, action) => {
    switch (action.type) {
        case PROCESO_UPDATE_REQUEST:
            return { loading: true }

        case PROCESO_UPDATE_SUCCESS:
            return { loading: false, success: true, proceso: action.payload }

        case PROCESO_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case PROCESO_UPDATE_RESET:
            return { proceso: {} }

        default:
            return state
    }
}

export const procesoDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PROCESO_DELETE_REQUEST:
            return { loading: true }

        case PROCESO_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PROCESO_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}