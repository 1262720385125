import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { useParams, useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField';

function ContratoPdfScreen() { 

    let navigate = useNavigate()
    const match = useParams()

    const [titulo, setTitulo] = useState('')
    const [pdf, setPdf] = useState('')
    const [uploading, setUploading] = useState(false)

    const dispatch = useDispatch()

    const contratoList = useSelector(state => state.contratoDetailList)
    const { error, loading, contrato } = contratoList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {

    }, [dispatch, contrato, match, navigate])

    const submitHandler = (e) => {
        e.preventDefault()
        navigate(`/contratos/${match.id}?from=create`)
    }

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('pdf', file)
        formData.append('folio', match.id)
        formData.append('titulo', titulo)
        formData.append('concepto','contrato')

        setUploading(true)
 
        try {

            const config = {
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post('/api/documentos/upload/', formData, config)


            setPdf(data)
            setUploading(false)

        } catch (error) {
            setUploading(false)
        }
    }

    return (
        <div>
            <FormContainer>
                <h1>Adjuntar Archivo Contrato </h1>

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <TextField
                                id="titulo"
                                label="Título del Archivo" 
                                variant="outlined"
                                type='text'
                                value={titulo}
                                fullWidth 
                                onChange={(event) => {
                                    setTitulo(event.target.value);
                                }}
                            />
                            <Form.Group controlId='pdf'>
                                {pdf!== '' && <Message variant='success'>{pdf}</Message>}
                                <br/>
                                <Form.Control
                                    id='pdf-file'
                                    label='Seleccione archivo'
                                    type="file"
                                    custom
                                    onChange={uploadFileHandler}
                                >
                                </Form.Control>
                                {uploading && <Loader />}

                            </Form.Group>
                            <br/>
                            <Button type='submit' variant='success'>
                                Guardar
                            </Button>
                        </Form>
                    )}

            </FormContainer >
        </div> 

    )
}

export default ContratoPdfScreen