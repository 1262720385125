import React, {useEffect} from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listDocumentoDetail } from '../actions/documentoActions'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function FilePoliza({documento}) {

    const dispatch = useDispatch()
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const documentoDetailList = useSelector(state => state.documentoDetailList)
    const{ url } = documentoDetailList

    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        dispatch(listDocumentoDetail(documento.id))
        setAnchorEl(event.currentTarget);
    };  

    const handleClose = () => {
        setAnchorEl(null);
    };

  return (
    <Card className='shadow-doc p-3 mt-1'>
        <Row>
            <Col sm={1} lg={1} className='text-start'>
                <i className='fas fa-file-pdf' style={{fontSize:'25px', color:'#D9534F'}}/>
            </Col>
            <Col sm={9} lg={9}>
                <h5 className='m-1'>{documento.titulo}</h5>
            </Col>
            <Col sm={2} lg={2} className='text-end pe-4'>
                <i className='fas fa-ellipsis' style={{fontSize:'25px'}} onClick={handleMenu}/>
            </Col>
        </Row>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handleClose}><a href={url} target="_blank" rel="noreferrer" style={{textDecoration:'None'}}><i className='fas fa-download me-2'/>Ver Documento</a></MenuItem>
            <MenuItem onClick={handleClose}><i className='fas fa-trash me-2'/>Eliminar</MenuItem>
        </Menu>
    </Card>
  )
}

export default FilePoliza