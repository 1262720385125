import React, {useState} from 'react'
import { Table} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteEndoso } from '../actions/endosoActions'
import ModalEndoso from '../components/ModalEndoso'

import { formatearFecha } from '../utils/utils'

function Endoso({endosos}) {
    
    const [show, setShow] = useState(false);
    const [endoso, setEndoso] = useState(null);
  
    const dispatch = useDispatch()

    const handleClose = (endoso) => {
      setShow(false);
    } 

    const handleShow = (endoso) => {
      setShow(true);
      setEndoso(endoso)
    }
  
    const deleteHandler = (id) => {
   
      if (window.confirm('¿Estás seguro de eliminar este endoso?')) {
          dispatch(deleteEndoso(id))
      }
    }
    return (
      <div>
        <Table bordered className='table-sm text-center fixed-header-table'>
          <thead>
            <tr>
              <th>Número</th>
              <th>Aseguradora</th>
              <th>Inicio Vigencia</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {endosos.length >0 ? endosos.map(endoso =>(
              <tr key={endoso._id}>
                <td>{endoso.numeroEndoso}</td>
                <td>{endoso.aseguradora}</td>
                <td>{formatearFecha(endoso.inicioVigencia)}</td>
                <td>
                  <i className='fas fa-eye' onClick={() =>{ handleShow(endoso)}}/>
                  <span style={{color:'white'}}>{' - '}</span>
                  <i className='fas fa-trash' style={{color:'#D9534F'}} onClick={() =>{ deleteHandler(endoso._id)}}/>
                </td>
              </tr>
            )):
            <tr></tr>}
          </tbody>
        </Table>
        {endoso != null && <ModalEndoso endoso={endoso} show={show} handleClose={handleClose}/>}
      </div>
      
    )
}

export default Endoso
