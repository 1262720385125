import { 
    PROYECTO_LIST_FAIL,
    PROYECTO_LIST_REQUEST,
    PROYECTO_LIST_SUCCESS,

} from '../constants/proyectoConstants'

export const proyectoListReducer = (state = {proyectos:[]}, action) =>{ 
    switch(action.type){
        case PROYECTO_LIST_REQUEST:
            return {loading:true, proyectos:[]}

        case PROYECTO_LIST_SUCCESS:
            return {loading:false, 
                proyectos: action.payload
            }

        case PROYECTO_LIST_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}