export const INCIDENCIA_LIST_REQUEST = 'INCIDENCIA_LIST_REQUEST'
export const INCIDENCIA_LIST_SUCCESS = 'INCIDENCIA_LIST_SUCCESS'
export const INCIDENCIA_LIST_FAIL = 'INCIDENCIA_LIST_FAIL'
export const INCIDENCIA_LIST_RESET = 'INCIDENCIA_LIST_RESET'

export const INCIDENCIA_DETAIL_REQUEST = 'INCIDENCIA_DETAIL_REQUEST'
export const INCIDENCIA_DETAIL_SUCCESS = 'INCIDENCIA_DETAIL_SUCCESS'
export const INCIDENCIA_DETAIL_FAIL = 'INCIDENCIA_DETAIL_FAIL'

export const INCIDENCIA_UPDATE_REQUEST= 'INCIDENCIA_UPDATE_REQUEST'
export const INCIDENCIA_UPDATE_SUCCESS= 'INCIDENCIA_UPDATE_SUCCESS'
export const INCIDENCIA_UPDATE_FAIL= 'INCIDENCIA_UPDATE_FAIL'
export const INCIDENCIA_UPDATE_RESET= 'INCIDENCIA_UPDATE_RESET'

export const INCIDENCIA_DELETE_REQUEST = 'INCIDENCIA_DELETE_REQUEST'
export const INCIDENCIA_DELETE_SUCCESS = 'INCIDENCIA_DELETE_SUCCESS'
export const INCIDENCIA_DELETE_FAIL = 'INCIDENCIA_DELETE_FAIL'

export const INCIDENCIA_CREATE_REQUEST = 'INCIDENCIA_CREATE_REQUEST'
export const INCIDENCIA_CREATE_SUCCESS = 'INCIDENCIA_CREATE_SUCCESS'
export const INCIDENCIA_CREATE_FAIL = 'INCIDENCIA_CREATE_FAIL'
export const INCIDENCIA_CREATE_RESET = 'INCIDENCIA_CREATE_RESET'