import { Route,Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import HomeScreen from './screens/HomeScreen';
import Layout from './components/Layout'

import MissingScreen from './screens/MissingScreen';
import LoginScreen from './screens/LoginScreen';
import ContactoScreen from './screens/ContactoScreen';

import Header from './components/Header';
import Footer from './components/Footer';

import ProfileScreen from './screens/ProfileScreen';

import FianzasScreen from './screens/FianzasScreen';
import FianzaDetailScreen from './screens/FianzaDetailScreen';
import FianzaEditScreen from './screens/FianzaEditScreen';
import FianzaCreateScreen from './screens/FianzaCreateScreen';
import FianzaPdfScreen from './screens/FianzaPdfScreen';

import PolizasScreen from './screens/PolizasScreen';
import PolizaCreateScreen from './screens/PolizaCreateScreen';
import PolizaDetailScreen from './screens/PolizaDetailScreen';
import PolizaEditScreen from './screens/PolizaEditScreen';
import PolizaPdfScreen from './screens/PolizaPdfScreen';
import PolizaDocScreen from './screens/PolizaDocScreen';

import ContratosScreen from './screens/ContratosScreen';
import ContratoDetailScreen from './screens/ContratoDetailScreen';
import ContratoCreateScreen from './screens/ContratoCreateScreen';
import ContratoPdfScreen from './screens/ContratoPdfScreen';
import ContratoEditScreen from './screens/ContratoEditScreen';

import MenuPageScreen from './screens/MenuPageScreen';

import DocumentosScreen from './screens/DocumentosScreen';
import DocumentosCScreen from './screens/DocumentosCScreen';
import DocumentosPScreen from './screens/DocumentosPScreen';
import DocumentosSFScreen from './screens/DocumentosSFScreen';

import ProveedoresScreen from './screens/ProveedoresScreen';
import ProveedorDetailScreen from './screens/ProveedorDetailScreen';
import ProveedorCreateScreen from './screens/ProveedorCreateScreen';
import ProveedorEditScreen from './screens/ProveedorEditScreen';

import EndosoCreateScreen from './screens/EndosoCreateScreen';
import PagoCreateScreen from './screens/PagoCreateScreen';
import IncidenciasScreen from './screens/IncidenciasScreen';
import IncidenciasCreateScreen from './screens/IncidenciasCreateScreen';
import FianzaDocScreen from './screens/FianzaDocScreen';
import ContratoDocScreen from './screens/ContratoDocScreen';
import ProyectosScreen from './screens/ProyectosScreen';
import ProyectosCreateScreen from './screens/ProyectosCreateScreen';
import RegistroScreen from './screens/RegistroScreen';
import ProveedoresDocScreen from './screens/ProveedoresDocScreen';


function App() {
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  return (
    <div className="App">
      <Header/>
      <main className="py-4 px-5">
        <Routes>
            {/*PUBLIC ROUTES*/}
            <Route path="login" element={<LoginScreen/>}/>
            <Route path="contactanos" element={<ContactoScreen/>}/>
            
            <Route path="/" element={<Layout userInfo={userInfo}/>}>

              {/*CATH ALL*/}
              <Route path='*' element={<MissingScreen/>}/>

              {/*PRIVATE ROUTES*/}
              <Route path="home" element={<MenuPageScreen/>}/>
              <Route path="dashboard" element={<HomeScreen/>}/>
              
              <Route path="incidencias" element={<IncidenciasScreen/>}/>
              <Route path="incidencias/create" element={<IncidenciasCreateScreen/>}/>

              <Route path="procesos" element={<ProyectosScreen/>}/>
              <Route path="procesos/create" element={<ProyectosCreateScreen/>}/>

              <Route path="documentos/:cliente/" element={<DocumentosScreen/>}/>
              <Route path="documentos/:cliente/:proveedor" element={<DocumentosPScreen/>}/>
              <Route path="documentos/:cliente/:proveedor/:contrato" element={<DocumentosCScreen/>}/>
              <Route path="documentos/:cliente/:proveedor/:contrato/:poliza" element={<DocumentosSFScreen/>}/>

              <Route path="fianzas/" element={<FianzasScreen/>}/>
              <Route path="fianzas/create" element={<FianzaCreateScreen/>}/>
              <Route path="fianzas/:id/" element={<FianzaDetailScreen/>}/>
              <Route path="fianzas/:cliente/:id/edit" element={<FianzaEditScreen/>}/>
              <Route path="fianzas/:id/pdf" element={<FianzaPdfScreen/>}/>
              <Route path="fianzas/:id/files" element={<FianzaDocScreen/>}/>

              <Route path="profile" element={<ProfileScreen/>}/>
              <Route path="registro" element={<RegistroScreen/>}/>

              <Route path="contratos/" element={<ContratosScreen/>}/>
              <Route path="contratos/create" element={<ContratoCreateScreen/>}/>
              <Route path="contratos/:id/" element={<ContratoDetailScreen/>}/>
              <Route path="contratos/:cliente/:id/edit" element={<ContratoEditScreen/>}/>
              <Route path="contratos/:id/pdf" element={<ContratoPdfScreen/>}/>
              <Route path="contratos/:id/files" element={<ContratoDocScreen/>}/>

              <Route path="polizas/" element={<PolizasScreen/>}/>
              <Route path="polizas/create" element={<PolizaCreateScreen/>}/>
              <Route path="polizas/:id/" element={<PolizaDetailScreen/>}/>
              <Route path="polizas/:cliente/:id/edit" element={<PolizaEditScreen/>}/>
              <Route path="polizas/:id/pdf" element={<PolizaPdfScreen/>}/>
              <Route path="polizas/:id/files" element={<PolizaDocScreen/>}/>

              <Route path="proveedores/" element={<ProveedoresScreen/>}/>
              <Route path="proveedores/create" element={<ProveedorCreateScreen/>}/>
              <Route path="proveedores/:id/" element={<ProveedorDetailScreen/>}/>
              <Route path="proveedores/:id/edit" element={<ProveedorEditScreen/>}/>
              <Route path="proveedores/:id/files" element={<ProveedoresDocScreen/>}/>

              <Route path="endosos/:id/create" element={<EndosoCreateScreen/>}/>
              <Route path="pagos/:id/create" element={<PagoCreateScreen/>}/>
              
            </Route>
          </Routes>
      </main>
      {/*<Footer/>*/}
    </div>
  );
}

export default App;
