import { 
    COMENTARIO_LIST_FAIL,
    COMENTARIO_LIST_REQUEST,
    COMENTARIO_LIST_SUCCESS,

    COMENTARIO_CREATE_REQUEST,
    COMENTARIO_CREATE_SUCCESS,
    COMENTARIO_CREATE_FAIL,
    COMENTARIO_CREATE_RESET,
} from '../constants/comentarioConstants'

import axios from 'axios'

export const listComentarios = (concepto, folio) => async (dispatch) => {
    try{
        dispatch({type: COMENTARIO_LIST_REQUEST})

        const {data} = await axios.get(`/api/comentarios/${concepto}/${folio}`)

        dispatch({
            type: COMENTARIO_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: COMENTARIO_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const createComentario = (comentario) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMENTARIO_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/comentarios/create/`,
            comentario,
            config
        )
        dispatch({
            type: COMENTARIO_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: COMENTARIO_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}