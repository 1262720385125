export const ENDOSO_LIST_REQUEST = 'ENDOSO_LIST_REQUEST'
export const ENDOSO_LIST_SUCCESS = 'ENDOSO_LIST_SUCCESS'
export const ENDOSO_LIST_FAIL = 'ENDOSO_LIST_FAIL'
export const ENDOSO_LIST_RESET = 'ENDOSO_LIST_RESET'

export const ENDOSO_DETAIL_REQUEST = 'ENDOSO_DETAIL_REQUEST'
export const ENDOSO_DETAIL_SUCCESS = 'ENDOSO_DETAIL_SUCCESS'
export const ENDOSO_DETAIL_FAIL = 'ENDOSO_DETAIL_FAIL'

export const ENDOSO_UPDATE_REQUEST= 'ENDOSO_UPDATE_REQUEST'
export const ENDOSO_UPDATE_SUCCESS= 'ENDOSO_UPDATE_SUCCESS'
export const ENDOSO_UPDATE_FAIL= 'ENDOSO_UPDATE_FAIL'
export const ENDOSO_UPDATE_RESET= 'ENDOSO_UPDATE_RESET'

export const ENDOSO_DELETE_REQUEST = 'ENDOSO_DELETE_REQUEST'
export const ENDOSO_DELETE_SUCCESS = 'ENDOSO_DELETE_SUCCESS'
export const ENDOSO_DELETE_FAIL = 'ENDOSO_DELETE_FAIL'

export const ENDOSO_CREATE_REQUEST = 'ENDOSO_CREATE_REQUEST'
export const ENDOSO_CREATE_SUCCESS = 'ENDOSO_CREATE_SUCCESS'
export const ENDOSO_CREATE_FAIL = 'ENDOSO_CREATE_FAIL'
export const ENDOSO_CREATE_RESET = 'ENDOSO_CREATE_RESET'