import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listPolizas } from '../actions/polizaActions'
import { listFianzas } from '../actions/fianzaActions'
import { useNavigate } from 'react-router-dom'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createIncidencia } from '../actions/incidenciaActions'


function IncidenciasCreateScreen() {

    let navigate = useNavigate()
    const dispatch = useDispatch()

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()

    const [prioridad, setPrioridad] = useState(null)
    const [status, setStatus] = useState(null)
    const [tipoIncidencia, setTipoIncidencia] = useState(null)
    const [descripcion, setDescripcion] = useState('')
    const [fechaInicio, setFechaInicio] = useState(date)
    const [concepto, setConcepto] = useState(null)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const fianzaList = useSelector(state => state.fianzaList)
    const{fianzas} = fianzaList

    const polizaList = useSelector(state => state.polizaList)
    const{polizas} = polizaList

    useEffect(()=>{
        dispatch(listFianzas(userInfo.cliente_id))
        dispatch(listPolizas(userInfo.cliente_id))

    }, [dispatch, userInfo.cliente_id])

    let idCliente = userInfo.cliente_id

    let idConcepto = 0
    if (concepto !== null) {
        idConcepto = concepto._id
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createIncidencia({
            idCliente,
            idConcepto,
            tipoIncidencia,
            prioridad,
            status,
            descripcion,
            fechaInicio
        }))
        navigate(`/incidencias/`)
    }

    const handleClick = () => {
        window.history.back();
    };

    const defaultPropsI = {
        options: fianzas,
        getOptionLabel: (option) => option.numeroFianza,
    };

    const defaultPropsS = {
        options: polizas,
        getOptionLabel: (option) => option.numeroPoliza
    }

    const propsToUse = tipoIncidencia === 'Incumplimiento' ? defaultPropsI : defaultPropsS;

    const optionIncidencia=['Incumplimiento', 'Siniestro']
    const optionPrioridad=['BAJA', 'MEDIA', 'ALTA']
    const otpionStatus=['Atrasado', 'Pendiente', 'Concluido']
  return (
    <div>
        <Button onClick={handleClick} className='btn btn-light my-3'>
                Regresar
        </Button>
        <div className='pt-2 p-5'>
            <h1>Nueva Incidencia </h1>
            <Form onSubmit={submitHandler}>
                <Row>
                    <Col sm={4} className='p-3'>
                        <Autocomplete
                            value={tipoIncidencia}
                            onChange={(event, newValue) => {
                                setTipoIncidencia(newValue);
                            }}
                            disablePortal
                            id="ramoSeguro"
                            options={optionIncidencia}
                            renderInput={(params) => <TextField {...params} label="Tipo Incidencia" />}
                        />
                    </Col>
                    {tipoIncidencia !== null &&
                        (
                            <Col sm={4} className='p-3'>
                                <Autocomplete
                                    {...propsToUse}
                                    value={concepto}
                                    onChange={(event, newValue) => {
                                        setConcepto(newValue);
                                    }}
                                    autoComplete
                                    disablePortal
                                    id="concepto"
                                    renderInput={(params) => <TextField {...params} label="Póliza"/>}
                                />
                            </Col>
                        )
                    }
                    </Row>
                    <Row>
                    <Col sm={4} className='p-3'>
                        <Autocomplete
                            value={status}
                            onChange={(event, newValue) => {
                                setStatus(newValue);
                            }}
                            disablePortal
                            id="status"
                            options={otpionStatus}
                            renderInput={(params) => <TextField {...params} label="Status" />}
                        />
                    </Col>
                    <Col sm={4} className='p-3'>
                        <Autocomplete
                            value={prioridad}
                            onChange={(event, newValue) => {
                                setPrioridad(newValue);
                            }}
                            disablePortal
                            id="prioridad"
                            options={optionPrioridad}
                            renderInput={(params) => <TextField {...params} label="Prioridad" />}
                        />
                    </Col>
                    <Col sm={4} className='p-3'>
                        <TextField 
                            id="fechaInicio"
                            label="Fecha Inicio" 
                            variant="outlined"
                            value={fechaInicio}
                            type='date'
                            fullWidth 
                            onChange={(event) => {
                                setFechaInicio(event.target.value);
                            }}
                        />
                    </Col>
                    <hr/>
                    <TextField
                        id="descripcion"
                        label="Descripción"
                        multiline
                        rows={4}
                        value={descripcion}
                        onChange={(event) => {
                            setDescripcion(event.target.value);
                        }}
                    />
                </Row>
                <br/>
                <Button type='submit' variant='success'>
                    Guardar
                </Button>
            </Form>
        </div>
    </div>
  )
}

export default IncidenciasCreateScreen