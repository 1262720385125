import React, {useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { listClientDetail } from '../actions/clientActions'
import { useDispatch, useSelector } from 'react-redux'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'

function ProfileScreen() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const userDetail = useSelector(state => state.userDetail)
    const { error, loading, user } = userDetail

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile

    const clientList = useSelector(state => state.clientDetailList)
    const { client } = clientList

    let clave = 0
    if(userInfo){
        clave = userInfo.cliente_id
    }


    let navigate = useNavigate()

    useEffect(() =>{
        if(!userInfo){
          navigate('/login')
        }else{
            if(!user || !user.name || success){
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails('profile'))
            }else{
                setName(user.name)
                setEmail(user.email)

            }
        }
        dispatch(listClientDetail(clave))

      }, [dispatch, navigate, userInfo, user, success, clave])

      const submitHandler = (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setMessage('La contraseña no coincide')
        }else{
            dispatch(updateUserProfile({
                'id':user._id,
                'name':name,
                'email':email,
                'password': password
            }))
        }
      }

  return (
    <Row>
        <Col md={3}>
            <h2>Perfil de Usuario</h2>
            <hr/>
            {message && <Message variant='danger'>{message}</Message>}
            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader/>}
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='name'>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        required
                        type='name'
                        placeholder='Ingresa Nombre'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
                <Form.Group controlId='email'>
                    <Form.Label>Correo</Form.Label>
                    <Form.Control
                        required
                        type='email'
                        placeholder='Ingresa Correo'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
                <Form.Group controlId='password'>
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Ingresa Contraseña'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
                <Form.Group controlId='passwordConfirm'>
                    <Form.Label>Comfirma Contraseña</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Confirma Contraseña'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                <br/>
                <Button type='submit' style={{background:client.secondary}} >Guardar Cambios</Button>
            </Form>

        </Col>
        <Col>
            <h2>Notificaciones</h2>
            <hr/>
            <Message variant="info">No hay notificaciones</Message>
        </Col>
    </Row>
  )
}

export default ProfileScreen