import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listPolizaDetail } from '../actions/polizaActions'
import { listFianzaDetail } from '../actions/fianzaActions'
import { createPago } from '../actions/pagoActions'
import { POLIZA_UPDATE_RESET } from '../constants/polizaConstants'
import { useParams, useNavigate } from 'react-router-dom'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

function PagoCreateScreen() {
    let navigate = useNavigate()
    const keyword = useLocation().search
    const match = useParams()
    const concepto = keyword.split('concepto=')[1]

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 1) + '-' + (today.getDate()<10 ? '0':'') + today.getDate(),
    date2 = (today.getFullYear()) + '-' + (today.getMonth()<10 ? '0':'') +(today.getMonth() + 2) + '-' + (today.getDate()<10 ? '0':'') + today.getDate()

    const dispatch = useDispatch()

    const [fechaLimitePago, setFechaLimitePago] = useState(date2)
    const [fechaPago, setFechaPago] = useState(date)
    const [numeroFactura, setNumeroFactura] = useState('')
    const [moneda, setMoneda] = useState(null)
    const [monto, setMonto] = useState('')
    const [porcentajeComision, setPorcentajeComision] = useState('')

    const pagoCreate = useSelector(state => state.pagoCreate)
    const { loading: loadingCreate, error: errorCreate, success: successCreate, endoso: createdPago } = pagoCreate

    const fianzaList = useSelector(state => state.fianzaDetailList)
    const{error, loading, fianza} = fianzaList

    const polizaList = useSelector(state => state.polizaDetailList)
    const{poliza} = polizaList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(()=>{
        if(concepto === 'seguro'){ 
            dispatch(listPolizaDetail(userInfo.cliente_id, match))
        }else if (concepto === 'fianza'){
            dispatch(listFianzaDetail(userInfo.cliente_id, match))
        }
        if(successCreate){
            if(concepto === 'seguro'){
                navigate(`/polizas/${poliza._id}/`)
            }else if (concepto === 'fianza'){
                navigate(`/fianzas/${fianza._id}/`)
            }
        }
      }, [dispatch, match, userInfo, successCreate])

    const handleClick = () => {
        window.history.back();
    };
    const idConcepto = match.id
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createPago({
            idConcepto,
            concepto,
            fechaLimitePago,
            fechaPago,
            numeroFactura,
            moneda,
            monto,
            porcentajeComision,
        }))
    }
    
    const optionMoneda=['MXN','USD','EURO']

  return (
    <div>
        <Button onClick={handleClick} className='btn btn-light my-3'>
                Regresar
        </Button>
        <div className='pt-2 p-5'>
                <h1>Nuevo Pago </h1>
                <h6>Registro de pago para {concepto}</h6>
                <Form onSubmit={submitHandler}>
                    <Row>
                        <Col sm={4} className='p-3'>
                        <TextField 
                            id="numeroFactura"
                            label="Número Factura" 
                            variant="outlined"
                            type='text'
                            value={numeroFactura}
                            fullWidth 
                            onChange={(event) => {
                                setNumeroFactura(event.target.value);
                            }}
                        />
                        </Col>
                        <br/>
                        <Col sm={4} className='p-3'>
                        <TextField 
                            id="fechaLimitePago"
                            label="Fecha límite de pago" 
                            variant="outlined"
                            value={fechaLimitePago}
                            type='date'
                            fullWidth 
                            onChange={(event) => {
                                setFechaLimitePago(event.target.value);
                            }}
                        />
                        </Col> 
                        <br/>
                        <Col sm={4} className='p-3'>
                        <TextField 
                            id="fechaPago"
                            label="Fecha de pago" 
                            variant="outlined"
                            value={fechaPago}
                            type='date'
                            fullWidth 
                            onChange={(event) => {
                                setFechaPago(event.target.value);
                            }}
                        />
                        </Col>
                        <br/>
                        <Col sm={4} className='p-3'>
                        <Autocomplete
                            value={moneda}
                            onChange={(event, newValue) => {
                                setMoneda(newValue);
                            }}
                            disablePortal
                            id="moneda"
                            options={optionMoneda}
                            renderInput={(params) => <TextField {...params} label="Moneda" />}
                        />
                        </Col>
                        <br/>
                        <Col sm={4} className='p-3'>
                        <TextField 
                            id="monto"
                            label='Monto de Pago'
                            variant="outlined"
                            value={monto}
                            type='number'
                            fullWidth 
                            onChange={(event) => {
                                setMonto(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{moneda}</InputAdornment>,
                            }}
                        />
                        </Col>
                        <br/>
                        <Col sm={4} className='p-3'>
                        <TextField 
                            id="porcerntajeComision"
                            label='Porcentaje de Comisión'
                            variant="outlined"
                            value={porcentajeComision}
                            type='number'
                            fullWidth 
                            onChange={(event) => {
                                setPorcentajeComision(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                        </Col>
                    </Row>
                    <br/>
                    <Button type='submit' variant='success'>
                        Guardar
                    </Button>
                </Form>
        </div>
    </div>
  )
}

export default PagoCreateScreen