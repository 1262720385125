import React, { useEffect } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import { Row, Col,Card, Button } from 'react-bootstrap'
import { listContratoDetail } from '../actions/contratoActions'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Comment from '../components/Comment'
import { listFianzasContrato } from '../actions/fianzaActions'
import { listPolizasContrato } from '../actions/polizaActions'
import FianzaContrato from '../components/FianzaContrato'
import PolizaContrato from '../components/PolizaContrato'

import { formatearFecha, currency } from '../utils/utils'

function ContratoDetailScreen() {

  let navigate = useNavigate()
  const match = useParams()
  
  const dispatch = useDispatch()

  const contratoList = useSelector(state => state.contratoDetailList)
  const{error, loading, contrato} = contratoList

  const fianzaContratoList = useSelector(state => state.fianzaContratoList)
  const{errorF, loadingF, fianzas} = fianzaContratoList

  const polizaContratoList = useSelector(state => state.polizaContratoList)
  const{errorP, loadingP, polizas} = polizaContratoList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(()=>{
    if (!contrato.inicioVigencia || parseInt(match.id) != parseInt(contrato._id)){
        dispatch(listContratoDetail(userInfo.cliente_id, match))
    }else{
        if(fianzas.length === 0){
            dispatch(listFianzasContrato(userInfo.cliente_id, match.id))
        }
        if(polizas.length === 0){
            dispatch(listPolizasContrato(userInfo.cliente_id, match.id))
        }
    }
  }, [dispatch, contrato.inicioVigencia, userInfo.cliente_id, match])

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(contrato.pdf).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = contrato.pdf ;
            alink.click();
        })
    })
  }

  const createFianzaHandler = () => {
    navigate(`/fianzas/create`)
  }

  const createPolizaHandler = () => {
    navigate(`/polizas/create`)
  }

  const location = useLocation()
  const fromCreate = location.search === '?from=create'
  const handleClick = () => {
    if (fromCreate) {
      // Si se llegó desde la tercera forma, redirige a otro lado
      navigate('/contratos/');
    } else {
      // En caso contrario, regresa a la página anterior
      navigate(-1);
    }
  };

  return (
    <div className='pt-4'>
        <Row className="d-flex align-items-center justify-content-center vh-10" >
            <Col sm="2">
                <Button onClick={handleClick} className='btn btn-light my-3'>Regresar</Button>
            </Col>
            <Col sm="4">
                <h4>NC: {contrato.numeroContrato}</h4>
            </Col>
            <Col sm="4">
                <Message variant="info">No hay ninguna acción requerida</Message>
            </Col>
            <Col sm="2" className='text-end'>
                <Link className='btn btn-warning' to={`/procesos/?keyword=${contrato.proceso}`}>
                    Proceso
                </Link>
            </Col>
            <hr/>
        </Row>
        <Row>
            <Col>
                <Card style={{background:"#f5f5f5", width:"100%"}} className='rounded'>
                    <div className="p-3">
                        <Row className="flex align-items-center justify-content-center">
                            <Col sm="10">
                                <div>
                                    <h6> Detalles Generales del Contrato: {contrato.proyecto}</h6>
                                    
                                </div>
                                <hr/>
                                <Row>
                                    <Col>
                                        <strong>Proveedor:</strong> {contrato.proveedor}
                                    </Col>
                                    <Col>
                                        <strong>Sociedad:</strong> {contrato.sociedad}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>Moneda:</strong> {contrato.moneda}
                                    </Col>
                                    <Col>
                                        <strong>Valor Contrato:</strong> {currency(contrato.valorContrato)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>Inicio Vigencia:</strong> {formatearFecha(contrato.inicioVigencia)}
                                    </Col>
                                    <Col>
                                        <strong>Fin Vigencia:</strong> {formatearFecha(contrato.finVigencia)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <strong>Descripción:</strong> {contrato.descripcion}
                                    </Col>
                                </Row>
                            </Col>
                            <Col >
                                <Link to={`/contratos/${userInfo.cliente_id}/${contrato._id}/edit`} style={{width:'100%'}} className='btn btn-info'>Editar Contrato</Link>
                                <br/>
                                <br/>
                                <Link to={`/contratos/${contrato._id}/files`} style={{width:"100%"}}  className='btn btn-success'>Ver Documentos</Link>
                            </Col>
                        </Row>
                        
                    </div>
                </Card>
            </Col>
        </Row>
        <Row>
        <Col className='me-2 ms-2'>
            <br/>
            <Row className="d-flex align-items-center justify-content-center">
                <Col>
                    <h4>Fianzas</h4>
                </Col>
                <Col className='text-end'>
                    <Button className="btn-sm" variant="warning" style={{ width:"8vw"}} onClick={createFianzaHandler}> Nueva Fianza</Button>
                </Col>
            </Row >
            <hr/>
            <Row>
                {fianzas.length>0 ? 
                    <FianzaContrato fianzas={fianzas}/>
                    : 
                    <Row><Message variant="info">No hay Fianzas registrados</Message></Row>} 
            </Row>
        </Col>
        <Col className='me-2 ms-2'>
            <br/>
            <Row className="d-flex align-items-center justify-content-center">
                <Col>
                    <h4>Seguros</h4>
                </Col>
                <Col className='text-end'>
                    <Button className="btn-sm" variant="warning" style={{ width:"8vw"}} onClick={createPolizaHandler}> Nuevo Seguro</Button>
                </Col>
            </Row>
            <hr/>
            <Row>
                {polizas.length>0 ?
                    <PolizaContrato polizas={polizas}/>
                    :
                    <Row><Message variant="info">No hay Seguros registrados</Message></Row>} 
            </Row>
        </Col>
        </Row>
    </div>
  )
}

export default ContratoDetailScreen

