import { 
    DOCUMENTO_FAIL,
    DOCUMENTO_REQUEST,
    DOCUMENTO_SUCCESS,

    DOCUMENTO_DETAIL_FAIL,
    DOCUMENTO_DETAIL_REQUEST,
    DOCUMENTO_DETAIL_SUCCESS,
} from '../constants/documentoConstants'

export const documentoListReducer = (state = {documentos:[]}, action) =>{
    switch(action.type){
        case DOCUMENTO_REQUEST:
            return {loading:true, documentos:[]}

        case DOCUMENTO_SUCCESS:
            return {loading:false, 
                documentos: action.payload,
            }

        case DOCUMENTO_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}

export const documentoDetailReducer = (state = {documento:[]}, action) =>{
    switch(action.type){
        case DOCUMENTO_DETAIL_REQUEST:
            return {loading:true, ...state}

        case DOCUMENTO_DETAIL_SUCCESS:
            return {loading:false, 
                url: action.payload.url,
                documento: action.payload.documento,
            }

        case DOCUMENTO_DETAIL_FAIL:
            return {loading:false, error: action.payload}

        default:
            return state
    }
}