import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProveedorDetail, updateProveedor } from '../actions/proveedoresActions'
import { PROVEEDOR_UPDATE_RESET } from '../constants/proveedorConstants'
import { useParams, useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

function ProveedorEditScreen() {
  let navigate = useNavigate()
  const match = useParams()

  const dispatch = useDispatch()

  const [nombreProveedor, setNombreProveedor] = useState('')
  const [rfc, setRfc] = useState('')
  const [giro, setGiro] = useState('')
  const [antiguedad, setAntiguedad] = useState('')

  const proveedorList = useSelector(state => state.proveedorDetailList)
  const { error, loading, proveedor } = proveedorList

  const proveedorUpdate = useSelector(state => state.proveedorUpdate)
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = proveedorUpdate

  useEffect(() => {

    if (successUpdate) {
        dispatch({ type: PROVEEDOR_UPDATE_RESET })
        navigate(`/proveedores/${proveedor.id}?from=create`)

    } else {

        if (!proveedor.antiguedad || proveedor.id !== Number(match.id)) {
            dispatch(listProveedorDetail(match))

        } else {
            setNombreProveedor(proveedor.nombreProveedor)
            setRfc(proveedor.rfc)
            setGiro(proveedor.giro)
            setAntiguedad(proveedor.antiguedad)
        }
    }

}, [dispatch, proveedor, match, successUpdate, navigate])

const submitHandler = (e) => {
  e.preventDefault()
  dispatch(updateProveedor({
      id: match.id, 
      nombreProveedor,
      rfc,
      antiguedad,
      giro
  }))
}

const handleClick = () => {
  window.history.back();
};

  return (
    <div>
      <Button onClick={handleClick} className='btn btn-light my-3'>
              Regresar
      </Button>

      <div className='pt-2 p-5'>
        <h1>Nuevo Proveedor </h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

        <Form onSubmit={submitHandler}>
            <Row>
                <Col sm={6} className='p-3'>
                    <TextField 
                        id="nombreProveedor"
                        label="Nombre Proveedor" 
                        variant="outlined"
                        type='text'
                        value={nombreProveedor}
                        fullWidth 
                        onChange={(event) => {
                            setNombreProveedor(event.target.value);
                        }}
                    />
                </Col>
                <br/>
                <Col sm={6} className='p-3'>
                    <TextField 
                        id="rfc"
                        label="RFC Proveedor" 
                        variant="outlined"
                        type='text'
                        value={rfc}
                        fullWidth 
                        onChange={(event) => {
                            setRfc(event.target.value);
                        }}
                    />
                </Col>
                <br/>
                <Col sm={6} className='p-3'>
                    <TextField 
                        id="antiguedad"
                        label="Antigüedad" 
                        variant="outlined"
                        type='date'
                        value={antiguedad}
                        fullWidth 
                        onChange={(event) => {
                            setAntiguedad(event.target.value);
                        }}
                    />
                </Col>
                <br/>
                <Col sm={6} className='p-3'>
                    <TextField 
                        id="giro"
                        label="Giro Proveedor" 
                        variant="outlined"
                        type='text'
                        value={giro}
                        fullWidth 
                        onChange={(event) => {
                            setGiro(event.target.value);
                        }}
                    />
                </Col>
                <br/>
            </Row>
            <br/>
            <Button type='submit' variant='success'>
                Guardar
            </Button>
        </Form>
      </div>
    </div>
  )
}

export default ProveedorEditScreen