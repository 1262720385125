import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listFianzaDetail, updateFianza } from '../actions/fianzaActions'
import { FIANZA_UPDATE_RESET } from '../constants/fianzaConstants'
import { useParams, useNavigate } from 'react-router-dom'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

function FianzaEditScreen() {

    let navigate = useNavigate()
    const match = useParams()

    const [tipoFianza, setTipoFianza] = useState(null)
    const [numeroFianza, setNumeroFianza] = useState('')
    const [afianzadora, setAfianzadora] = useState(null)
    const [montoAfianzado, setMontoAfianzado] = useState('')
    const [primaNeta, setPrimaNeta] = useState('')
    const [primaTotal, setPrimaTotal] = useState('')
    const [pdf, setPdf] = useState('')
    const [inicioVigencia, setInicioVigencia] = useState('')
    const [beneficiario, setBeneficiario] = useState(null)
    const [observaciones, setObservaciones] = useState('')
    const [isPaid, setIsPaid] = useState(false)
    const [paidAt, setPaidAt] = useState('')
    const [uploading, setUploading] = useState(false)

    const dispatch = useDispatch()

    const fianzaList = useSelector(state => state.fianzaDetailList)
    const { error, loading, fianza } = fianzaList

    const fianzaUpdate = useSelector(state => state.fianzaUpdate)
    const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = fianzaUpdate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {

        if (successUpdate) {
            dispatch({ type: FIANZA_UPDATE_RESET })
            navigate(`/fianzas/${fianza._id}?from=create`)

        } else {

            if (!fianza.inicioVigencia || fianza._id !== Number(match.id)) {
                dispatch(listFianzaDetail(match))

            } else {
                setTipoFianza(fianza.tipoFianza)
                setNumeroFianza(fianza.numeroFianza)
                setAfianzadora(fianza.afianzadora)
                setMontoAfianzado(fianza.montoAfianzado)
                setPrimaNeta(fianza.primaNeta)
                setPrimaTotal(fianza.primaTotal)
                setPdf(fianza.pdf)
                setInicioVigencia(fianza.inicioVigencia)
                setBeneficiario(fianza.beneficiario)
                setObservaciones(fianza.observaciones)
                setIsPaid(fianza.isPaid)
                setPaidAt(fianza.paidAt)
            }
        }

    }, [dispatch, fianza, match, successUpdate, navigate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateFianza({ 
            _id: match.id, 
            tipoFianza,
            numeroFianza, 
            afianzadora,
            montoAfianzado,
            primaNeta,
            primaTotal,
            inicioVigencia,
            beneficiario,
            observaciones,
        }))
    }

    const optionFianza=['Cumplimiento', 'Anticipo', 'Vicios Ocultos']
    const optionAfianzadora=['CHUBB', 'ATLAS', 'SOFIMEX']
    const optionBeneficiario=['OPCEM','Concesionaria Mexiquense']

    return (
        <div>
            
            <Link to={`/fianzas/${fianza._id}?from=create`} className='btn btn-light my-3'>
                Regresar
            </Link>
                <h1>Editar Fianza </h1>
                {loadingUpdate && <Loader />}
                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Row>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={tipoFianza}
                                onChange={(event, newValue) => {
                                    setTipoFianza(newValue);
                                }}
                                disablePortal
                                id="tipoFianza"
                                options={optionFianza}
                                renderInput={(params) => <TextField {...params} label="Tipo de Fianza" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="numeroFianza"
                                label="Número Fianza" 
                                variant="outlined"
                                type='text'
                                value={numeroFianza}
                                fullWidth 
                                onChange={(event) => {
                                    setNumeroFianza(event.target.value);
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={afianzadora}
                                onChange={(event, newValue) => {
                                    setAfianzadora(newValue);
                                }}
                                disablePortal
                                id="afianzadora"
                                options={optionAfianzadora}
                                renderInput={(params) => <TextField {...params} label="Afianzadora" />}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="montoAfianzado"
                                label="Monto Afianzado" 
                                variant="outlined"
                                value={montoAfianzado}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setMontoAfianzado(event.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="primaNeta"
                                label="Prima Neta" 
                                variant="outlined"
                                value={primaNeta}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setPrimaNeta(event.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="primaTotal"
                                label="Prima Total" 
                                variant="outlined"
                                value={primaTotal}
                                type='number'
                                fullWidth 
                                onChange={(event) => {
                                    setPrimaTotal(event.target.value);
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <TextField 
                                id="inicioVigencia"
                                label="Inicio Vigencia" 
                                variant="outlined"
                                value={inicioVigencia}
                                type='date'
                                fullWidth 
                                onChange={(event) => {
                                    setInicioVigencia(event.target.value);
                                }}
                            />
                            </Col>
                            <br/>
                            <Col sm={4} className='p-3'>
                            <Autocomplete
                                value={beneficiario}
                                onChange={(event, newValue) => {
                                    setBeneficiario(newValue);
                                }}
                                disablePortal
                                id="beneficiario"
                                options={optionBeneficiario}
                                renderInput={(params) => <TextField {...params} label="Beneficiario" />}
                            />
                            </Col>
                            <br/>
                            <hr/>
                            <TextField
                                id="observaciones"
                                label="Observaciones"
                                multiline
                                rows={4}
                                value={observaciones}
                                onChange={(event) => {
                                    setObservaciones(event.target.value);
                                }}
                            />
                            </Row>
                            <br/>
                            <Button type='submit' variant='success'>
                                Guardar
                            </Button>
                        </Form>
                    )}
        </div> 

    )
}

export default FianzaEditScreen