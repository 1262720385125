import { 
    FIANZA_LIST_FAIL,
    FIANZA_LIST_REQUEST,
    FIANZA_LIST_SUCCESS,

    FIANZA_LIST_CONTRATO_FAIL,
    FIANZA_LIST_CONTRATO_REQUEST,
    FIANZA_LIST_CONTRATO_SUCCESS,

    FIANZA_DETAIL_REQUEST,
    FIANZA_DETAIL_SUCCESS,
    FIANZA_DETAIL_FAIL,

    FIANZA_VIGENCIA_REQUEST,
    FIANZA_VIGENCIA_SUCCESS,
    FIANZA_VIGENCIA_FAIL,

    FIANZA_TOP_VENCIDAS_REQUEST,
    FIANZA_TOP_VENCIDAS_SUCCESS,
    FIANZA_TOP_VENCIDAS_FAIL,

    FIANZA_UPDATE_REQUEST,
    FIANZA_UPDATE_SUCCESS,
    FIANZA_UPDATE_FAIL,
    FIANZA_UPDATE_RESET,

    FIANZA_CREATE_REQUEST,
    FIANZA_CREATE_SUCCESS,
    FIANZA_CREATE_FAIL,
    FIANZA_CREATE_RESET,

    FIANZA_DELETE_REQUEST,
    FIANZA_DELETE_SUCCESS,
    FIANZA_DELETE_FAIL,


} from '../constants/fianzaConstants'
import axios from 'axios'

export const listFianzas = (cliente, keyword = '') => async (dispatch) => {
    try{
        dispatch({type: FIANZA_LIST_REQUEST})

        const {data} = await axios.get(`/api/fianzas/${cliente}${keyword}`)

        dispatch({
            type: FIANZA_LIST_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: FIANZA_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listFianzasContrato = (cliente, contrato) => async (dispatch) => {
    try{
        dispatch({type: FIANZA_LIST_CONTRATO_REQUEST})

        const {data} = await axios.get(`/api/fianzas/contratos/${cliente}/${contrato}`)

        dispatch({
            type: FIANZA_LIST_CONTRATO_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: FIANZA_LIST_CONTRATO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listFianzaDetail = (cliente, match) => async (dispatch) => {
    try{
        dispatch({type: FIANZA_DETAIL_REQUEST})

        const {data} = await axios.get(`/api/fianzas/${cliente}/${match.id}`)

        dispatch({
            type: FIANZA_DETAIL_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: FIANZA_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listFianzasTopVigencia = (cliente_id) => async (dispatch) => {
    try{
        dispatch({type: FIANZA_VIGENCIA_REQUEST})

        const {data} = await axios.get(`/api/fianzas/topvigencia/${cliente_id}`)

        dispatch({
            type: FIANZA_VIGENCIA_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: FIANZA_VIGENCIA_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}

export const listFianzasTopVencidas = (cliente_id) => async (dispatch) => {
    try{
        dispatch({type: FIANZA_TOP_VENCIDAS_REQUEST})

        const {data} = await axios.get(`/api/fianzas/topvencidas/${cliente_id}`)

        dispatch({
            type: FIANZA_TOP_VENCIDAS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: FIANZA_TOP_VENCIDAS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}
export const createFianza = (fianza) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FIANZA_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/fianzas/create/`,
            fianza,
            config
        )
        dispatch({
            type: FIANZA_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: FIANZA_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateFianza = (fianza) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FIANZA_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/fianzas/update/${fianza._id}/`,
            fianza,
            config
        )
        dispatch({
            type: FIANZA_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: FIANZA_DETAIL_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: FIANZA_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteFianza = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FIANZA_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/fianzas/delete/${id}/`,
            config
        )

        dispatch({
            type: FIANZA_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: FIANZA_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}